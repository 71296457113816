import React from 'react';
import { useCookies } from 'react-cookie';
import { get_api_host,get_id_token } from '../../Helper/HostInfo';
import { Typography, Container, Paper, Button} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import moment from 'moment';
import ReactGantt, {GanttRow} from 'react-gantt';

export default function Roadmap(props){
  const [cookies] = get_id_token();
  const [data, setData] = React.useState({
    run: true,
    loaded: false,
    response: {}
  });
  const roadmap_url = get_api_host() + "/roadmap/" + props.team;

  React.useEffect(() => {
    if(data.run && get_id_token()){
      const axios = require('axios');
      axios.request({
        method:'get',
        url: roadmap_url,
        headers: {
          'Content-Type':'application/json',
          'myCloudProxySession': get_id_token()
        }
      }).then((response) => {
        setData({
          response:response.data,
          loaded: true,
          run:false
        });
      });
      setData({
        response:{},
        loaded: false,
        run:false
      });
    }
  },[data.run,get_id_token(),roadmap_url]);

  const content = () => {
    const get_epics = () => {
      if("epics" in data.response) {
        return data.response.epics;
      } else {
        return [];
      }
    };

    const get_sprints = () => {
      if("sprints" in data.response) {
        return data.response.sprints;
      } else {
        return [];
      }
    };

    if(data.loaded){
      return <LoadedContent epics={get_epics()} sprints={get_sprints()} {...props}/>;
    } else {
      return <Loading/>;
    }
  };

  return <div >
  	<Container maxWidth={false} style={{position:"relative",left:"-45px",top:"25px",color:"#555",minHeight:"500px"}}>
      {content()}
  	</Container>
  </div>;
}

function Loading(props) {
  return <div style={{width:"30px",margin:"auto"}}><RotateLeftIcon fontSize="large" className="loading_spin_icon" style={{marginTop:"25px"}}/></div>;
}

function HoverText(props) {
  const [hover,setHover] = React.useState({set:false});

  const set_style = () => {
    if(hover.set){
      return {textAlign:"left",display:"block",padding:"10px",width:"100%",height:"100%",color:"#30afd3"};
    } else {
      return {textAlign:"left",display:"block",padding:"10px",width:"100%",height:"100%",color:"#007fa3"};
    }
  };
  return <Button
    onClick={() => {window.open(props.url);}}
    style={set_style()}
    onMouseOver={() => {setHover({set:true});}}
    onMouseOut={() => {setHover({set:false});}}
  >
    {props.children}
  </Button>;
}

function LoadedSprint(props) {
  const row = props.row;


  return <TableRow key={row.name}>
    <TableCell component="th" scope="row">
      <HoverText url="https://one-jira.pearson.com/secure/RapidBoard.jspa?rapidView=13308&view=planning&issueLimit=100">
        {row.name}
      </HoverText>
    </TableCell>
    <TableCell align="left">{row.epics.map((epic) => {
      const url = "https://one-jira.pearson.com/issues/?jql=project%20%3D%20%22Virtual%20Classroom%22%20AND%20Sprint%20%3D%20%22" + row.name + "%22%20AND%20%22Epic%20Link%22%20%3D%20" + epic.id;
      return <HoverText url={url}>
        {epic.summary}
      </HoverText>;
    })}</TableCell>
    <TableCell align="left">{row.goal}</TableCell>
    <TableCell align="right">{row.velocity}</TableCell>
    <TableCell align="right">{moment(row.startDate).format("MM-DD-YYYY")}</TableCell>
    <TableCell align="right">{moment(row.endDate).format("MM-DD-YYYY")}</TableCell>
  </TableRow>;
}

function LoadedEpic(props) {
  const epic = props.row;
  return <GanttRow
    title={epic.summary}
    templateName={epic.id}
    steps={[
      epic.real_start_date,
      epic.real_end_date
    ]}
    barStyle={{
      height: '30px',
      marginTop: '0px',
      marginBottom: '0px'
    }}
  />;
}

function LoadedContent(props) {
  const epics = props.epics.map((epic) => {
    let new_epic = epic;
    new_epic.end_date = moment(epic.end_date);
    new_epic.real_end_date = new_epic.end_date.toDate();
    new_epic.start_date = moment(epic.start_date);
    new_epic.real_start_date = new_epic.start_date.toDate();
    return new_epic;
  });

const latest_end_date = epics
    .map((epic) => {return epic.end_date})
    .reduce((accum,next) => {
      if(next.isAfter(accum)){
        return next;
      } else {
        return accum;
      }
    })
    .add(1, 'months');
  const earliest_start_date = epics
    .map((epic) => {return epic.start_date})
    .reduce((accum,next) => {
      if(next.isBefore(accum)){
        return next;
      } else {
        return accum;
      }
    })
    .subtract(1, 'months');
  const consumer_or = (consumer) => {
    if(consumer !== null){
      return consumer;
    } else {
      return "N/A";
    }
  };
  const templates = epics
    .reduce((accum,next) => {
      accum[next.id] = {
        title: next.summary,
        steps: [
          {
            name: <div style={{color:"#888",borderTopStyle:"solid",borderWidth:"1px",borderColor:"#ccc",paddingTop:"5px"}}>
              Start Date: {moment(next.real_start_date).format("MM-YY")}<br/>
              End Date: {moment(next.real_end_date).format("MM-YY")}<br/>
              Consumer: {consumer_or(next.consumer)}
              </div>,
            color: '#ccc'
          }
        ]
      };
      return accum;
    },{});
  const table = () => {
    if(props.table){
      return <div>
        <Typography variant="h5">Sprints</Typography>
        <br/>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sprint</TableCell>
                <TableCell>Epic</TableCell>
                <TableCell>Goal</TableCell>
                <TableCell align="right">Velocity</TableCell>
                <TableCell align="right">Start Date</TableCell>
                <TableCell align="right">End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.sprints.map((row) => {
                return <LoadedSprint row={row}/>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>;
    } else {
      return <></>;
    }
  }

  const timeline = () => {
    if(props.timeline){
      return <div style={{paddingBottom:"50px"}}>
        <Typography variant="h5">{props.title}</Typography>
        <span className="timeline_layout">
          <ReactGantt
            templates={templates}
            leftBound={earliest_start_date.toDate()}
            rightBound={latest_end_date.toDate()}
            dateFormat="MM-DD-YY"
            monthFormat="MM-DD-YY"
            yearFormat="MM-DD-YY"
            weekFormat="MM-DD-YY"
            dayFormat="MM-DD-YY"
          >
            {epics.map((row) => (
              <LoadedEpic row={row}/>
            ))}
          </ReactGantt>
        </span>
      </div>;
    } else {
      return <></>;
    }
  }
  return <Container maxWidth={false}>
    {timeline()}
    {table()}
  </Container>;
}
