import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import OverviewPage from '../../Overview/OverviewPage';

function IdentityOverview(props){
	return <OverviewPage
		url={(doc) => {return "/identity/api/User Management/" + doc}}
		title="Identity and Access Management"
		subtitle="Identity and Access Management (IdAM) is a diverse portfolio of services and processes that provide identity management, authentication, and authorization."
		service_owner={{
			owner:"Himadraya Ashar",
			user:"Himadraya.Ashar@pearson.com",
			href:"https://teams.microsoft.com/l/channel/19%3a4a465508e2c74ee5b392755932ca6a4d%40thread.tacv2/User%2520Management?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width="930px"
		nav_links={[
			{href:'#get_started', label:"Getting Started"},
			{href:'#identity', label:"Identity Management Services"},
      {href:'#use_cases', label:"Identity Use Cases"},
			{href:'#access', label:"Access Management Services"},
      {href:'#am_use_cases', label:"Access Management Use Cases"},
			{href:'#videos', label:"Videos"}
		]}
		sections={[
			{
				id:"get_started",
				title:"Getting Started",
				type:"custom",
				content:<Grid item md={12}>
					<Typography variant="body2" style={{position:"relative",top:"-20px"}}>
						<p>Identity Enabling Services provides integrated Identity-related UIs, session management capabilities and APIs, and is the foundation for OnePearson Identity whereby Educators and Learners can create a single Pearson account and use that account across all participating products within Pearson. </p>
						<ul>
							<li>Prior to integrating with any other GPS services, an integrating partner must first work with the Identity team to communicate requirements and request the appropriate service account to implement one or both of the following: </li>
							<li>Adopt end-user authentication and account creation capabilities </li>
							<li>Support system-to-system authentication for API integration </li>
						</ul>

						<p>To get started, email <a href="mailto:IdentityOnboarding@pearson.com">IdentityOnboarding@pearson.com</a> with the following details: </p>
						<ul>
							<li>Initiative name </li>
							<li>Brief description of the initiative, including primary integration points with other Pearson products, if any </li>
							<li>Attach Demand Management ticket,  ARB or any other documentation, if available </li>
							<li>Contact name(s) for requirements, e.g. Architect and Product Manager or Delivery Manager (or other business representative) </li>
						</ul>
					</Typography>
				</Grid>
			},
			{
				id:"identity_business",
				title:"Identity Management Business Context",
				height:"375px",
				type:"business_context",
				sections:[{
					title:"User Creation and Authentication",
					color:"#dba63f",
					text:<>
						<p>Pearson’s Identity strategy requires that Pearson applications use common, but customizable Sign In, Account Creation and Forgot Username/Password UIs. Integrating partners will set up their own user-facing URL, which redirects to IES for authentication.</p>
					</>
				},
				{
					title:"Single Sign On between Pearson products ",
					color:"#dd77e4",
					text:<>
						<p>Once a user signs on to an integrated Pearson product, IES Session Management capabilities allow that user to seamlessly access any other integrated product without signing in again. Systems that can be integrated include those protected by IES, ForgeRock, SMS or Rumba session types. </p>
					</>
				},
				{
					title:"Single Sign On via Federation",
					color:"#3982c6",
					text:<>
						<p>IES supports SSO with third parties via various federation mechanisms:</p>
						<ul>
							<li>LMS Federation, e.g. Blackboard, Moodle, Canvas, D2L (note: Socket or other LTI integration is also required) </li>
							<li>SAML-based integration where Pearson is either the Service Provider or the Identity Provider </li>
							<li>Social Sign On via Google or Microsoft</li>
						</ul>
					</>
				},
				{
					title:"API Security",
					color:"#dba63f",
					text:<>
						<p>For partners that require integration with any other GPS services, IES supplies libraries to allow partners to initiate (instantiate?) and redeem IES system tokens.</p>
					</>
				},
				{
					title:"Mobile Authentication SDK",
					color:"#dd77e4",
					text:<>
						<p>IES provides a mobile authentication SDK which supports Sign In, Forgot Username/Password, and Account Creation for the latest versions of iOS and Android.</p>
					</>
				},
				{
					title:"Consumers",
					color:"#39ca1e",
					text:<ul>
						<li>PLP / Revel</li>
						<li>MyLabs Mastering</li>
						<li>HEd Learning Applications</li>
						<li>eText / Reader+</li>
						<li>Global Gateway / GPS</li>
						<li>MyPearson Place</li>
						<li>ActiveLearn</li>
						<li>Sonic</li>
						<li>UK Learns</li>
						<li>Pearson Pathways</li>
					</ul>
				}]
			},
			{
				id:"identity",
				title:"Identity Management Services",
				type:"apis",
				tile_height:"375px",
				sections:[
					{
						title:"Identity Enablement",
						src:"pearson_3.jpg",
						url:"IES_Credential_Swagger",
						content:<p>Credential API allows the consumer to change the Credentials of the profile, including both username and password. This service allows partners to update the password and username from the Account Management page.
						It also validates the uniqueness of a username in IES and FR while updating the username.
						Credential API also allows partners to validate the user credentials without generating IES session.</p>
					},
					{
						title:"User Composite",
						src:"pearson_2.jpg",
						url:"IES_User_Composite_Swagger",
						content:<>
							<p>Composite resource that aggregates Identity, User Profile, Affiliation, Consent Information, User Consent, Pearson Credential, and (potentially) Data Extension. The UserComposite API will allow consumers to Create, Search, Purge and Delete users based on certain supported criteria. This API can be used to create a complete identity in one call, providing all attributes including credentials and profile. It also supports search including bulk search by either:</p>
							<ul>
								<li>list of iesUserIds</li>
								<li>list of smsUserIds</li>
								<li>username</li>
								<li>name with firstName/lastName</li>
								<li>email</li>
								<li>phoneNumber</li>
							</ul>
						</>
					},
					{
						title:"Identity Profile Email",
						src:"pearson_4.jpg",
						url:"IES_IdentityProfile_Email_Swagger",
						content:"IdentityProfile Email API will allow the consumers to add, update or even remove the email from their Identity profile. Partners can call this API to change email later to the profile or add it if the profile has missing email."
					},
					{
						title:"Identity Profile",
						src:"pearson_2.jpg",
						url:"IES_Identity_Profile_Swagger",
						content:<p>The identityProfile endpoint allows users to add or update the user profile details, including first name, last name, email, home country code, and language preferences. It also provides API's to fetch the user profile details based on Identity Id or firstName and LastName.
						This API also provide capability to update CG accounts in SMS.</p>
					},
					{
						title:"Identity",
						src:"pearson_1.jpg",
						url:"IES_Identity_Swagger",
						content:"Partners can use the Identity API to create a basic Pearson Identity. Includes Identity ID, Account,  Lifecycle status and homeCountryCode. homeCountryCode is a two digit ISO 3166-1 and optional."
					},
					{
						title:"User Consent",
						src:"pearson_4.jpg",
						url:"IES_Consent_Policy_Swagger",
						content:"The User Consent API allows you to track a user's consent to a particular policy. The API checks if the user has consented to required policies. If not, it prompts the user to consent to missing policies on successful login."
					}
				]
			},
      {
				id:"use_cases",
				type:"custom",
				title:"Identity Use Cases",
        content:<TableContainer component={Container}>
          <Table style={{minWidth: 650,border:"1px solid black",borderCollapse:"inherit"}} aria-label="Identity Use Cases">
            <TableHead>
              <TableRow>
                <TableCell>My Product</TableCell>
                <TableCell>Ask us about</TableCell>
                <TableCell style={{minWidth:"300px"}}>Documentation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <p>Requires Core Identity Features:</p>
                  <ul>
                    <li>A UI for Sign In, Account Creation and Forgot Username/Password</li>
                    <li>Is either stand-alone or requires single sign-on to other Pearson applications</li>
                    <li>Does not include a 'Guest' (non-signed in) shopping experience</li>
                    <li>Is intended for users in any country</li>
                    <li>Is intended for mid-primary grades (children of reading age) through adult</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <p>Identity Target Architecture:</p>
                  <ul>
                    <li>IES Standard UI</li>
                    <li>Session Management</li>
                    <li>Security Tokens</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/Identity+Enabling+Services+%28IES%29+API+Documentation">Getting Started documentation</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/Pearson+Single+Sign+On+Session+Management+library+V2#PearsonSingleSignOnSessionManagementlibraryV2-getsmssession(okurl,errurl,siteid,iscourseaware)">Session Management library</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/System+to+System+Token+Library">System to System Token library</a><br/><br/>
                  <a href="https://code.pearson.com/identity">API Documentation</a><br/><br/>
                  <a href="https://neo.pearson.com/docs/DOC-722500">IES UI Customization Options</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Requires all Core features, but has non-standard UX requirements, for example:</p>
                  <ul>
                    <li>Includes a 'Guest' shopping experience</li>
                    <li>Has customization requirements that differ across users of the platform (e.g. multi-tenant)</li>
                    <li>UI screens must be tightly integrated or embedded within your UX</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>IES Modal UI (React component)</li>
                    <li>Session Management</li>
                    <li>Security Tokens</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/IES+SignIn+Modal+%28React+component%29+-+Features">IES Modal UI - Features</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/Pearson+Single+Sign+On+Session+Management+library+V2#PearsonSingleSignOnSessionManagementlibraryV2-getsmssession(okurl,errurl,siteid,iscourseaware)">Session Management library</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/System+to+System+Token+Library">System to System Token library</a><br/><br/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Requires all Core features, but is intended for young children of pre-reading age</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>IES Young Learner UI: APIs and icons for Username & Password</li>
                    <li>Session Management</li>
                    <li>Security Tokens</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/Digital+Library+Young+Learner+IES+integration">Young Learner Implementation</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/Pearson+Single+Sign+On+Session+Management+library+V2#PearsonSingleSignOnSessionManagementlibraryV2-getsmssession(okurl,errurl,siteid,iscourseaware)">Session Management library</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/System+to+System+Token+Library">System to System Token library</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Is a microservice that's used by another customer-facing product</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Security Tokens</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/System+to+System+Token+Library">System to System Token library</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Has a UI, but is integrated in with another Pearson application that includes sign-in and account creation functionality</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Session Management</li>
                    <li>Security Tokens</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/Pearson+Single+Sign+On+Session+Management+library+V2#PearsonSingleSignOnSessionManagementlibraryV2-getsmssession(okurl,errurl,siteid,iscourseaware)">Session Management library</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/System+to+System+Token+Library">System to System Token library</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Requires integration with a 3rd party or Learning Management System (LMS)</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Supported federation protocols, e.g. SAML</li>
                    <li>Integration with Socket or GPS/LTI; account linking</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/pages/viewpage.action?pageId=236196197">SAML Integration</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Requires sign in with Google or Microsoft accounts</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Social Sign In</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/IAM/IES+Social+Login">IES Social Login</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Is a Native mobile application that requires Sign In, Account Creation and Forgot Username/Password</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Mobile SDK for iOS and Android</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <a href="https://one-confluence.pearson.com/display/MOBILE/Mobile+Auth+SDK">Mobile Auth SDK</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/APS/Auth+SDK+Developer+Guideline">Auth SDK Developer Guideline</a><br/><br/>
                  <a href="https://one-confluence.pearson.com/display/IAM/90-day+Mobile+Token">90-day Mobile Token</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p>Is a Support tool which requires Impersonation functionality</p>
                </TableCell>
                <TableCell>
                  <ul>
                    <li>Impersonation Tokens (requires approval from IES and the Data Privacy Office)</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <p>Supplied upon approval</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
			},
			{
				id:"business",
				type:"business_context",
				title:"Access Management Business Context",
				height:"375px",
				sections:[
				{
					title:"Entitlements",
					color:"#3982c6",
					text:<p>AES Entitlement API is used to maintain a user's access to digital products, with the specific terms of access.</p>
				},
        {
          title:"Licenses",
          color:"#dd77e4",
          text:<p>AES License Manager API is used to maintain a collection of entitlements obtained by an organization for use by those affiliated with the organization.</p>
        },
				{
					title:"Consumers",
					color:"#39ca1e",
					text:<p>Everest, Mojo</p>
				}],
			},
			{
				id:"access",
        type:"apis",
				title:"Access Management Services",
        height:"375px",
        sections:[
          {
            title:"Entitlement",
            src:"pearson_2.jpg",
            url:"entitlements",
            content:<p>The Entitlement API allows the consumer to create, retrieve, and update entitlements; search for entitlements in a flexible manner via multiple search criteria; and perform entitlement check to ensure a user has proper access to Pearson protected content.  </p>
          },
          {
            title:"License Manager",
            src:"pearson_3.jpg",
            url:"license_manager",
            content:<p>The License Manager API allows the consumer to create, retrieve, and update licenses; search for licenses in a flexible manner via multiple search criteria; assign / unassign entitlements associated with licenses to / from individual users; and generate licenses from completed TEP orders associated with certain business flows.</p>
          }
        ]
				
			},
      {
				id:"am_use_cases",
				type:"coming_soon",
				title:"Access Management Use Cases",
      },
      {
        id:"videos",
        type:"video",
        title:"Videos",
        videos:[{
          title:"IES UI Configurations and Settings",
          file:"IES workflow demo-20210212_090254-Meeting Recording.mp4"
        }]
      }
		]}/>
}
export default IdentityOverview;
