import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import NavButton from '../Header/Navigation/NavButton';

function Footer(props) {
    const [scroll, setScroll] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const handle_scroll = (e) => {
      if(window.scrollY >= 50){
        setVisible(true);
      } else {
        setVisible(false);
      }
    }

    if(scroll === false) {
      window.addEventListener('scroll',handle_scroll);
      setScroll(true);
    }
    if(visible){
      return <div className="footer" style={{position:'fixed',bottom:'0px',paddingBottom:'50px',backgroundColor:'white',width:'100%'}}>
        <NavButton
          onClick={(e) => {window.scrollTo(0,0)}}
          color="secondary"
          className="nav_button_discover"
          style={{textAlign:'center',paddingTop:'20px',width:'120px',margin:'auto',display:'block'}}
        >
          <ArrowUpwardIcon/><br/>
          Back To Top
        </NavButton>
      </div>;
    } else {
      return <div></div>;
    }
}
export default Footer;
