import {Container, Grid, Typography} from '@material-ui/core';
import {NavSection, SectionHeader, SectionSubHeader, ServiceOwnerSection, TopRowBlue} from './OverviewPageStyling';
import {OverviewGridContainer, OverviewGridSection} from './OverviewCard';

import BusinessSection from './BusinessSection';
import BusinessSectionWithFooter from './BusinessSectionWithFooter';
import React from 'react';
import RequestEnhancement from './RequestEnhancement';
import RoadmapPage from './RoadmapPage';
import VideoPlayer from '../../Helper/VideoPlayer';

function SectionTemplate(props){
	return <div id={props.id} style={props.style}>
	<Container maxWidth={false} style={{paddingBottom:"50px"}}>
		<SectionHeader>
			{props.title}
		</SectionHeader>
		{props.children}
		</Container>
	</div>
}

function ApiSection(props){
	const request_enhancement = () => {
		if(props.request_enhancement){
			return <RequestEnhancement/>
		}
	}
	return <SectionTemplate {...props}>
		{request_enhancement()}
    {props.content ? <div style={{padding:'20px',backgroundColor:'#fcfcfc', marginBottom:"20px"}}>
      {props.content}
    </div> : null}
		<Grid container spacing={2}>
			{props.sections.map((section) => {
				return <OverviewGridSection
					title={section.title}
					url={props.url(section.url)}
					src={section.src}
					height={props.tile_height}
				>
					{section.content}
				</OverviewGridSection>;
			})}
		</Grid>
	</SectionTemplate>
}

function ApiSectionWithRoadmap(props){
	const request_enhancement = () => {
		if(props.request_enhancement){
			return <RequestEnhancement/>
		}
	}
	return <SectionTemplate {...props}>
		{request_enhancement()}
    {props.content ? <div style={{padding:'20px',backgroundColor:'#fcfcfc', marginBottom:"20px"}}>
      {props.content}
    </div> : null}
		<Grid container spacing={2}>
			{props.sections.map((section) => {
				return <OverviewGridSection
					title={section.title}
					url={props.url(section.url)}
					src={section.src}
					height={props.tile_height}
				>
					{section.content}
				</OverviewGridSection>;
			})}
		</Grid>
		<SectionSubHeader>Roadmap</SectionSubHeader>
		<RoadmapPage team={props.team} title={props.roadmap_title} timeline/>
	</SectionTemplate>
}

function ApiSectionWithRoadmapVideo(props){
	const request_enhancement = () => {
		if(props.request_enhancement){
			return <RequestEnhancement/>
		}
	}
	return <SectionTemplate {...props}>
    <Grid container spacing={2}>
      {props.sections.map((section) => {
        return <OverviewGridSection
          title={section.title}
          url={props.url(section.url)}
          src={section.src}
          height={props.tile_height}
        >
          {section.content}
        </OverviewGridSection>;
      })}
    </Grid>
    <SectionSubHeader>Roadmap</SectionSubHeader>
    {request_enhancement()}
		<RoadmapPage team={props.team} title={props.roadmap_title} timeline/>
    <SectionSubHeader>Video</SectionSubHeader>
		<Grid container>
			{props.videos.map((video) => <OverviewGridContainer><VideoPlayer src={video.file} title={video.title}/></OverviewGridContainer>)}
		</Grid>
	</SectionTemplate>
}

function ApiSectionWithVideo(props){
  const request_enhancement = () => {
    if(props.request_enhancement){
      return <RequestEnhancement/>
    }
  }
  return <SectionTemplate {...props}>
		{request_enhancement()}
		<Grid container spacing={2}>
			{props.sections.map((section) => {
				return <OverviewGridSection
					title={section.title}
					url={props.url(section.url)}
					src={section.src}
					height={props.tile_height}
				>
					{section.content}
				</OverviewGridSection>;
			})}
		</Grid>
		<SectionSubHeader>Video</SectionSubHeader>
		<Grid container>
			{props.videos.map((video) => <OverviewGridContainer><VideoPlayer src={video.file} title={video.title}/></OverviewGridContainer>)}
		</Grid>
  </SectionTemplate>
}

function ApiSectionWithBox(props){
	return <SectionTemplate {...props}>
		<Grid container spacing={2}>
			<OverviewGridSection
				title={props.api.title}
				url={props.url(props.api.url)}
				src={props.api.src}
				height={props.api.height ? props.api.height : false}
			>
				{props.api.content}
			</OverviewGridSection>
			{props.box.content}
		</Grid>
	</SectionTemplate>
}

function ComingSoon(props){
	return <SectionTemplate {...props}>
		<Typography variant="body2" style={{color:"#777"}}>Coming Soon ...</Typography>
	</SectionTemplate>
}

function Videos(props){
	return <SectionTemplate
		id={props.id}
		title={props.title}
		style={props.style}
	>
		<Grid container>
			{props.videos.map((video) => <OverviewGridContainer><VideoPlayer src={video.file} title={video.title}/></OverviewGridContainer>)}
		</Grid>
	</SectionTemplate>;
}

function VideoLeft(props){
	return <SectionTemplate
		id={props.id}
		title={props.title}
		style={props.style}
	>
		<Grid container>
			<Grid md={4}>
        <VideoPlayer src={props.videos[0].file} title={props.videos[0].title}/>
      </Grid>
      <Grid md={8}>
      <div style={{padding:'20px',backgroundColor:"#fcfcfc",marginBottom:'20px',boxShadow:"0px 0px 5px #888888",}}>
        {props.content}
      </div>
      </Grid>
		</Grid>
	</SectionTemplate>;
}

function OverviewPage(props){
	const bgc = (i) => {
		if(i%2 === 0){
			return "#f2f2f2";
		} else {
			return "#fcfcfc";
		}
	};
	return(<div style={{backgroundColor:"#fcfcfc",paddingBottom:"50px"}}>
    <div style={{backgroundColor:'#007fa3'}}>
    <Container maxWidth="false" >
    <Grid container direction="column" style={{padding: "3rem 0rem 0.5rem"}}>
      <Grid item xs>
    		<TopRowBlue>
    	      <Typography variant="h4" gutterBottom >{props.title}</Typography>
    	      <Typography variant="body2" gutterBottom style={{color:"white"}}>
    	      </Typography>
    	    </TopRowBlue>
      </Grid>
      <Grid item xs>
    		<ServiceOwnerSection
    	    owner={props.service_owner.owner}
    			user={props.service_owner.user}
    	    href={props.service_owner.href}
    	  />
      </Grid>
    </Grid>
    </Container>
    </div>
    <div style={{backgroundColor:'#003057'}}>
    <Container maxWidth="false">
		<NavSection width={props.nav_width}>
				{props.nav_links.map((nav) => <Grid item class="li-customer"><a href={nav.href}>{nav.label}</a></Grid>)}
		</NavSection>
    </Container>
    </div>
		{props.sections.map((section,index) => {
			if(section.type === "business_context"){
				return <BusinessSection
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),paddingBottom:"20px",minHeight:"300px"}}
					height={section.height}
					sections={section.sections}
          content={section.content}
				/>;
			} else if(section.type === "business_context_footer"){
        return <BusinessSectionWithFooter
         id={section.id}
         title={section.title}
         style={{backgroundColor:bgc(index),paddingBottom:"20px",minHeight:"300px"}}
         height={section.height}
         sections={section.sections}
          content={section.content}
       />;
      } else if(section.type === "apis"){
				return <ApiSection
					id={section.id}
					title={section.title}
					tile_height={section.tile_height}
					style={{backgroundColor:bgc(index),minHeight:"600px"}}
					sections={section.sections}
					url={props.url}
					request_enhancement={props.request_enhancement}
          content={section.content}
				/>;
			} else if(section.type === "coming_soon"){
				return <ComingSoon
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),minHeight:"300px"}}
				/>;
			} else if(section.type === "apis_with_box"){
				return <ApiSectionWithBox
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),minHeight:"600px"}}
					api={section.api}
					box={section.box}
					url={props.url}
				/>
			} else if(section.type === "custom"){
				return <SectionTemplate
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),minHeight:"300px"}}
				>
					{section.content}
				</SectionTemplate>
			} else if(section.type === "roadmap"){
				return <SectionTemplate
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),minHeight:"300px"}}
				>
					<RoadmapPage team={section.team} timeline/>
				</SectionTemplate>
			} else if(section.type === "apis_with_roadmap"){
				return <ApiSectionWithRoadmap
					id={section.id}
					title={section.title}
					tile_height={section.tile_height}
					style={{backgroundColor:bgc(index),minHeight:"600px"}}
					sections={section.sections}
					url={props.url}
					team={section.team}
					roadmap_title={section.roadmap_title}
					request_enhancement={section.request_enhancement}
				/>
      } else if(section.type === "video_left"){
        return <VideoLeft
          id={section.id}
          title={section.title}
          style={{backgroundColor:bgc(index),minHeight:"300px"}}
					videos={section.videos}
          content={section.content}
        />
			} else if(section.type === "video"){
				return <Videos
					id={section.id}
					title={section.title}
					style={{backgroundColor:bgc(index),minHeight:"300px"}}
					videos={section.videos}
				/>
			} else if(section.type === "api_with_roadmap_video") {
				return <ApiSectionWithRoadmapVideo
					id={section.id}
					title={section.title}
					tile_height={section.tile_height}
					style={{backgroundColor:bgc(index),minHeight:"600px"}}
					sections={section.sections}
					url={props.url}
					team={section.team}
					roadmap_title={section.roadmap_title}
					request_enhancement={section.request_enhancement}
					videos={section.videos}
          content={section.content}
				/>
			} else if(section.type === "apis_with_video"){
        return <ApiSectionWithVideo
          id={section.id}
          title={section.title}
          tile_height={section.tile_height}
          style={{backgroundColor:bgc(index),minHeight:"600px"}}
          sections={section.sections}
          url={props.url}
          request_enhancement={section.request_enhancement}
          videos={section.videos}
          content={section.content}
        />
      } else {
				return <div></div>;
			}
		})}
	</div>);
}

export default OverviewPage;
