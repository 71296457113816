import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import ContentOnboarding from './ContentOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Assessment Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-deleteDuplicateAssessmentsUsingDELETE' name='Delete Duplicate Assessments'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getAssessmentsForProjectUsingGET' name='Get Assessments For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getAssessmentsForProjectByEntityUrnUsingGET' name='Get Assesments For Project by Urn'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-validateAssessmentInAProjectUsingGET' name='Validate Assessment in a Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getAssessmentsForContainerUsingGET' name='Get Assessments For Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getEmbeddedAssessmentItemsForContainerUsingGET' name='Get Embedded Assessment Items For Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getAssessmentsLoForContainerUsingGET' name='Get Assessments LO For Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getEmbeddedAssessmentItemsForProjectUsingGET' name='Get Embedded Assessment Items For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-controller-getAssessmentsForProjectV3UsingGET' name='Get Assessments For Project V3'/>
    </SubMenu>
    <SubMenu name="Assessment Consumption" open={props.handle_open('acapi-api-docs')}>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-context-controller' name="Assessment Context Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-controller' name="Assessment Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-controller-v-2' name="Assessment Controller V2"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-item-controller' name="Assessment Item Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-item-controller-v-2' name="Assessment Item Controller V2"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessment-item-group-controller' name="Assessment Item Group Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-assessments-controller' name="Assessments Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-inactive-data-cleanup-controller' name="Inactive Data Cleanup Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-kafka-event-processing-status-controller' name="Kafka Event Processing Status Controller"/>
      <Item folder='Content' doc='acapi-api-docs' root={props.root} scroll_to='operations-tag-kafka-event-producer-controller' name="Kafka Event Producer Controller"/>
    </SubMenu>
    <SubMenu name="Assessment Item Group Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-item-group-controller-fetchAigForProjectUsingGET' name='Get AIG For Project'/>
    </SubMenu>
    <SubMenu name="Assessment Migration Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-migration-controller-migrateElementAssessmentDataByProjectUsingPOST' name='Migrate Element Assessment Data By Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-migration-controller-migrateElementAssessmentDataUsingPOST' name='Migrate Element Assessment Data'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-migration-controller-migrateEmbeddedAssessmentDataUsingPOST' name='Migrate Embedded Assessment Data'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-assessment-migration-controller-migrateAssessmentDataUsingPOST' name='Migrate Assessment Data'/>
    </SubMenu>
    <SubMenu name="Basic Error Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingGET' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingHEAD' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingPOST' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingPUT' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingDELETE' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingOPTIONS' name='Error HTML'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-basic-error-controller-errorHtmlUsingPATCH' name='Error HTML'/>
    </SubMenu>
    <SubMenu name="Data Migration Controller LO" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchByLOBulkUsingPOST' name='Get By LO Bulk'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchByLOUsingGET' name='Get By LO'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchByDistributableUsingGET' name='Get By Distributable'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchByLFBulkUsingPOST' name='Get By LF Bulk'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchLFMetadataUsingGET' name='Get LF Metadata'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-data-migration-controller-lo-fetchLOByLFUsingGET' name='Get LO By LF'/>
    </SubMenu>
    <SubMenu name="Dynamic TOC Generation Script Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-dynamic-toc-generation-script-controller-generateTocUsingPOST' name='Generate TOC'/>
    </SubMenu>
    <SubMenu name="Figure Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figure-controller-getImageMetadataUsingGET' name='Get Image Metadata'/>
    </SubMenu>
    <SubMenu name="Figures Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-deactivateProjectForOverideParsingUsingPUT' name='Deactivate Project For Override Parsing'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-captureElementAssessmentNotificationUsingPUT' name='Capture Element Assessment Notification'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-captureNotificationUsingPUT' name='Capture Notification'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getAudioForProjectUsingGET' name='Get Audio For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getAudioOfAContainerUsingGET' name='Get Audio Of a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getImageOfAContainerUsingGET' name='Get Image Of a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getInteractivesForContainerUsingGET' name='Get Interactives For Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getVideoOfAContainerUsingGET' name='Get Video Of a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getImageForProjectUsingGET' name='Get Image For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getInteractivesForProjectUsingGET' name='Get Interactives For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-figures-controller-getVideoForProjectUsingGET' name='Get Video For Project'/>
    </SubMenu>
    <SubMenu name="Learning Objective Data Migration Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-learning-objective-data-migration-controller-doDataMigrationUsingPOST' name='Do Data Migration'/>
    </SubMenu>
    <SubMenu name="Learning Product" open={props.handle_open('product-api-app-docs')}>
      <Item folder='Content' doc='product-api-app-docs' root={props.root} scroll_to='fake' name="Assessment Learning Objective Controller"/>
      <Item folder='Content' doc='product-api-app-docs' root={props.root} scroll_to='fake' name="Learning Objective Controller"/>
      <Item folder='Content' doc='product-api-app-docs' root={props.root} scroll_to='fake' name="Logging Controller"/>
    </SubMenu>
    <SubMenu name="Learning Template Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-learning-template-controller-getLearningTemplatesForProjectUsingGET' name='Get Learning Templates For Project by Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-learning-template-controller-getLearningTemplatesForProjectUsingGET_1' name='Get Learning Templates For Project'/>
    </SubMenu>
    <SubMenu name="Logging Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-logging-controller-loglevelUsingPOST' name='Set Log Level'/>
    </SubMenu>
    <SubMenu name="Manifest Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAllAncestorsOfLeafUrnUsingGET' name='Get All Ancestors Of Leaf Urn'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAncestorUrnGroupUsingGET' name='Get Ancestor Urn generateTOCForOlderProjectUsingPOST'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-createNewSectionUsingPUT' name='Create New Section'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAlignedLoContainersUsingGET' name='Get Aligned LO Containers'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAssessmentItemGroupUsingGET' name='Get Assessment Item Group'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getContainersByLabelUsingGET' name='Get Containers By Label'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getCitationsUsingGET' name='Get Citations'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getCitationsForAContainerUsingGET' name='Get Citations For a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getOpenerElementsForAContainerUsingGET' name='Get Opener Elements For a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getPoetryForAContainerUsingGET' name='Get Poetry For a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-newVersionEventUsingPUT' name='Create Version Event'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getOpenerElementsUsingGET' name='Get Opener Elements'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getPoetryUsingGET' name='Get Poetry'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getLatestManiFestVersionOfAProjectUsingGET' name='Get Latest Manifest Version Of a Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAIGsForProjectUsingGET' name='Get AIGs For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAIGsForContainerUsingGET' name='Get AIGs For Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getFootNoteForAContainerUsingGET' name='Get Foot Note For a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getGlossaryForAContainerUsingGET' name='Get Glossary For a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getLearningObjectivesOfAContainerUsingGET' name='Get Learning Objectives Of a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getNarrativeAudioByContainerUsingGET' name='Get Narrative Audio By Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getFootNoteForProjectUsingGET' name='Get Foot Note for Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getFormattedtextUsingGET' name='Get Formatted Text'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getGlossaryForProjectUsingGET' name='Get Glossary For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getLearningFrameworksForProjectUsingGET' name='Get Learning Frameworks For Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getMathMlUsingGET' name='Get Math MI'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getNarrativeAudioUsingGET' name='Get Narrative Audio'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getTablemarkupsUsingGET' name='Get Table Markups'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getAssessmentItemGroupV3UsingGET' name='Get Assessment Item Group V3'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getImageOfAContainerUsingGET_1' name='Get Image Of a Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getLearningObjectivesOfAContainersUsingGET' name='Get Learning Objectives Of a Containers'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-getImageForProjectUsingGET_1' name='Get Image For Project'/>
    </SubMenu>
    <SubMenu name="Manifest Controller V3" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-v-3-getLatestManiFestVersionOfAProjectV3UsingGET' name='Get Latest Manifest Version Of a Project V3'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-v-3-generateTOCForOlderProjectUsingPOST' name='Generate TOC For Older Project'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-v-3-getFootNotesForProjectV3UsingGET' name='Get Foot Notes For Project V3'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-v-3-getGlossaryForProjectV3UsingGET' name='Get Glossary For Project V3'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-manifest-controller-v-3-getInteractivesForProjectUsingGET_1' name='Get Interactives For Project'/>
    </SubMenu>
    <SubMenu name="Narrative Text Change Notification Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-narrative-text-change-notification-controller-deleteUsingGET' name='Delete Notification'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-narrative-text-change-notification-controller-captureAuthoredTextElementDataUsingPUT' name='Capture Authored Text Element Data'/>
    </SubMenu>
    <SubMenu name="Node Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-node-controller-getCompleteChildrenUsingGET' name='Get Complete Children'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-node-controller-getImmediateChildrenUsingGET' name='Get Immediate Children'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-node-controller-getContentOfSlateContainerUsingGET' name='Get Content Of Slate Container'/>
    </SubMenu>
    <SubMenu name="Operation Handler" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET' name='Get Audit Events'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_1' name='Get Beans'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_3' name='Get Caches'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingDELETE_1' name='Delete Caches'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_2' name='Get Caches by Id'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingDELETE' name='Delete Caches by Id'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_4' name='Get Conditions'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_5' name='Get Config Props'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_7' name='Get Env'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_6' name='Get Env by Match'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_10' name='Get Health'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_9' name='Get Health by Component'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_8' name='Get Health by Instance'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_11' name='Get Heap Dump'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_12' name='Get Http Trace'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_13' name='Get Info'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_15' name='Get Loggers'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_14' name='Get Loggers by Name'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingPOST' name='Update Loggers by Name'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_16' name='Get Mappings'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_18' name='Get Metrics'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_17' name='Get Metrics by Name'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_19' name='Get Scheduled Tasks'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-operation-handler-handleUsingGET_20' name='Get Thread Dump'/>
    </SubMenu>
    <SubMenu name="Page Number Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-page-number-controller-deleteDuplicateAssessmentsUsingDELETE_1' name='Delete Duplicate Assessments'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-page-number-controller-getDuplicatedPageNumbersByContainerUsingPOST' name='Get Duplicated Page Numbers By Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-page-number-controller-getPageNumbersByContainerUsingGET' name='Get Page Numbers By Container'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-page-number-controller-getContainerByPageNumberUsingGET' name='Get Container By Page Number'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-page-number-controller-getAllPageNumbersByContainerUsingGET' name='Get All Page Numbers By Container'/>
    </SubMenu>
    <SubMenu name="Product API Integration Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-product-api-integration-controller-getLoForAsssessmentUsingGET' name='Get LO For Assessment'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-product-api-integration-controller-getLoForAsssessmentItemUsingGET' name='Get LO For Assessment Item'/>
    </SubMenu>
    <SubMenu name="Project Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-project-controller-getProjectMetadataUsingGET' name='Get Project Metadata'/>
    </SubMenu>
    <SubMenu name="Project Theme Association Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-project-theme-association-controller-getThemesAssociatedWithProjectUsingGET' name='Get Themes Associated With Project'/>
    </SubMenu>
    <SubMenu name="Resource Collection Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-resource-collection-controller-fetchAlignedResourcesUsingGET' name='Get Aligned Resources'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-resource-collection-controller-fetchAllResourcesAlignedToContainerUsingGET' name='Get All Resources Aligned To Container'/>
    </SubMenu>
    <SubMenu name="Seeding Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-seeding-controller-seedOpenerElementUsingPOST' name='Seed Opener Element'/>
    </SubMenu>
    <SubMenu name="TOC Event Source Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-toc-event-source-controller-getTocEventLogUsingGET' name='Get TOC Event Log'/>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-toc-event-source-controller-deleteTocEventLogsUsingDELETE' name='Delete TOC Event Logs'/>
    </SubMenu>
    <SubMenu name="Utility Controller" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-utility-controller-validateTOCUsingPOST' name='Validate TOC'/>
    </SubMenu>
    <SubMenu name="Web MVC Links Handler" open={props.handle_open('ContentAPI_Swagger')}>
      <Item folder='Content' doc='ContentAPI_Swagger' root={props.root} scroll_to='operations-web-mvc-links-handler-linksUsingGET' name='Links'/>
    </SubMenu>
  </>;
}

export function docs() {
  return [
    'ContentAPI_Swagger',
    'acapi-api-docs',
    'product-api-app-docs'
  ];
}

class ContentApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/content' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Content'
      overview_route='/content'
      onboarding_title='Onboarding'
      onboarding_route='/content/onboarding'
      onboarding_component={<ContentOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default ContentApi;
