import React from 'react';

class Image extends React.Component {
  render(){
    return(
      <div style={{
        margin:this.props.centered?"auto":null,
        width:this.props.centered?this.props.centered:null
      }}>
        <img
          className={this.props.className}
          src={process.env.PUBLIC_URL + '/images/' + this.props.src}
          alt={this.props.alt}
          style={this.props.style}
        />
      </div>
    );
  }
}

export default Image;
