export const get_api_host = () => {
  const env = get_host_env();
  if (env === "DEV") {
    return "https://api.code.pearsondev.tech";
  } else if (env === "LOCAL") {
    return "http://localhost:8090";
  } else {
    return "https://api.code.pearsonprd.tech";
  }
};

export const get_media_host = () => {
  const env = get_host_env();
  if (env === "DEV" || env === "LOCAL") {
    return "https://media.code.pearsondev.tech";
  } else {
    return "https://media.code.pearsonprd.tech";
  }
};

export const get_host_env = () => {
  const hostname = window.location.hostname;
  if (
    hostname === "portal.code.pearsondev.tech" ||
    hostname === "code-dev.pearson.com"
  ) {
    return "DEV";
  } else if (hostname === "localhost") {
    return "LOCAL";
  } else {
    return "PROD";
  }
};

export const get_id_token = () => {
  let idTokenName;
  if (get_host_env() === "PROD") {
    idTokenName = JSON.parse(
      localStorage.getItem(
        "msal.token.keys.06569c32-20de-40ac-8e3d-1fc07c030767"
      )
    );
  } else {
    idTokenName = JSON.parse(
      localStorage.getItem(
        "msal.token.keys.cbcf5d7b-ea10-443f-a2e3-5827d907e091"
      )
    );
  }
  const idTokenValue = JSON.parse(localStorage.getItem(idTokenName.idToken));
  return idTokenValue.secret;
};

export const get_client_id = () => {
  let clientid = "06569c32-20de-40ac-8e3d-1fc07c030767";
  if (get_host_env() !== "PROD") {
    clientid = "cbcf5d7b-ea10-443f-a2e3-5827d907e091";
  }
  return clientid;
};

export const get_tenent_id = () => {
  let tenentid =
    "https://login.microsoftonline.com/8cc434d7-97d0-47d3-b5c5-14fe0e33e34b";
  if (get_host_env() !== "PROD") {
    tenentid =
      "https://login.microsoftonline.com/209a71f6-c9bb-48f1-a23d-a26d275b75e2";
  }
  return tenentid;
};

export const get_redirect_uri = () => {
  let redirectUri = "https://code.pearson.com/";
  if (get_host_env() === "DEV") {
    redirectUri = "https://code-dev.pearson.com/";
  } else if (get_host_env() === "LOCAL") {
    redirectUri = "http://localhost:3000/";
  }
  return redirectUri;
};
