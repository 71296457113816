import OverviewPage from '../../Overview/OverviewPage';
import {Grid} from '@material-ui/core';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

function EcommerceOverview(props) {
  return (<OverviewPage
		url={(doc) => {return "/ecommerce/api/eCommerce/" + doc}}
		title="Ecommerce and Marketing Services"
		subtitle="Providing a core set of eCommerce services for the display of products, management of a shopping cart, progression to order completion and my account functionality."
		service_owner={{
      owner:"Vinny George",
      user:"vinny.george@pearson.com",
  		href:"https://teams.microsoft.com/l/channel/19%3afb0822e77a524c94ae8828585b607130%40thread.tacv2/eCommerce%2520and%2520Marketing?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width = "720px"
		nav_links = {[
			{
				href:"#business",
				label:"Business Context"
			},
			{
				href:"#api_docs",
				label:"Ecommerce and Marketing Services"
			},
			{
				href:"#use_cases",
				label:"Use Cases"
			},
			{
				href:"#videos",
				label:"Videos"
			}
		]}
		sections = {[
        {
          id:"getting_started",
          title:"Getting Started",
          type:"custom",
          content:<div style={{position:"relative",top:"-20px"}}>
            <p>The eCommerce Platform APIs are designed to enable commerce capabilities for existing / new business application. Prior to integrating with these APIs, an integrating partner must first work with the PMC eCommerce team to discuss requirements so that the best solution can be agreed and any pre-requisites (e.g. product data) are in place so the site can be on-boarded onto the relevant platform.</p>
            <p>To get started, please email eCommerceOnboarding@grp.pearson.com with the following details:</p>
            <ul>
              <li>Initiative name</li>
              <li>Brief description of the initiative and what ecommerce capabilities are required</li>
              <li>Attach Demand Management ticket, ARB or any other documentation, if available</li>
              <li>Contact name(s) for requirements, e.g. Architect or Delivery Manager (or other business representative)</li>
            </ul>
          </div>
        },
				{
					id:"business",
					type:"custom",
          title:"Business Context",
					height:"200px",
          content:"These APIs cover all of the core customer journeys required to support eCommerce - from product search and management through to cart, checkout and order creation. They can support web or mobile applications that required a complete set of APIs from browse to buy, or can support use cases where APIs are used up until the checkout stage with a hosted checkout then presented to the customer. The main use cases are for D2C but B2B is supported for digital access codes delivery. There are two variations to the GPS ecommerce services - with REST (v2) and SOAP & Punch-In (v1) integrations available. The best option can be discussed as part of the on-boarding process.",
				},
				{
					id:"api_docs",
					type:"apis",
					tile_height:"300px",
					title:"Ecommerce and Marketing Services",
					sections:[{
						title:"Authentication",
						src:"pearson_4.jpg",
						url:"gps_ecomm_services_v2",
						content:"These APIs supports authenticating users via MultiPass flow (as supported by Shopify) as well as OAuth flow (as supported by SAP Hybris)."
					},
          {
            title:"Commerce Product",
            src:"pearson_5.jpg",
            url:"gps_ecomm_services_v2",
            content:<>
              <p>These APIs allow business applications to fetch product information from commerce platforms including:</p>
              <ul>
                <li>Basic & detailed product information</li>
                <li>Product pricing / stock information</li>
                <li>Product Availability</li>
                <li>Product Hierarchy</li>
              </ul>
            </>
          },
          {
            title:"Commerce Cart",
            src:"pearson_2.jpg",
            url:"gps_ecomm_services_v2",
            content:<>
              <p>These APIs allows business application to manage customer shopping carts. These include various operations, including:</p>
              <ul>
                <li>Creation of Carts</li>
                <li>Update of an existing Cart (Line Items & Quantity, Shipping / Billing Address, etc.)</li>
              </ul>
              <p>See below for v1 APIs</p>
            </>
          },
          {
            title:"Orders",
            src:"pearson_4.jpg",
            url:"gps_ecomm_services_v2",
            content:<>
              <p>These APIs allow business application to place an order for an existing cart and capture / authorise payments. The commerce platform will only be placing orders and fulfilment will be delegated to TEP.  These APIs also support fetching of the customer's order history.</p>
              <p>See below for v1 APIs</p>
            </>
          },
          {
            title:"Digital Access Codes API",
            src:"pearson_3.jpg",
            url:"gps_ecomm_services_v2",
            content:<>
              <p>These APIs allows Pearson B2B business customer to obtain the digital access code in real-time. Using these APIs, customers doesn't need to order digital products in advance and Pearson doesn't have to ship the Access Cards for the access codes.</p>
            </>
          }]
				},
        {
					id:"current_consumers",
					type:"custom",
					title:"Current Consumers",
          content:<ul>
            <li>UK Learns</li>
            <li>PMC Practitioner</li>
            <li>B2B Digital APIs Consumers</li>
            <ul>
              <li>Ambassador</li>
              <li>Akademos</li>
              <li>CEI (US)</li>
            </ul>
          </ul>
				},
        {
					id:"use_cases",
					type:"custom",
					title:"Use Cases",
          content:<ul>
            <li>UK Learns is integrated with Shopify to provide the commerce capability. In order to achieve the Shopify's integration to IES, it is using the GPS Commerce Multipass Authentication API. In this way, UK Learns AEM provides the IES user ID to GPS commerce APIs and commerce APIs uses the GPS IES APIs to create the Multipass Access Token for Shopify.</li>
            <li>PMC Practitioner has made a decision to implement the Order history in a headless manner and is using the GPS Order history APIs. These APIs expect the IES access token to be passed and it will be validated through IES before the request is processed. Once IES Token is validated, the commerce layer will be responding with the orders for the user associated with the IES token.</li>
            <li>Mobile app connecting to Shopify commerce provider through commerce APIs where the Products fetch and Cart creation was managed by APIs and Checkout process used were the hosted Shopify checkout based on the cart URL received from Shopify.</li>
            <li>Digital Access Codes API are implementing the goals for Project ACE (Access Card Elimination) where B2B vendors can retrieve the Access codes in real-time from Pearson (OASIS B2B Platform). In this way, vendors doesn't need to order the Physical Access Cards (for Access Codes) well in advance and they can retrieve the access code as and when student / school is placing an order via vendor Bookstore website. Pearson OASIS platform will configure the list of ISBNs for each vendors and it will be exported to vendor system via daily feed. When student / school places an order on bookstore vendor site, bookstore will call digital APIs to retrieve the access codes. Billing for the orders placed via Digital APIs will be handled DAILY, WEEKLY, MONTHLY.</li>
          </ul>
				},
        {
  				id:"videos",
  				type:"video_left",
  				title:"Mobile App eCommerce Integration",
          content:<>
            <p>This use case is for a mobile application where the products are the courses that user subscribe for. The requirement was to enable commerce capability where users can purchase points which can be used to obtain the solution to a submitted problem. Each option for a purchasable point was created as a product in Shopify Commerce platform. Below are the steps for customer to purchase points:</p>
            <ul>
              <li>App to call GPS Products API to display list of products (points bundle to select from)</li>
              <li>Once customer has made a selection, app to call create Cart API to create a commerce Cart</li>
              <li>Once cart is created, Shopify commerce will be responding with the Checkout URL.</li>
              <li>App to launch browser to load Checkout URL to finish the Shopify hosted Checkout flow.</li>
              <li>Once User has completed the payments in Shopify Hosted Checkout flow, App to top-up points to customer account.</li>
            </ul>
          </>,
          videos:[{
            title:"",
            file:"eCommerce-Payments.mp4"
          }]
  			},
        {
          id:"eCommerceAPIs",
          type:"custom",
          title:"v1 eCommerce APIs",
          content:<>
          <p>These APIs are no longer being actively developed and expanded. Work is underway to convert these capabilities to v2 APIs. However, in their current form they support a number of high-volume eCommerce stores within Pearson.</p>
          <ul>
                <li><b>SOAP documentation</b>: <a href= "https://one-confluence.pearson.com/display/PMC/Pearson+BasketAPI+SOAP+API+-+Reference">Pearson BasketAPI SOAP API - Reference</a></li>
                <ul>
                  <li>Below are the list of SOAP Basket APIs</li>
                </ul>
              </ul>
          <TableContainer component={Container}>
            <Table style={{minWidth: 650,border:"1px solid black",borderCollapse:"inherit"}} aria-label="v1 eCommerce APIs">
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Service Description</TableCell>
                  <TableCell style={{minWidth:"300px"}}>WSDL URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Save Cart</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for adding the cart items, saving the billing address and shipping address, adding the cart items in PIM catalog manager and setting the delivery mode for the cart items.</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Validate Address</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for validating the billing and shipping address during the profile address updates by the customer.</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Get Country And States</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service getting the list of countries and states for billing and shipping address section with respect to the given locale.</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Get Tax Info</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for retrieving the tax information details for each cart items added by the user.</p>
                    <p><b>Show/Hide PayPal based on estimatedTax flag:</b></p>
                    <p>Also this call is used to determine whether to hide/show PayPal payment option in UI based on the vertex availability from the 'taxEstimated' response field as follows.</p>
                    <ol>
                      <li>When taxEstimated - true</li>
                      <p>Vertex connection timed out and flat tax rate (6%) is applied in hybris which is an estimated tax value. Don't display PayPal Payment</p>
                      <li>When taxEstimated - false</li>
                      <p>Tax is calculated from vertex. Display PayPal payment</p>
                    </ol>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Retrieve Cart</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for retrieving the cart item details whenever the user wants to visit the cart items.</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Submit Order</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for placing the order after the payment. The payment type will be paypal, credit order and purchase order.	</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Get Shipping Info	</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for getting the shipping information details like shipping type, shipping rule, shipping charges for the placed order.	</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Create PayPal transaction	</p>
                  </TableCell>
                  <TableCell>
                    <p>Initiate a transaction with Paypal via hybris	</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Add PayPal payment information</p>
                  </TableCell>
                  <TableCell>
                    <p>Add paypal information to hybris cart	</p>
                  </TableCell>
                  <TableCell>
                    <p>api/eCommerce/v1?wsdl</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <ul>
                <li><b>REST documentation</b>: <a href= "https://one-confluence.pearson.com/display/PMC/Pearson+PunchIn++REST+API+-+Reference">Pearson PunchIn REST API - Reference</a></li>
                <ul>
                  <li>Below are the Punch-In REST APIs</li>
                </ul>
              </ul>
          <TableContainer component={Container}>
            <Table style={{minWidth: 650,border:"1px solid black",borderCollapse:"inherit"}} aria-label="v1 eCommerce APIs">
              <TableHead>
                <TableRow>
                  <TableCell>API Name</TableCell>
                  <TableCell>API Description</TableCell>
                  <TableCell>End point URL</TableCell>
                  <TableCell style={{minWidth:"300px"}}>Method Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>addToCart</p>
                  </TableCell>
                  <TableCell>
                    <p>Storefront calls this service for adding the cart items. After successfully completing all operations it redirects to Hybris checkout or cart page.</p>
                  </TableCell>
                  <TableCell>
                    <p>/punchin</p>
                  </TableCell>
                  <TableCell>
                    POST
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>getOrder</p>
                  </TableCell>
                  <TableCell>
                    <p>SOnce punchin redirect to Hybris checkout page or cart page user will provide billing/shipping address and then user has to complete the payment. After successful payment Hybris calls the getOrder API.</p>
                    <p>getOrder Api gets the order details from the Hybris and encrypt order info and then redirect to storefront with these encrypted order details.</p>
                  </TableCell>
                  <TableCell>
                    <p>/redirectStorefron</p>
                  </TableCell>
                  <TableCell>
                    POST
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>decrypt</p>
                  </TableCell>
                  <TableCell>
                    <p>This REST API developed to encrypt the XML.</p>
                  </TableCell>
                  <TableCell>
                    <p>/tool/decrypt</p>
                  </TableCell>
                  <TableCell>
                    PUT
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>encrypt</p>
                  </TableCell>
                  <TableCell>
                    <p>This REST API developed to decrypt the XML.</p>
                  </TableCell>
                  <TableCell>
                    <p>/tool/encrypt</p>
                  </TableCell>
                  <TableCell>
                    PUT
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>


         </>
        },
        {
					id:"current_consumers2",
					type:"custom",
					title:"Current Consumers",
          content:<ul>
            <li>Adobe Press</li>
            <li>Brady Books</li>
            <li>Cisco Press</li>
            <li>Corporate Services</li>
            <li>InformIT</li>
            <li>Mathology</li>
            <li>Microsoft Press</li>
            <li>Mylab & Mastering</li>
            <li>Peachpit Press</li>
            <li>Pearson IT Certification</li>
            <li>Professional and Careers</li>
            <li>Revel</li>
            <li>SMS</li>
            <li>Vignette</li>
          </ul>
				}
		]}/>);
}

export default EcommerceOverview;
