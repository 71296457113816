import React from 'react';
import {useHistory} from 'react-router-dom';
import {CalloutButton} from './OverviewPageStyling'
import { Card
  , CardHeader
  , CardContent
  , CardActions
  , CardMedia
  , Typography
  , Grid
} from '@material-ui/core';

export function OverviewGridSection(props) {
  return <OverviewGridContainer>
    <OverviewCard {...props}/>
  </OverviewGridContainer>;
}

export function OverviewGridContainer(props) {
  return <Grid item sm={6} md={4} lg={4}>
    {props.children}
  </Grid>;
}

function OverviewCardBody(props) {
  const history = useHistory();

  const set_height = () => {
    if(props.height){
      return props.height;
    } else {
      return "120px";
    }
  };

  return <>
    <CardMedia
      onClick={() => {window.scrollTo(0, 0);history.push(props.url);}}
      style={props.image_style()}
      image={"/images/"+props.src}
      title={props.title}
    />
    <CardHeader
      onClick={() => {window.scrollTo(0, 0);history.push(props.url);}}
      style={{backgroundColor:'white',color:'black'}}
      title={props.title}
      titleTypographyProps={{variant:'h6' }}
    />
    <CardContent
      onClick={() => {window.scrollTo(0, 0);history.push(props.url);}}
      style={{backgroundColor:'white'}}
    >
      <Typography variant="body2" style={{height:set_height(),overflowY:"hidden"}}>
        {props.children}
      </Typography>
    </CardContent>
  </>;
}

function OverviewCard(props) {
  const [hover, setHover] = React.useState({x:1,y:1});
  const MIN_HOVER = 1;
  const MAX_HOVER = 1.05;

  const image_style = () => {
      return {height: 200, transform: "scale3d(" + hover.x + "," + hover.y + ",1)", transition: "transform .2s"};
  };

  const button_label = () => {
    if(props.label){
      return props.label;
    } else {
      return "API Docs";
    }
  };

  const callout_url = () => {
    if(props.url_api){
      return props.url_api;
    } else {
      return props.url;
    }
  };

  return(
    <Card
      style={{
        backgroundColor:'white',
        maxWidth:'445px',
        minWidth:'275px',
        minHeight:'350px',
        cursor:"pointer",
        ...props.style
      }}
      onMouseOver={() => {setHover({set:true,x:MAX_HOVER,y:MAX_HOVER});}}
      onMouseOut={() => {setHover({set:false,x:MIN_HOVER,y:MIN_HOVER});}}
    >
      <OverviewCardBody {...props} image_style={image_style}/>
      <CardActions style={{backgroundColor:'white',paddingBottom:'10px',paddingTop:'10px'}}>
        <br/><br/>
        <CalloutButton to={callout_url()} text={button_label()}/>
      </CardActions>
    </Card>
  );
}

export default OverviewCard;
