import {Box, Container, Grid, Typography} from '@material-ui/core';

import Description from '@material-ui/icons/Description';
import Group from '@material-ui/icons/Group';
import Image from '../../Helper/Image';
import { Link } from 'react-router-dom';
import NavButton from '../../Header/Navigation/NavButton';
import React from 'react';

export function TopRowBlue(props) {
  return(
  <Box style={{backgroundColor: '#007fa3', color:"white"}}>
      {props.children}
  </Box>);
}

export function TopRowGrey(props) {
  return(<div style={{backgroundColor: '#f0f0f0', color:"black"}}>
    <Container maxWidth={false}>
      <Grid container>
        <Grid item md={7}>
          {props.children}
        </Grid>
        <Grid item md={5}>
          <Image src="Dan_Matutina_Guide_RGB.png" style={{width:'600px'}}/>
        </Grid>
      </Grid>
    </Container>
  </div>);
}

export function TopRowGreyBlue(props) {
  return(<div style={{backgroundColor: 'rgb(223 238 251)', color:"black"}}>
    <Container maxWidth={false}>
      <Grid container>
        <Grid item md={7}>
          {props.children}
        </Grid>
        <Grid item md={5}>
          <Image src="Dan_Matutina_Guide_RGB.png" style={{width:'600px'}}/>
        </Grid>
      </Grid>
    </Container>
  </div>);
}

export function RowLeftRight(props) {
  return(<>
    <Grid item md={5} style={{paddingBottom:props.padding,height:props.height}}>
      {props.left}
    </Grid>
    <Grid item md={2} style={{paddingBottom:props.padding}}/>
    <Grid item md={5} style={{paddingBottom:props.padding, height:props.height}}>
      {props.right}
    </Grid>
  </>);
}

export function RowCentered(props) {
  return(<>
    <Grid item md={2} style={{paddingTop:props.padding}}/>
    <Grid item md={8} style={{paddingTop:props.padding,marginTop:'-50px'}}>{props.text}</Grid>
    <Grid item md={2} style={{paddingTop:props.padding}}/>
    <Grid item md={2} style={{paddingBottom:props.padding,height:props.height}}/>
    <Grid item md={8} style={{paddingBottom:props.padding, marginLeft: '10%',width:'300px',marginTop:'-50px'}}>{props.image}</Grid>
    <Grid item md={2} style={{paddingBottom:props.padding, height:props.height}}/>
  </>);
}

export function RowSidebySideWithPhoto(props) {
  return(<>
    <Grid item md={5}> {props.img_left}</Grid>
    <Grid item md={2}/>
    <Grid item md={5}>{props.img_right}</Grid>
    <Grid item md={5} style={{paddingBottom:props.padding,marginTop:'-150px'}}>{props.left}</Grid>
    <Grid item md={2} style={{paddingBottom:props.padding,paddingTop:'0px'}}/>
    <Grid item md={5} style={{paddingBottom:props.padding, marginTop:'-150px'}}>{props.right}</Grid>
  </>);
}

export function Text(props) {
  return <RowLeftRight left={props.text} right={props.text_right} padding="0px" paddingTop="50px" height="fit-content"/>;
}

export function TextWithPhoto(props) {
  return <RowSidebySideWithPhoto left={props.text} right={props.text_right} img_left={props.image} img_right={props.image_2} padding="50px" paddingTop="50px" height="fit-content"/>;
}


export function TextLeft(props) {
  return <RowLeftRight left={props.text} right={props.image} padding="25px" height={props.height}/>;
}

export function TextRight(props) {
  return <RowLeftRight left={props.image} right={props.text} padding="25px"  height={props.height}/>;
}

export function TextCentered(props) {
  return <RowCentered text={props.text} image={props.image} padding="0px" paddingTop="0px" height="fit-content"/>;
}

export function DefaultTextLeft(props) {
  return (<>
    <Typography variant="h4" align="left" gutterBottom style={{paddingTop:props.gutterTop}}>{props.title}</Typography>
    <Typography variant="body2" align="left" gutterBottom style={{color:"#556677"}}>
    {props.children}
    </Typography>
  </>)
}

export function DefaultTextCenter(props) {
  return (<>
    <Typography variant="h4" align="center" gutterBottom style={{paddingTop:props.gutterTop}}>{props.title}</Typography>
    <Typography variant="body2" align="center" gutterBottom style={{color:"#556677"}}>
    {props.children}
    </Typography>
  </>)
}

export function CalloutButton(props) {
  return(
    <span style={{position:'relative',left:'0px'}}>
    <NavButton className="nav_button_discover" style={{paddingLeft:'2px'}}>
      <a href={props.to} style={{color:'white',textDecoration:'none'}}>
        <span style={{
          borderRadius:'50%',
          width:'22px',
          height:'22px',
          padding:'6px',
          border:'1px solid #212121',
          color:'#212121',
          textAlign:'center',
          marginRight:'10px',
        }}>
          <Description style={{position:'relative',top:'7px',left:'1px',width:'20px'}}/>
        </span>
        {props.text}
      </a>
    </NavButton>
    </span>
  );
}

export function InProgressButton(props) {
  return(
    <p className="coming_soon">
     COMING SOON
    </p>
  );
}

export function OverviewHeader(props) {
  return(<>
    {props.children}
  </>);
}

export function GreySection(props) {
  return(<>
    <div className="section" style={{backgroundColor:'#f2f2f2'}}>
      <Container maxWidth={false}>
        <Grid container>
          {props.children}
        </Grid>
      </Container>
    </div>
  </>);
}

export function ServiceOwnerSection(props) {
  return(
    <Box height="100%" display="flex">
      <Box display="flex" alignItems="center">
      <Typography style={{color:"white",whiteSpace:"nowrap"}} variant="body2">Service Owner:  <a href={"https://teams.microsoft.com/l/chat/0/0?users=" + props.user} target="blank" alt="Teams Chat" 
                style={{position:"relative",textDecoration:"underline",fontWeight: "bold", color:"white",whiteSpace:"nowrap"}}>{props.owner}</a></Typography>
      
      </Box>
      <Box width="2rem" height="2rem">
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" style={{color: "white"}}>
            Community: 
        </Typography>
        <Link style={{color: "white", paddingLeft: "0.5rem"}} href={props.href} aria-label="Teams Chat" target="blank" variant="text">
          <Group />
        </Link>
      </Box>
    </Box>
    );
}

export function NavSection(props) {
  if(props.children){
    return(<>
        <Grid container>
          {props.children}
        </Grid>
    </>);
  } else {
    return <div style={{backgroundColor:'#003057',padding:'5px'}}/>;
  }
}

export function WhiteSection(props) {
  return(<>
    <div className="section">
      <Container maxWidth={false}>
        <Grid container>
          {props.children}
        </Grid>
      </Container>
    </div>
  </>);
}

// Business Context
export function SectionHeader(props) {
  return <Typography variant="h4" style={{fontSize:"2em",color:"#003050",paddingBottom:"50px",paddingTop:"50px"}}>
    {props.children}
  </Typography>;
}

export function SectionSubHeader(props) {
  return <Typography variant="h5" style={{fontSize:"1.5em",color:"#003050",paddingBottom:"50px",paddingTop:"50px"}}>
    {props.children}
  </Typography>;
}
