import React from 'react';
import OverviewPage from '../../Overview/OverviewPage';
import NavButton from '../../../Header/Navigation/NavButton';

const card_height = "400px";
function UserManagementOverview(props){
	return <OverviewPage
		url={(doc) => {return "/user_management/api/User Management/" + doc}}
		title="User Permission and Authorization Services"
		subtitle={<>
      <p>These set of services allow students, teachers, and admins to be added to an institution. The functionalities and capabilities each role can perform are also managed by these services.</p>
      <NavButton style={{backgroundColor:"white"}}to="https://one-confluence.pearson.com/display/K12X/User+Management+Services">More information</NavButton>
    </>}
		service_owner={{
      owner:"Srinivas Karedla",
      user:"srinivas.karedla@pearson.com",
      href:"https://teams.microsoft.com/l/channel/19%3a4a465508e2c74ee5b392755932ca6a4d%40thread.tacv2/User%2520Management?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width="780px"
		nav_links={[
      {href:'#business', label:"Business Context"},
      {href:'#api_docs', label:"User Permission and Authorization Services"},
      {href:'#use_cases', label:"Use Cases"},
      {href:'#videos', label:"Videos"}
		]}
		sections={[
			{
				id:"business",
				title:"Business Context",
				height:"250px",
				type:"business_context",
        content:"These set of services allow students, teachers, and admins to be added to an institution. The functionalities and capabilities each role can perform are also managed by these services. Definitions of entities that make up this category of services can be found here.",
				sections:[{
  				title:"User",
  				color:"#dba63f",
  				text:<ul>
  					<li>Provides the ability to manage users within an Organization.</li>
  					<li>Ability to search for users using their email addresses.</li>
            <li>Manage organization and organization hierarchy data sets either through integration with master data management systems or locally if MDM solution is not available in a particular region.</li>
            <li>Ability to set up and on-board users in bulk (uses IES behind the scenes) enabling faster adoption of a product model.</li>
  				</ul>
  			},
  			{
  				title:"Role",
  				color:"#dd77e4",
  				text:<ul>
  					<li>Provides ability to manage different roles of users, permission groups and permissions. This has been designed as a generic capability where a consumer can define these entities and use them as per their requirements.</li>
  					<li>Fine-grained access to features and functionalities based on role.</li>
  					<li>Authorization check in place to ensure user has sufficient privileges to perform an action.</li>
  				</ul>
  			}]
			},
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Consumers",
        content:<ul>
          <li>HNO</li>
          <li>OEP</li>
          <li>BOEP</li>
          <li>Sonic</li>
          <li>Everest (Active Hub)</li>
        </ul>
      },
			{
				id:"api_docs",
				title:"User Permission and Authorization Services",
				type:"apis",
				tile_height:"200px",
				sections:[
          {
					 title:"Organization",
					 src:"pearson_5.jpg",
					 url:"Organization-API Swagger",
					 content:"The Organization Service manages the definition of Organizations that Product Models have as customers on Global SAM, and enables users to be associated with those Organizations. Each user can have one or more Permission Group. Organizations are not deleted, they are simply deactivated/disabled within Global SAM and are thus no longer available. These Organizations can be retrieved if their ID is known, but they will not show up in any searches. Similarly, an Organization can be deactivated/disabled from within a Product Model Region, but whether the information about Users within the Product Model Region is maintained or not is governed by the retention policy of that Product Model Region. If an Organization is re-enabled for a Product Model Region, and the retention policy for that Product Model Region did not cause the previous information to be removed, then the previous User information will be restored."
          },
					{
					 title:"Permission Check",
					 src:"pearson_1.jpg",
					 url:"PermissionCheck-API Swagger",
					 content:"Provides the ability to check if a user has a particular Permission granted to them at runtime. It performs the logic of parsing the incoming URL request path, loading the appropriate configuration for that URL request path, and then determining if the User the Permissions for that URL request path."
          },
					{
					 title:"Permission System",
					 src:"pearson_2.jpg",
					 url:"PermissionSystem-API Swagger",
					 content:"Stores and manages all the Permissions that are available in Global Gateway. The creation of a Permission is always a developer action tied to the creation of new functionality within the Global SAM system. This is because the developers must know the specific PermissionID in advance in order to perform the Permission Check within the code. So Permissions can never be created dynamically by business users. This also means that retrieving the details of a Permission only rarely occurs as part of the OneUser Permission UI. In general, this service does not need to be accessed very often in Production environments."
          },
					{
					 title:"Product Model Region",
					 src:"pearson_4.jpg",
					 url:"ProductModelRegion-API Swagger",
					 content:"Manages the availability of a Product Model for each Region that Global Gateway supports. This allows for a Product Model to be active in only some of the regions supported by Global Gateway. It also allows for differences in configurations and permissions across different regions within the same Product Model."
          },
					{
					 title:"Product Model",
					 src:"pearson_3.jpg",
					 url:"ProductModel-API Swagger",
					 content:"Defines the Product Models that are available on Global Gateway. It also defines the configurations of each Product Model. The product and the role of each Product Model can be configured. The Product Model role can be further configured by storing the permission group for students and instructors."
          },
					{
					 title:"URL Permission Context",
					 src:"pearson_1.jpg",
					 url:"URLPermissionContext-API Swagger",
					 content:"Stores and manages all of the configurations that determine the context of a URL in GPS. Clients can call this service with any URL to determine what the Permission Context for it is by parsing the URL request path to obtain the appropriate ID. The Permission Context Configuration provides the information necessary for this by specifying the place in the request path where the Related ID can be found."
          },
					{
					 title:"User Search",
					 src:"pearson_2.jpg",
					 url:"UserSearch-API Swagger",
					 content:"Aids in performing simple or complex searches on resources such as users. This service is deployed per region as it it used to store users PII data. It uses elastic search to power the search operations. The filter criteria must include regionId to fetch correct results."
          }
				]
			},
			{
				id:"use_cases",
				type:"custom",
				title:"Use Cases",
        content:<ul>
          <li>A school can bulk upload a list of users through an UI to ActiveHub</li>
          <li>Search for an email address to see if an user account exists before creating a new one</li>
          <li>Support MAT and the hierarchy of schools that make up a MAT for ActiveHub</li>
          <li>Assign roles like school admin, instructors and learns for a school that buys ActiveHub, and the permissions associated with those roles</li>
          <li>Enable create course button on an UI if the logged in user has a role of an instructor</li>
        </ul>
			},
			{
				id:"videos",
				type:"video",
				title:"Videos",
        videos:[{
          title:"Video 1",
          file:"GGW_Final_Version_Sep25.mp4"
        },{
          title:"Group Management",
          file:"GroupManagement.mp4"
        }]
			}
		]}/>;
}
export default UserManagementOverview;
