import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import EcommerceOnboarding from './EcommerceOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Authentication" open={props.handle_open('gps_ecomm_services_v2')}>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Authentication-post_auth_token' name='User authentication'/>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Authentication-post_multipass_token' name="Generate the multipass token for Shopify login based on provided user information"/>
    </SubMenu>
    <SubMenu name="Products" open={props.handle_open('gps_ecomm_services_v2')}>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Products-getProductsByCategory' name='Get products by category ID'/>
    </SubMenu>
    <SubMenu name="Carts" open={props.handle_open('gps_ecomm_services_v2')}>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Carts-post_carts' name='Create Cart'/>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Carts-getOrderForCart' name='Get an Order for cart'/>
    </SubMenu>
    <SubMenu name="Orders" open={props.handle_open('gps_ecomm_services_v2')}>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Orders-getOrders' name='Get orders'/>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Orders-getOrdersForUserOrganization' name='Get orders for Org unit'/>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Orders-get_orders_organization__orgID__qualified_users' name='Get qualified users'/>
    </SubMenu>
    <SubMenu name="Digital Access Codes API" open={props.handle_open('gps_ecomm_services_v2')}>
    <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Digital_Access_Codes_API-post_digital_authenticate' name='Access Code Authentication'/>
      <Item folder='eCommerce' doc='gps_ecomm_services_v2' root={props.root} scroll_to='operations-Digital_Access_Codes_API-post_digital' name='Get Access Codes'/>
    </SubMenu>
  </>;
}

export function docs(){
  return [
    'eCommerce-API',
    'e_commerce_api',
    'gps_ecomm_services_v2'
  ];
}

class EcommerceApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder, doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/ecommerce' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Ecommerce and Marketing'
      overview_route='/ecommerce'
      onboarding_title='Onboarding'
      onboarding_route='/ecommerce/onboarding'
      onboarding_component={<EcommerceOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default EcommerceApi;
