import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import OverviewPage from '../../Overview/OverviewPage';
import NavButton from '../../../Header/Navigation/NavButton';
import Image from '../../../Helper/Image';

function ThirdPartyOverview(props){
	return <OverviewPage
		url={(doc) => {return "/product/api/Third Party/" + doc}}
		title="Third Party Services"
		subtitle={<>
      <p>This suite of services integrates with vendors’ capabilities rather than encouraging point to point integrations. This allows to keep the implementations cleaner as well as enforce Pearson’s standards and taxonomies.</p>
      <NavButton style={{backgroundColor:"white"}}to="https://one-confluence.pearson.com/display/K12X/Product+Management+Services">More information</NavButton>
    </>}
		service_owner={{
      owner:"Srinivas Karedla",
  		user:"srinivas.karedla@pearson.com",
  		href:"https://teams.microsoft.com/l/channel/19%3a1b2e586a61614961a63b799a2b50363a%40thread.tacv2/Product%2520Management?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width="620px"
		nav_links={[
      {href:'#third_party', label:"Third Party Services"},
      {href:'#use_cases_tp', label:"Third Party Use Cases"},
      {href:'#learnosity', label:"Learnosity Integration"},
      {href:'#use_cases', label:"Learnosity Use Cases"},
      {href:'#videos', label:"Videos"}
		]}
		sections={[
      {
        id:"third_party",
        type:"custom",
        title:"Third Party Services",
        content:"This suite of services integrates with vendors’ capabilities rather than encouraging point to point integrations. This allows to keep the implementations cleaner as well as enforce Pearson’s standards and taxonomies."
      },
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Third Party Consumers",
        content:<ul>
          <li>HNO</li>
          <li>OEP</li>
          <li>BOEP</li>
          <li>Sonic</li>
          <li>Everest (Active Hub)</li>
        </ul>
      },
      {
        id:"third_party_capabilities",
        type:"custom",
        title:"Third Party Capabilities",
        content:<ul>
          <li>Speech Analysis - The service returns feedback based on metrics like pronunciation and emphasis for an audio input and integrates with a vendor called Speechace.</li>
          <li>LTI – Implements 1.3 provider specifications and standards, and 1.3 consumers is in development. This enables capabilities like single sign on, grade sync, deep links between Pearson and LMS. This provides a seamless experience to users when they switch between Pearson and their organizations’ adopted LMS.
            <Image centered="80%" style={{width:"100%"}} alt="Product Management Resource Flowchart" src="project_everest-tool_provider_consumer.png"/>
          </li>
          <li>SIS – Integrates with Student Information Systems to bring in user and course data from a school’s SIS into GPS so that users don’t have to manually create those data sets again. This enables day 1 on boarding of users to a product model. Now there is an integration with a vendor called GroupCall, who follows a 1 to many model where the vendor has integrated with multiple SIS out there. This comes with a SPA as well so that consumers can use the full stack experience.
            <Image centered="80%" style={{width:"100%"}} alt="Product Management Resource Flowchart" src="project_everest-mis_integration-component_diagram.png"/>
          </li>
        </ul>
      },
      {
        id:"use_cases_tp",
        type:"custom",
        title:"Third Party Use Cases",
        content:<ul>
          <li>Instead of integrating with a vendor directly for OEP, build an abstraction layer within GPS for speech analysis. This prevents tight coupling with a vendor and opens opportunities to integrate with multiple vendors for the same capability</li>
          <li>Ability for an instructor or a student to launch a course from their LMS, and land on a course for HNO</li>
          <li>Bring in students and instructors' data from a SIS that a school in UK using to ActiveHub so that those data sets do no need to be created again</li>
          <li>Deep link to content outside Pearson’s ecosystem from a Pearson course</li>
        </ul>
      },
      {
        id:"learnosity",
        type:"custom",
        title:"Learnosity Integration Services",
        content:"These services provide integration with Learnosity and provides a seamless user experience for authoring and delivering assessments and retrieving scores."
      },
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Learnosity Consumers",
        content:<ul>
          <li>OEP</li>
          <li>BOEP</li>
          <li>Sonic</li>
          <li>Everest (Active Hub)</li>
        </ul>
      },
      {
        id:"learnosity_capabilities",
        type:"custom",
        title:"Learnosity Capabilities",
        content:<ul>
          <li>Learnosity Event Broker – This long polls Learnosity and bring in user session and scores into GPS for gradebook and insights.</li>
          <li>Learosity Signing Service - The Learnosity Signing service will take Learnosity Item API initialization requests for Preview functionality and generate a signed request while keeping actual Learnosity credentials secure.</li>
        </ul>
      },
			{
				id:"use_cases",
				type:"custom",
				title:"Learnosity Use Cases",
        content:<ul>
          <li>For ActiveHub bring in users’ marks from Learnosity into ActiveHub</li>
          <li>Launch as assessment authoried in Learnosity within ActiveHub</li>
        </ul>
			},
      {
        id:"videos",
        type:"video",
        title:"Videos",
        videos:[{
          title:"LTI v1.0 Demo",
          file:"LTI-3rdParty.mp4"
        }]
      }
		]}/>;
}
export default ThirdPartyOverview;
