import React from 'react';
import NavigationButton from './NavigationButton.js'
import NavHamMenu from './NavHamMenu.js'
import {Custom,tabletMax,desktopMin} from '../../Responsive'
import SearchField from '../SearchField'

class Navigation extends React.Component {
  constructor(props){
    super(props)
    this.state = {open:false};
  }

  handleClick() {
      this.setState({
          open: !this.state.open
      });
  }

  render() {
    return(
      <div className="nav_group">
        <Custom screen={{ minWidth: desktopMin + 175}}>
          <SearchField/>
          <NavigationButton to="/develop" color="primary">Develop</NavigationButton>
          <NavigationButton to="/faq" color="primary">FAQ</NavigationButton>
          <NavigationButton external to="https://one-confluence.pearson.com/display/APIMgmt/API+Documentation" color="primary">Non-GPS API's</NavigationButton>
        </Custom>
        <Custom screen={{ maxWidth: tabletMax + 175 }}>
          <NavHamMenu>
            <br/><br/>
            <SearchField/><br/><br/>
            <NavigationButton to="/develop" color="primary">Develop</NavigationButton><br/><br/>
            <NavigationButton to="/faq" color="primary">FAQ</NavigationButton><br/><br/>
            <NavigationButton external to="https://one-confluence.pearson.com/display/APIMgmt/API+Documentation" color="primary">Non-GPS API's</NavigationButton><br/><br/>
          </NavHamMenu>
        </Custom>
      </div>
    );
  }
}

export default Navigation;
