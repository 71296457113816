import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import CourseOnboarding from './CourseOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Courses" open={props.handle_open('Course-API_Swagger')}>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getEventDeatilsUsingPOST' name='Get Product Model Region Events'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getCourseByCourseIdUsingGET' name='Get Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-updateCourseByCourseIdUsingPUT' name='Update Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-associateExternalIdUsingPUT' name='Add ExternalID to Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-addResourceUsingPOST' name='Add Resource to Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-deleteResourceUsingDELETE' name='Remove Resource from Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getResourcesUsingGET' name='Get All Resources in Course'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getEventDeatilsUsingPOST_1' name='Get Product Model Region Events by Organization'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getCoursesByOrganizationIdUsingGET' name='Get Courses by Organization'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-getCoursesByOrganizationIdAndProductModelRegionIdUsingGET' name='Get Courses by Product Model Region and Organization'/>
      <Item folder='Course Management' doc='Course-API_Swagger' root={props.root} scroll_to='operations-course-controller-createCourseUsingPOST' name='Create a New Course'/>
    </SubMenu>
    <SubMenu name="Course Instance" open={props.handle_open('CourseInstance-API_Swagger')}>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getEventDeatilsBypmrIdUsingPOST' name='Get All Events in a Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getCourseInstanceUsingGET' name='Get Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-updateCourseInstanceUsingPUT' name='Update Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-deleteCourseInstanceUsingDELETE' name='Deactivate Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-addAssignmentToCourseInstanceUsingPOST' name='Add Assignment to Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getAssignmentDetailsUsingGET' name='Get Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-updateAssignmentDetailsUsingPUT' name='Update Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-deleteAssignmentFromCourseInstanceUsingDELETE' name='Remove Assignment from Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getAssignmentDetailsUsingGET_1' name='Get Assignment in Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-updateAssignmentDetailsUsingPUT_1' name='Update Assignment in Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-addTocNodeToAssignmentUsingPOST' name='Add TOC to Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-deleteTocNodeFromAssignmentUsingDELETE' name='Remove TOC from Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getTocNodesUsingGET' name='Get TOCs from Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-reOrderTocNodesInAssignmentUsingPOST' name='Reorder List of TOCs in Assignment'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getAssignmentsUsingGET' name='Get Active Assignments in Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getAssignmentsUsingGET_1' name='Get Active Assignments in Course Instance by Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-reOrderAssignmentsUsingPOST' name='Reorders Assignments in Course Instance'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getCourseInstanceInternalUsingGET' name='Get Temporary Course Instance Details'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getCourseInstanceUsingGET_1' name='Get Course Instance by Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-updateCourseInstanceUsingPUT_1' name='Update Course Instance in Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getEventDeatilsUsingPOST' name='Get All Events in Course Instance by ProductModelRegionID'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getCourseInstancesByCourseUsingGET' name='Get Active Course Instances for Course'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getAllCourseInstancesByCourseUsingGET_1' name='Get All Course Instances for Course'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-getCourseInstancesByCourseUsingGET_1' name='Get All Course Instances for Course by Locale'/>
      <Item folder='Course Management' doc='CourseInstance-API_Swagger' root={props.root} scroll_to='operations-course-instance-controller-createNewCourseInstanceUsingPOST' name='Create a New Course Instance'/>
    </SubMenu>
    <SubMenu name="Course Instance Consumption" open={props.handle_open('CourseInstanceConsumption-API_Swagger')}>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getAssignmentDetailsUsingGET' name='Get Assignment Details'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getAllAssignmentDetailsUsingGET' name='Get All Assignment Details'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getRelatedContentItemIdAssignmentsUsingGET' name='Get Related Content Item ID Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getRosterInfoUsingGET' name='Get Roster Info'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getTocNodeAncestorsUsingGET' name='Get TOC Node Ancestors'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getTocNodeAssignmentsUsingGET' name='Get TOC Node Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getTocNodeAggregatedAssignmentsUsingGET' name='Get TOC Node Aggregated Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getTocNodeNestedAssignmentsUsingGET' name='Get TOC Node Nested Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getChildrenNodesUsingGET' name='Get Children Nodes'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getNextNodeUsingGET' name='Get Next Node'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getParentDetailsUsingGET' name='Get Parent Details'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getUserUsingGET_1' name='Get User'/>
      <Item folder='Course Management' doc='CourseInstanceConsumption-API_Swagger' root={props.root} scroll_to='operations-consumption-controller-getUserUsingGET' name='Get User by ProductModelRegionID'/>
    </SubMenu>
    <SubMenu name="Course Instance TOC" open={props.handle_open('CourseInstanceTOC-API_Swagger')}>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getEventDeatilsBypmrIdUsingPOST' name='Get All Events in a Course Instance TOC'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getCourseInstanceDetailsUsingGET' name='Get Course Instance Details'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-createTOCUsingPOST' name='Create TOC for Course Instance'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-updateCourseInstanceDetailsUsingPUT' name='Update Course Instance'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-createCustomContentUsingPOST' name='Create a Record for Custom Content'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getCustomContentUsingGET' name='Get Custom Content'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-updateCustomContentUsingPUT' name='Update Custom Content'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-generateTOCUsingPOST' name='Generate TOC for Course Instance'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-createTocNodeUsingPOST' name='Create New TOC Node'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getTOCNodeDetailsUsingGET' name='Get TOC Node Details'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getChildNodesUsingGET' name='Get Child Node Details'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-reorderNodesUsingPOST' name='Reorder Nodes'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-moveTocNodeToNewParentUsingPOST' name='Update TOC Node Parent'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-addTocNodeToParentNodeUsingPOST' name='Add a Child Node to TOC Node'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-removeNodeFromParentNodeUsingDELETE' name='Remove Child Node from TOC Node'/>
      <Item folder='Course Management' doc='CourseInstanceTOC-API_Swagger' root={props.root} scroll_to='operations-course-instance-toc-controller-getEventDeatilsUsingPOST' name='Get All Events in Course Instance'/>
    </SubMenu>
    <SubMenu name="Course Instance User Assignment" open={props.handle_open('CourseInstanceUserAssignment-API_Swagger')}>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getEventDeatilsUsingPOST' name='Get Event Details'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-createMultipleUserAssignmentUsingPOST' name='Create Multiple User Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-updateMultipleUserAssignmentUsingPUT' name='Update Multiple User Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-removeAllUserAssignmentUsingDELETE' name='Remove All User Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getEventDeatilsUsingPOST_1' name='Get Event Details by Organization'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getUserAssignmentDetailUsingGET' name='Get User Assignment Details'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-createUserAssignmentUsingPOST' name='Create User Assignment'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-udateUserAssignmentUsingPUT' name='Update User Assignment'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-deleteUserAssignmentDetailUsingDELETE' name='Delete User Assignment'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getUserAssignmentDetailsUsingGET' name='Get Temporary User Assignment'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getTocNodeStatusUsingGET' name='Get TOC Node Status'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-updateTocNodeStatusUsingPUT' name='Update TOC Node Status'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getTocNodesForUserAssignmentUsingGET' name='Get TOC Nodes for User Assignment'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-reorderTocNodesUsingPOST' name='Reorder TOC Nodes'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getUserAssignmentsUsingGET' name='Get All User Assignments'/>
      <Item folder='Course Management' doc='CourseInstanceUserAssignment-API_Swagger' root={props.root} scroll_to='operations-course-instance-assignments-controller-getActiveUserAssignmentsUsingGET' name='Get Active User Assignments'/>
    </SubMenu>
    <SubMenu name="Roster" open={props.handle_open('Roster-API_Swagger')}>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getRosterByRosterIdUsingGET' name='Get Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-updateRosterByRosterIdUsingPUT' name='Update Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getRosterByRosterIdUsingGET_1' name='Get Roster by Locale'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-updateRosterByRosterIdUsingPUT_1' name='Update Roster by Locale'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getOtherUsersByRosterIdUsingGET' name='Get Other Users By Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getOtherUsersByRosterIdAndPermissionGroupIdUsingGET' name='Get Other Users by Roster and Group'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getOtherUserDetailsByRosterIdAndUserIdUsingGET' name='Get Other Users Roster and User ID'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-addOtherUserToRosterUsingPOST' name='Add Other User to Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-updateOtherUserDetailsByRosterIdAndUserIdUsingPUT' name='Update Other User by Roster and User ID'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-removeOtherUserFromRosterUsingDELETE' name='Remove Other User from Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getStudentsByRosterIdUsingGET' name='Get Students by Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-addStudentToRosterUsingPOST' name='Add Student to Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-removeStudentFromRosterUsingDELETE' name='Remove Student from Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getTeachersByRosterIdUsingGET' name='Get Teachers by Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-addTeacherToRosterUsingPOST' name='Add Teacher to Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-removeTeacherFromRosterUsingDELETE' name='Remove Teacher from Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getUserPermissionGroupsUsingGET' name='Get User Permission Groups'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getRostersByOrganizationAndProductModelRegionUsingGET' name='Get Rosters by Product Model Region'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getEventDeatilsUsingPOST' name='Get All Events in Roster by Product Model Region'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getRostersByOrganizationAndProductModelRegionUsingGET_1' name='Get All Events in a Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getEventDeatilsBypmrIdUsingPOST' name='Get All Events in Roster'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-getNamesByIsUsingPOST' name='Get Temporary Names'/>
      <Item folder='Course Management' doc='Roster-API_Swagger' root={props.root} scroll_to='operations-roster-controller-createNewRosterUsingPOST' name='Create New Roster'/>
    </SubMenu>
    <SubMenu name="User Assignment Consumption" open={props.handle_open('UserAssignmentConsumption-API_Swagger')}>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getUserAssignmentsByCourseInstanceIdAndContentIdUsingGET' name='Get User Assignments by Course Instance'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getAllAssignmentsByCourseInstanceIdAndNodeIdAndUserIdUsingGET' name='Get All Assignments by Course Instance and Node'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getAggregatedUserAssignmentsByCourseInstanceIdAndNodeIdUsingGET' name='Get Aggregated User Assignments by Course Instance'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getAggregatedUserAssignmentsByCourseInstanceIdAndNodeIdInHierarchicalPassionUsingGET' name='Get Aggregated User Assignments by Course Instance'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getUserAssignmentByCourseInstanceIdAndAssignmentIdAndUserIdUsingGET' name='Get User Assignments by Course Instance and Assignment ID'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getAllAssignmentsByCourseInstanceIdAndUserIdUsingGET' name='Get All Assignments by Course Instance'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getNestedUserAssignmentsByCourseInstanceIdUsingGET' name='Get Nested User Assignments by Course Instance'/>
      <Item folder='Course Management' doc='UserAssignmentConsumption-API_Swagger' root={props.root} scroll_to='operations-user-assignment-controller-getUserAssignmentByCourseInstanceIdAndAssignmentIdUsingGET' name='Get User Assignment by Course Instance and Assignment ID'/>
    </SubMenu>
  </>;
}

export function docs() {
  return [
    'Course-API_Swagger',
    'CourseInstance-API_Swagger',
    'CourseInstanceConsumption-API_Swagger',
    'CourseInstanceTOC-API_Swagger',
    'CourseInstanceUserAssignment-API_Swagger',
    'Roster-API_Swagger',
    'UserAssignmentConsumption-API_Swagger'
  ];
}

class CourseApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/course' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Course Management'
      overview_route='/course'
      onboarding_title='Onboarding'
      onboarding_route='/course/onboarding'
      onboarding_component={<CourseOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default CourseApi;
