import { useMediaQuery } from 'react-responsive'

export const desktopMin = 1020;
export const tabletMax = desktopMin - 1;
export const tabletMin = 868;

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: desktopMin })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: tabletMin, maxWidth: tabletMax })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: tabletMin })
  return isMobile ? children : null
}
export const NotDesktop = ({ children }) => {
  const isNotDesktop = useMediaQuery({ maxWidth: tabletMax })
  return isNotDesktop ? children : null
}
export const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: tabletMin })
  return isNotMobile ? children : null
}
export const Custom = (props) => {
  const isCustom = useMediaQuery(props.screen)
  return isCustom ? props.children : null
}
