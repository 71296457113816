import OverviewPage from '../../Overview/OverviewPage';
function DataOverview(props) {
	return (<OverviewPage
		url={(doc) => { return "/data/api/Enterprise Data/" + doc }}
		title="Enterprise Data Services"
		subtitle="Tools can access the Enterprise Data Warehouse (EDW) to search Pearson's data assets, such as Customer and Product information using these RESTful API services. Tools for Clinical Businesses can also validate Customers and Practitioners using the Digital Account and Eligibility Check services."
		service_owner={{
			owner: "Srinivas Karedla",
			user: "srinivas.karedla@pearson.com",
			href: "https://teams.microsoft.com/l/channel/19%3a9d79e852f8144972afd3f5441784ee98%40thread.tacv2/Enterprise%2520Data?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
		nav_width="660px"
		nav_links={[
			{
				href: "#videos",
				label: "Videos"
			},
			{
				href: "#business",
				label: "Business Context"
			},
			{
				href: "#api_docs",
				label: "Enterprise Data"
			},
			{
				href: "#analytics",
				label: "Analytics"
			},
			{
				href: "#use_cases",
				label: "Use Cases"
			},
		]}
		sections={[
			{
				id: "videos",
				type: "video",
				title: "Videos",
				videos: [{
					title: "Address Validation",
					file: "AddressAPI.mp4"
				}, {
					title: "Customer Demo",
					file: "customerAPI.mp4"
				}, {
					title: "Customer Look Up Demo",
					file: "CustLookUpAPI.mp4"
				}, {
					title: "Digital Account Verification Demo",
					file: "DigitalAccountVerificationAPI.mp4"
				}, {
					title: "Eligibility Check Demo",
					file: "EligibilityCheckAPI.mp4"
				}, {
					title: "Product API Demo",
					file: "ProductAPI.mp4"
				}, {
					title: "RecEngine Demo",
					file: "RecEngineAPI.mp4"
				}, {
					title: "User Profile Demo",
					file: "UserProfileAPI.mp4"
				}]
			},
			{
				id: "business",
				type: "business_context",
				height: "275px",
				sections: [{
					title: "Customer",
					color: "#dba63f",
					text: <ul>
						<li>To obtain customer information including contact info, addresses, purchase eligibility and qualifications</li>
						<li>Checks a customer's address and provides suggested alternatives if an error is discovered</li>
						<li>Provides product details including marketing, manufacturing and pricing info</li>
					</ul>
				},
				{
					title: "Instructors",
					color: "#dd77e4",
					text: <p>Insights API to help educators review class attendance and analysis assessment results</p>
				},
				{
					title: "Consumers",
					color: "#3982c6",
					text: <p>Everest, HNO, Sonic, CRM, ecommerce(stores), higher-ed product platforms, sales & service platforms, third party vendors</p>
				}]
			},
			{
				id: "api_docs",
				type: "apis",
				tile_height: "160px",
				title: "Data APIs",
				sections: [{
					title: "Address Validation",
					url: "AddressDoctor_Swagger",
					src: "pearson_1.jpg",
					content: "This API is used for validating/completing a given address."
				},
				{
					title: "Product",
					url: "product_api_swagger",
					src: "pearson_9.jpg",
					content: "The Product API provides access to Pearson's product information which is stored and managed in the the Master Data Management (Product Data Hub) platform. The API also has a helper functionality which helps to track changes on products. The data the messages will be published to Autobahn."
				},
				{
					title: "Customer",
					url: "Customer-API_Swagger",
					src: "pearson_2.jpg",
					content: "The Customer API provides access to Pearson's customer information which is storage and governed through the Master Data Management (Customer Data Hub) platform. The API also has a helper functionality which helps to track changes on products. The data the messages will be published to Autobahn."
				},
				{
					title: "Account Verification",
					url: "DigitalAccount_API_Swagger",
					src: "pearson_8.jpg",
					content: " The Digital Account Verification API provides validation capabilities which can be used by clinical business. This API can be used to verify the account numbers being used in business processes like e-commerce transactions to ensure fulfillment processing can happen smoothly."
				},
				{
					title: "Customer Lookup",
					url: "CustomerLookup_API_Swagger",
					src: "pearson_4.jpg",
					content: "The Customer Lookup API provides validation capabilities which can be used by clinical business. This API can be used to verify the customer affiliations being used in business processes like e-commerce transactions to ensure fulfillment processing can happen smoothly."
				},
				{
					title: "Eligibility Check",
					url: "EligibilityCheck_API_Swagger",
					src: "pearson_3.jpg",
					content: " The Eligibility Check API provides functionality to check Eligibility Level of a Clinical Practitioner. This is done based on their credentials and can be used to assess if they are qualified to buy a certain product or not."
				},
				{
					title: "User Profile",
					url: "eds_user_profile_api_swagger",
					src: "pearson_7.jpg",
					content: " The User Profile API serves to maintain user information."
				},
				]
			},
			{
				id: "analytics",
				type: "coming_soon",
				title: "Analytics"
			},
			{
				id: "use_cases",
				type: "coming_soon",
				title: "Use Cases"
			},
		]} />);
}
export default DataOverview;
