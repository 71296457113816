import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { get_api_host, get_host_env } from './HostInfo';
const axios = require('axios');

function MyCloud(props){
  const env = get_host_env();

  const initial_state = () => {
    if(env === 'LOCAL'){
      return {
        output:props.children,
        run:false
      };
    } else {
      return {
        output:<></>,
        run:true
      };
    }
  };
  const [data, setData] = useState(initial_state());

  const [cookies] = useCookies(['myCloudProxySession']);
  const get_token = () => {
    if(env === 'LOCAL'){
      return 'test'
    } else {
      return cookies.myCloudProxySession
    }
  };
  if(!get_token()){
    window.location.href = "https://mycloud.pearson.com/redirect?url=" + window.location.href;
    return <></>; // probably not needed, but I want to be extra sure the execution to stop here.
  }

  if(data.run){
    axios.request({
      method:'post',
      url: get_api_host() + "/user/validate",
      data: {token:get_token()},
      headers: {'Content-Type':'application/json'}
    }).then((response) => {
      if('valid' in response.data && (response.data.valid === true || response.data.valid === "true")){
        setData({
          output:props.children,
          run:false
        });
      } else {
        window.location.href = "https://mycloud.pearson.com/redirect?url=" + window.location.href;
      }
    }).catch((error) => {
      window.location.href = "https://mycloud.pearson.com/redirect?url=" + window.location.href;
    });
    setData({
      output:<></>,
      run:false
    });
  }

  return data.output;
}

export default MyCloud;
