import React from 'react';

function DevelopOnboarding(props) {
  return(<div className="section">
    <div className='markdown-wrapper'>
    <h2>Onboarding</h2>
    <ol>
    <li>Get IES Token for Pearson User.</li>
    <li>Build service request with the required parameters:</li>
    <li>&#60; methodType&#62; : method of the request.</li>
    <li>&#60; baseUrl&#62; : environment url of service</li>
    <li>&#60; serviceEndpoint&#62; : endpoint to be reached in service</li>
    <li>&#60; UserId&#62; : Pearson User ID</li>
    <li>&#60; Location&#62; : Location</li>
    <li>&#60; IESToken&#62; : Authorization token from IES</li>
    <li>&#60; JSONBody&#62; : JSON document containing information required for service request</li>
    </ol>
    <p>Example:</p>
    <pre><code class="bash" style={{wordWrap:"break-word"}}>curl --location --request &amp;#60;methodTyp&amp;#62; '&amp;#60;baseUrlForServic&amp;#62;&amp;#60;serviceEndpoin&amp;#62;' \<br/>
    --header 'x-prsn-user-id: &amp;#60;UserI&amp;#62;' \<br/>
    --header 'X-PrefLocation: &amp;#60;Locatio&amp;#62;'(if needed) \<br/>
    --header 'Authorization: &amp;#60;IESToke&amp;#62;' (REQUIRED)\<br/>
    --header 'Content-Type: application/json' \<br/>
    --data-raw '&amp;#60;JSONBod&amp;#62;(if needed)'
    </code></pre>

    <blockquote>
    <p>If the Pearson User has sufficient permissions, the request will go through. Otherwise, the service will return a "403 Forbidden" status code and the request will not be fulfilled.</p>
    </blockquote>
  </div>
  </div>
  );
}

export default DevelopOnboarding;
