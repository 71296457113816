import React from 'react';
import {Typography} from '@material-ui/core';

function CommunicationOnboarding(props) {
  return(<div className="section">
  <div className="markdown-wrapper">
  <Typography style={{color:"#007fa3",paddingTop:"20px",paddingBottom:"20px"}} variant="h3" align="left">Onboarding</Typography>
  <Typography style={{color:"#003057",paddingTop:"20px",paddingBottom:"20px"}} variant="h5" align="left">Group Management</Typography>
    <p style={{position:"relative",left:"20px"}}>Get IES Token for IES User.</p>
    <br/>
  <Typography style={{color:"#003057",paddingTop:"20px",paddingBottom:"20px"}} variant="h5" align="left">Video Conference</Typography>
  <ol>
    <li>Get IES Token for IES User.</li>
    <li>Build service request with the required parameters:<br/>
        <span style={{color:"#007fa3"}}>&#60; methodType &#62;</span> : method of the request.<br/>
        <span style={{color:"#007fa3"}}>&#60; baseUrl &#62;</span> : environment url of service<br/>
        <span style={{color:"#007fa3"}}>&#60; serviceEndpoint &#62;</span> : endpoint to be reached in service<br/>
        <span style={{color:"#007fa3"}}>&#60; UserId &#62;</span> : IES User ID<br/>
        <span style={{color:"#007fa3"}}>&#60; Location &#62;</span> : Location<br/>
        <span style={{color:"#007fa3"}}>&#60; IESToken &#62;</span> : Authorization token from IES<br/>
        <span style={{color:"#007fa3"}}>&#60; JSONBody &#62; </span>: JSON document containing information required for service request<br/>
    </li>
    <li>If the &#60; serviceEndpoint &#62; requires:<br/>
      <span style={{color:"#007fa3"}}>&#123;organizationId&#125;</span> : please visit the Organization Service from User Management to get details such as organizationId from an organization.<br/>
      <span style={{color:"#007fa3"}}>&#123;productModelRegion&#125;</span> : please visit the Product Model Region from User Management to get details such as productModelRegion Id from a productModelRegion.<br/>
      <span style={{color:"#007fa3"}}>&#123;userId&#125;</span> : please visit the Users Service from User Management to get details about an user.<br/>
      <span style={{color:"#007fa3"}}>&#123;conferenceId&#125;</span> : This is obtained from creating a new video conference or by searching for a video conference by userId. For more details please visit Video Conference Service in Collaboration & Communications<br/>
    </li>
  </ol>
  <Typography style={{color:"#003057",paddingTop:"20px",paddingBottom:"20px"}} variant="h5" align="left">Testing Authentication</Typography>
  <div style={{position:"relative",left:"20px"}}>
  <p>Example:</p>
  <pre><code class="bash">curl --location --request &lt;methodType&gt; '&lt;baseUrlForService&gt;&lt;serviceEndpoint&gt;' <br/>
  --header 'x-prsn-user-id: &lt;UserID&gt;' <br/>
  --header 'X-PrefLocation: &lt;Location&gt;'(if needed) <br/>
  --header 'Authorization: &lt;IESToken&gt;' (REQUIRED) <br/>
  --header 'Content-Type: application/json' <br/>
  --data-raw '&lt;JSONBody&gt;(if needed)'
  </code></pre>

  <blockquote>
  <p>If the Pearson User has sufficient permissions, the request will go through. Otherwise, the service will return a "403 Forbidden" status code and the request will not be fulfilled.</p>
  </blockquote>
  </div>
  </div>
  </div>
  );
}

export default CommunicationOnboarding;
