import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import OverviewPage from '../../Overview/OverviewPage';
import NavButton from '../../../Header/Navigation/NavButton';

function CourseOverview(props){
	return(<OverviewPage
		url={(doc) => {return "/course/api/Course Management/" + doc}}
		title="Course Management Services"
		subtitle={<>
      <p>This suite of services provides the ability to support products that are sold within the context of a course. There are single page applications (SPA) called one Course and one Instructor that consume these services to provide the user experience as well. Consumers have the option of using SPAs so that they can reuse the full stack as is or can use services and create their own user experiences.</p>
      <NavButton style={{backgroundColor:"white"}} to="https://one-confluence.pearson.com/pages/viewpage.action?spaceKey=K12X&title=Course+Management+Services">More information</NavButton>
    </>}
		service_owner={{
			owner:"Srinivas Karedla",
			user:"srinivas.karedla@pearson.com",
			href:"https://teams.microsoft.com/l/channel/19%3ad3ec9b75750740b4a5e1806d0c33d128%40thread.tacv2/Course%2520Management?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width = "810px"
		nav_links = {[
			{
				href:"#business",
				label:"Business Context"
			},
			{
				href:"#api_docs",
				label:"Course Management Services"
			},
			{
				href:"#types",
				label:"Course Setup"
			},
			{
				href:"#use_cases",
				label:"Use Cases"
			},
			{
				href:"#videos",
				label:"Videos"
			}
		]}
		sections = {[
				{
					id:"business",
					type:"business_context",
					height:"275px",
          content:"This suite of services provides the ability to support products that are sold within the context of a course. There are single page applications (SPA) called one Course and one Instructor that consume these services to provide the user experience as well. Consumers have the option of using SPAs so that they can reuse the full stack as is or can use services and create their own user experiences. More documentation on the entitles can be found here.",
					sections:[{
						title:"Instructors",
						color:"#dba63f",
						text:<ul>
              <li>Create & manage courses and course instances.</li>
              <li>Create & manage their rosters.</li>
              <li>Create & manage rosters in bulk to enable faster adoption of a product model.</li>
              <li>Create assignments for users who make up a roster.</li>
              <li>View /Edit table of contents for the course to customize their class.</li>
              <li>Associate product(s) with their courses.</li>
              <li>Enter and manage marks for assignments manually or set up automated scoring.</li>
						</ul>
					},
					{
						title:"Students",
						color:"#dd77e4",
						text:<ul>
              <li>Enroll in courses.</li>
              <li>View and complete assignments.</li>
              <li>View table of contents for the course.</li>
              <li>View marks for their completed assignments.</li>
						</ul>
					}]
				},
        {
          id:"business_context_consumers",
          type:"custom",
          title:"Consumers",
          content:<ul>
            <li>HNO</li>
            <li>OEP</li>
            <li>BOEP</li>
            <li>Sonic</li>
            <li>Everest (Active Hub)</li>
          </ul>
        },
				{
					id:"api_docs",
					type:"apis",
					tile_height:"325px",
					title:"Course Management Services",
					sections:[{
						title:"Course Instance Consumption",
						src:"pearson_3.jpg",
						url:"CourseInstanceConsumption-API_Swagger",
						content:"Aggregates entities from multiple different services to provide a read-only view of all the data that a client is likely to require for managing a Course Instance."
					},
					{
						title:"Course Instance",
						src:"pearson_2.jpg",
						url:"CourseInstance-API_Swagger",
						content:"A Course Instance is a specific instance of a Course that is includes a Roster of Users and a list of Assignments to be given to the Students by the Instructor. A Course Assignment is a list of TOC Nodes that represent the content that the User needs to complete, as well as the expected duration that the User is given to complete the Assignment. A Course Assignment is converted into a Course User Assignment when it is assigned to a specific User."
					},
					{
						title:"Course Instance Table of Contents",
						src:"pearson_4.jpg",
						url:"CourseInstanceTOC-API_Swagger",
						content:"This allows an Instructor to customize the TOC if they wish. Conceptually a TOC is a series of Nodes, where each Node can have children Nodes. The TOC begins with a Root Node, and from there one can traverse the children. Each TOC node can be tied to a related Content Item, if it is derived from a Content Item from the Manifest API. But it is also possible for Instructors to create their own TOC Node from scratch, in which case there will not be a related Content Item."
					},
					{
						title:"Course Instance User Assignments",
						src:"pearson_5.jpg",
						url:"CourseInstanceUserAssignment-API_Swagger",
						content:"It tracks what the User has done for each TOC Node that is part of the Assignment. There are several states to a User's progress through a TOC Node: not started, started, and completed. here is also a Status Marker that gives more information about where in the TOC Node the user is. Depending on the type of Content that the TOC Node points to, as well as the Status of the TOC Node, the Status Marker will contain a different item."
					},
					{
						title:"Course",
						src:"pearson_8.jpg",
						url:"Course-API_Swagger",
						content:"A Course is an Organization-specific template that is used to generate Course Instances. It defines the Resource(s) that are used as part of the Course and has the following properties: organizationId, name, productModelId, a list of the IDs of the Resources that are part of this Course, and a list of external Ids."
					},
					{
						title:"Roster",
						src:"pearson_1.jpg",
						url:"Roster-API_Swagger",
						content:"Stores and manages the list of Users who are grouped together to participate in an activity together, typically a Course Instance. A Roster can be associated with multiple Course Instances. For example, in the K-12 schools market, it is not unusual for young learners to learn multiple subjects across a school day with the same teacher in the same classroom. Each subject will be its own Course Instance because the Resources and Assignments will differ based on the subject being taught, but the Roster itself is the same."
					},
					{
						title:"User Assignment Consumption",
						src:"pearson_2.jpg",
						url:"UserAssignmentConsumption-API_Swagger",
						content:"Aggregates entities from multiple different services to provide a read-only view of all the data that a client is likely to require for managing a User Assignments. It will also maintain some statistical information regarding user assignments, aggregated at different levels. Every User Assignment can have multiple TOC Nodes, which can each have its own status of (notstarted, started, completed). The status of the assignment itself is derived from the individual TOC Node status. Whenever a user assignment's TOC Node status is updated, the following rules are applied to persist the status of the assignment itself in the consumption service. Every time a TOC Node is updated, an event is generated. This Consumption Service will act on that, and update the user assignment's overall status."
					}]
				},
				{
					id:"types",
					type:"custom",
					title:"Course Setup",
					content:<Grid item md={12}>
						<Typography variant="body2">
							<p>A Course can be setup in several ways depending on the requirements of the Product Model:</p>
						</Typography>
						<Typography style={{color:"#007fa3",paddingTop:"0px"}} variant="h5" align="left">
							Administrator
						</Typography>
						<Typography variant="body2">
							<p>When an Organization decides to start utilizing a Resource or Resources for a Course, an Administrator will use One Course to create a new Course for that Organization, Product Model Region, and Resource(s) combination.</p>
						</Typography>
						<Typography style={{color:"#007fa3",paddingTop:"0px"}} variant="h5" align="left">
							User
						</Typography>
						<Typography variant="body2">
							<p>As Users begin signing up for the Course, an Administrator will use One Course to create Rosters and Course Instances for each Roster.</p>
						</Typography>
						<Typography style={{color:"#007fa3",paddingTop:"0px"}} variant="h5" align="left">
							Instructor
						</Typography>
						<Typography variant="body2">
							<p>Instructors will then use One Instructor to finish setting up their Course Instance:</p>
							<ul>
								<li>Instructors will set up Assignments</li>
								<li>If an Instructor wishes to use custom content, they will set up the custom content and add them to Assignments</li>
								<li>Once the Course Instance begins, Instructors will assign Assignments to specific Students</li>
								<li>Instructors will monitor the progress of their Students in their Course Instance</li>
							</ul>
						</Typography>
					</Grid>
				},
				{
					id:"use_cases",
					type:"custom",
					title:"Use Cases",
          content:<ul>
            <li>Instructors can create a course for HNO that runs for two years with a start and end date</li>
            <li>Students can register in a course for HNO that has been created by an instructor</li>
            <li>Instructors can create assignments from a TOC of a course for the students enrolled in a course</li>
            <li>Instructors can set up their scoring policies for their course for ActiveHub</li>
            <li>Instructors can add links to their own resources in a TOC of a course for ActiveHub</li>
          </ul>
				},
        {
          id:"videos",
          type:"video",
          title:"Videos",
          videos:[{
            title:"Student Progress and Assessment Widgets",
            file:"StudentProgressDemo.mp4"
          }]
        }
		]}/>);
}
export default CourseOverview;
