import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '@material-ui/core';
import {createTheme, ThemeProvider} from '@material-ui/core/styles'

// Styling for material-ui components
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontSize: 16,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        '&:hover': {
          backgroundColor: "#007FA3",
          boxShadow: 'none',
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: '#007FA3',
          boxShadow: 'none',
        }
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#007FA3',
          boxShadow: 'none',
        }
      },
      containedSecondary: {
        boxShadow: "none",
        '&:hover': {
          backgroundColor: "#ffffff",
          boxShadow: 'none',
        },
        '& > .MuiButton-label': {
          color: '#212121',
          boxShadow: "none",
          fontSize: '13px',
        },
        '& > .MuiButton-label:hover': {
          '& .circle': {
            color: 'grey',
            borderColor: 'grey',
          },
          backgroundColor: "#ffffff",
          boxShadow: 'none',
          color: 'grey',
          fontSize:'14px',
        },
      },
    },
  },
})

function NavigationButton(props){
  let history = useHistory();

  let handle_click = () => {
    if(props.external){
      window.open(props.to);
    } else {
      history.push(props.to);
    }
  }

  return(
    <ThemeProvider theme={theme}>
    <Button
      variant="contained"
      color={props.color}
      onClick={handle_click}
    >
      {props.children}
    </Button>
    </ThemeProvider>
  );
}

export default NavigationButton;
