import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import OverviewPage from '../../Overview/OverviewPage';
import NavButton from '../../../Header/Navigation/NavButton';
import Image from '../../../Helper/Image';

function ProductOverview(props){
	return <OverviewPage
		url={(doc) => {return "/product/api/Product Management/" + doc}}
		title="Product Management Services"
		subtitle={<>
      <p>The Product Management Services provide the definition and management of Products and SKUs (offers), as well as the configuration for granting access to those Products. Where MDM implementation is available integration with MDM platforms exist to bring in product data from MDM into GPS as well.</p>
      <NavButton style={{backgroundColor:"white"}}to="https://one-confluence.pearson.com/display/K12X/Product+Management+Services">More information</NavButton>
    </>}
		service_owner={{
      owner:"Srinivas Karedla",
  		user:"srinivas.karedla@pearson.com",
  		href:"https://teams.microsoft.com/l/channel/19%3a1b2e586a61614961a63b799a2b50363a%40thread.tacv2/Product%2520Management?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width="840px"
		nav_links={[
      {href:'#business', label:"Business Context"},
      {href:'#api_docs', label:"Product Management Services"},
      {href:'#types', label:"Types of Entities"},
      {href:'#use_cases', label:"Use Cases"},
      {href:'#videos', label:"Videos"}
		]}
		sections={[
			{
				id:"business",
				title:"Business Context",
				height:"1100px",
				type:"business_context",
        content:"The Product Management Services provide the definition and management of Products and SKUs (offers), as well as the configuration for granting access to those Products. Where MDM implementation is available integration with MDM platforms exist to bring in product data from MDM into GPS as well.",
				sections:[{
  				title:"Product Management Entities",
  				color:"#dba63f",
  				text:<>
            <p>The following are the entities that are within the Product Management System. They are separated into two categories:</p>
            <ul>
              <li>Resource Entities: Entities that describe the Resource that a customer can utilize. The "what" that a customer has access to.</li>
              <li>Entitlement Entities: Entities that describe "how" a customer can get access to a Resource.</li>
            </ul>
  				</>
  			},
  			{
  				title:"Resource Entities",
  				color:"#dd77e4",
  				text:<>
  					<p>These entities represent the Resource itself, what components it has. This is the "what" that customer can get access to.</p>
            <ul>
              <li>Resource: this is an authored package of content that is designed to teach a student a topic. Each authored package is a single Resource. These form the foundation of the educational material that is given to a student. A Resource always targets a specific Product Model, and one or more Regions. Authors know explicitly which Product Model they are targeting for, and which Region(s) they are targeting with that content.</li>
              <li>TOC: The Table of Contents is a structure that lists out where all the pieces of Content sit within a Resource. It groups the Content into structural entities such as Sections, Tasks, and Modules. Different Product Models have different types of structural entities.</li>
              <li>Objective, Objective Framework and Alignment: many Resources are designed to teach a learner mastery of certain Objectives. These Objectives form a separate Framework or Curriculum that the individual pieces of Content within the Resource are aligned to.</li>
              <li>Assessment and Assessment Item: Assessment Items are special types of Content within a Resource that are designed to determine if a learner has mastered an Objective. Multiple Assessment Items can be grouped together into an Assessment.</li>
              <li>Scoring Policy: The Scoring Policy determines how Assessment Items are scored. This governs how many points an Assessment Item is worth and provides rules around how the student interacts with Assessment Item, such as how many times a student can take an Assessment Item or whether they get Feedback if they get the Assessment Item wrong.</li>
            </ul>
            <p>This can be illustrated with following diagram:</p>
            <Image style={{width:"550px"}} alt="Product Management Resource Flowchart" src="product_management_resource_chart.png"/>
  				</>
  			}]
			},
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Consumers",
        content:<ul>
          <li>HNO</li>
          <li>OEP</li>
          <li>BOEP</li>
          <li>Sonic</li>
          <li>Everest (Active Hub)</li>
        </ul>
      },
			{
				id:"api_docs",
				title:"User Permission and Authorization Services",
				type:"apis",
				tile_height:"550px",
				sections:[
          {
						title:"Access Code",
						url:"EntitlementAccessCodes-API_Swagger",
						src:"pearson_3.jpg",
						content:"Used to create subscriptions based on the Entitlement of a SKU. Typically, an access code is generated if certain purchases are made, such as an E-Commerce Purchase, Physical Purchase, Organizational bulk purchase or single purchase, or a single instance purchase. Once an Access Code is redeemed, it can no longer be used. Each Access Code has an internal ID that is used to identify it. As the ID is difficult to use for public-facing purposes, a separate Access Code Value is provided. Typically this Access Code Value is shorter and easier to read/print/hand out to Users."
					},
          {
						title:"Entitlement",
						url:"Entitlement-API_Swagger",
						src:"pearson_2.jpg",
						content:"llows an Entitlement Window to be placed on the Resources within a Stock Code Unit (SKU), thus enabling access to that SKU to be made available via an Access Code, License, or other access mechanism. For example, if an Entitlement Window has a Time-Lapse of three months, then the Subscription will be created with a Subscription Window that lasts three months from today. If an SKU has multiple Resources within it, then multiple Subscriptions will be created for the SKU."
					},
          {
						title:"Resource",
						url:"Resource-API_Swagger",
						src:"pearson_4.jpg",
						content:<>
              <p>Enables management of Resources withing GPS. A Resource is a package of Content that can be provided to a Student as part of an education experience. In some organizations, this would also be called a Product. There are several possible ways that a resource can be used:</p>
  						<ul>
  							<li>A single resource can be authored to provide and entire course-worth of education material. In this case, there is a one-to-one relationship between a resource and the course it is used in (most common scenario)</li>
  							<li>Multiple resources can be authored to provide a single course-worth of education material. In this case, there is a many-to-one relationship between resources and the source they are in (less common scenario)</li>
  							<li>A single resource can be used in multiple different courses. In this case, there is a one-to-man relationship between a resource and the courses it is used in (uncommon scenario)</li>
  						</ul>
            </>
					},
          {
						title:"SKU",
						src:"pearson_2.jpg",
						url:"SKU-API_Swagger",
						content:"Acts as the bridge between GPS and the e-commerce/financial systems that govern what a User is able to actually purchase. It contains a representation of all the SKUs that are available for sale. For most Product Models and Regions, a SKU will map directly to a single Resource. In other words, usually a User can directly purchase a single Resource. However, there are a few use cases where a single SKU points to multiple Resources, i.e. a User can purchase multiple Resources with just a single transaction."
					},
          {
						title:"Subscription",
						src:"pearson_5.jpg",
						url:"Subscription-API_Swagger",
						content:"Contains the window of time that a particular User has access to a given Resource. An Active Subscription is a Subscription that allows access to a Resource for a User at the given time that the request is made and has a start and an end date time along with a status. In addition, the access type of the subscription can also be determined and can be through an access code, a license, or an administrator."
					}
				]
			},
      {
        id:"types",
        type:"custom",
        title:"Types of Entities",
        content: <Grid item md={12}>
          <Typography variant="body2">
            <p>The following are the entities that are within the Product Management System. They are separated into two categories:</p>
          </Typography>
          <div>
            <Typography style={{color:"#007fa3",paddingTop:"0px"}} variant="h5" align="left">
              Resource Entities
            </Typography>
            <Typography variant="body2">
              <p>Entities that describe the Resource that a customer can utilize. The "what" that a customer has access to.</p>
                <span style={{fontWeight:"600"}}>What is a Resource?</span> : This is an authored package of content that is designed to teach a student a topic. Each authored package is a single Resource. These form the foundation of the educational material that is given to a student. A Resource always targets a specific Product Model, and one or more Regions. Authors know explicitly which Product Model they are targeting for, and which Region(s) they are targeting with that content.
                <ul>
                  <li><span style={{fontWeight:"600"}}>TOC</span>: the Table of Contents is a structure that lists out where all the pieces of Content sit within a Resource. It groups the Content into structural entities such as Sections, Tasks, and Modules. Different Product Models have different types of structural entities.</li>
                  <li><span style={{fontWeight:"600"}}>Objective, Objective Framework and Alignment</span>: Many Resources are designed to teach a learner mastery of certain Objectives. These Objectives form a separate Framework or Curriculum that the individual pieces of Content within the Resource are aligned to.</li>
                  <li><span style={{fontWeight:"600"}}>Assessment and Assessment Item</span>: Assessment Items are special types of Content within a Resource that are designed to determine if a learner has mastered an Objective. Multiple Assessment Items can be grouped together into an Assessment.</li>
                  <li><span style={{fontWeight:"600"}}>Scoring Policy</span>: the Scoring Policy determines how Assessment Items are scored. This governs how many points an Assessment Item is worth, and provides rules around how the student interacts with Assessment Item, such as how many times a student can take an Assessment Item or whether they get Feedback if they get the Assessment Item wrong.</li>
                </ul>
            </Typography>
            <Typography style={{color:"#007fa3",paddingTop:"0px"}} variant="h5" align="left">
              Entitlement Entities
            </Typography>
            <Typography variant="body2">
                <p>Entities that describe "how" a customer can get access to a Resource</p>
                <ul>
                <li><span style={{fontWeight:"600"}}>SKU</span>: these are the specific financial items that can be sold to customers. SKUs are tied to the e-Commerce/Financial systems that govern what customers can purchase.</li>
                <li><span style={{fontWeight:"600"}}>Entitlement</span>: An Entitlement is a mechanism to grant access to a SKU to one or more students.  An Entitlement is not the actual access to the SKU for a particular user, rather it is the template that the access is based on. An Entitlement is redeemed by a User, which creates a Subscription that provides the User access to the SKU(s). There are three mechanisms by which a User can redeem an Entitlement:</li>
                <li><span style={{fontWeight:"600"}}>Entitlement Window</span>: an Entitlement Window is the period of time that an Entitlement is valid for. This is typically a rule, such as a time period/duration. The Subscription uses this rule to create an actual window of time when the Subscription is valid. Each Resource can have a different Entitlement Window within the Entitlement.</li>
                <li><span style={{fontWeight:"600"}}>Subscription</span>: A Subscription is the authorization for a specific User for a specific Resource.</li>
                </ul>
            </Typography>
          </div>
        </Grid>
      },
			{
				id:"use_cases",
				type:"custom",
				title:"Use Cases",
        content:<ul>
          <li>Bring products and offers automatically from product MDM into GPS for instructors to adopt, and create their courses</li>
          <li>Manually configure products and offers for OEP where MDM solution is not available, but is planned for future</li>
        </ul>
			},
      {
				id:"videos",
				type:"coming_soon",
				title:"Videos"
			}
		]}/>;
}
export default ProductOverview;
