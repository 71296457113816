import React from 'react';
import {Typography} from '@material-ui/core'


class Consideration extends React.Component{
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div style={{background:"white"}}>
        <h1 style={{background:"white"}}>
        Considerations for Building a Product
        </h1>
        <br/>
        <br/>
        <br/>
        <br/>
        <Typography variant="body1" className="welcome_hook_text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Lorem ipsum dolor sit amet, quis nostrud exercitation
        </Typography>
        <Typography variant="body1" className="welcome_hook_text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Lorem ipsum dolor sit amet, quis nostrud exercitation
        </Typography>
        <Typography variant="body1" className="welcome_hook_text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Lorem ipsum dolor sit amet, quis nostrud exercitation
        </Typography>
        <Typography variant="body1" className="welcome_hook_text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Lorem ipsum dolor sit amet, quis nostrud exercitation
        </Typography>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Consideration;
