import React from 'react';
import OverviewPage from '../../Overview/OverviewPage';
import NavButton from '../../../Header/Navigation/NavButton';

function CustomerOverview(props) {
  return <OverviewPage
		url={(doc) => {return "/customer/api/Customer Experience/" + doc}}
		title="Customer Experience Services"
		subtitle={<>
      <p>These services help power the user experiences on the global gateway and/or product model experiences, and enhance the core suite of services like product, course, and user management services.</p>
      <NavButton style={{backgroundColor:"white"}}to="https://one-confluence.pearson.com/pages/viewpage.action?spaceKey=K12X&title=Customer+Experience+Services">More information</NavButton>
    </>}
		service_owner={{
      owner:"Srinivas Karedla",
      user:"srinivas.karedla@pearson.com",
      href:"https://teams.microsoft.com/l/channel/19%3a1707e875b3be451aa7b1de88c27baf61%40thread.tacv2/Customer%2520Experience?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b",
		}}
    nav_width="820px"
		nav_links={[
      {href:'#business', label:"Business Context"},
      {href:'#global-gateway-services', label:"Global Gateway Services"},
      {href:'#notification-services', label:"Notification Services"},
      {href:'#use_cases', label:"Use Cases"},
      {href:'#videos', label:"Videos"}
		]}
		sections={[
			{
				id:"business",
				title:"Business Context",
				height:"300px",
				type:"business_context",
        content:<p>These services help power the user experiences on the global gateway and/or product model experiences, and enhance the core suite of services like product, course, and user management services. More information on these services can be found <a href="https://one-confluence.pearson.com/pages/viewpage.action?spaceKey=K12X&title=Customer+Experience+Services">here</a>.</p>,
				sections:[{
  				title:"Global Gateway",
          color:"#dba63f",
  				text:<ul>
              <li>Ability to define what product models are available in the gateway.</li>
              <li>Ability to define what products models' users can access in the gateway.</li>
              <li>Ability to configure gateway for different regions.</li>
              <li>Ability to configure what products are available for each product model.</li>
            </ul>
  			},
  			{
  				title:"Instructors/Learners",
          color:"#dd77e4",
  				text:<ul>
            <li>Provides the ability for instructors and students to have discussions asynchronously against authored content items.</li>
            <li>Support in-app and email notifications for users for examples:
              <ul>
                <li>Learner can get a notification that an assignment is past due, or a deadline is coming up.</li>
                <li>Instructor can get an event notification that a video conference is coming up.</li>
              </ul>
            </li>
            <li>Ability to compose data from different systems of authorities for example profile, entitlements and subscription of a user to provide better and faster customer support.</li>
          </ul>
  			}]
			},
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Consumers",
        content:<ul>
          <li>HNO</li>
          <li>OEP</li>
          <li>BOEP</li>
          <li>Sonic</li>
          <li>Everest (Active Hub)</li>
        </ul>
      },
			{
				id:"global-gateway-services",
				title:"Global Gateway Services",
				type:"apis",
				tile_height:"200px",
				sections:[
          {
            title:"Gateway Resources",
            src:"pearson_4.jpg",
            url:"GatewayResources-API_Swagger",
            content:"Gateway Resources are usually tied to a specific platform or a specific region which can be set as a reference. This allows a customer that is tied to a specific region or a specific platform that they are entitled to use, to have access to specific Gateway Resources."
          },
          {
            title:"Gateway Platforms",
            src:"pearson_1.jpg",
            url:"GatewayPlatform-API",
            content:"This service is also able to modify details of an existing Platform or create new Platforms. When a user is granted entitlements to a specific Platform, the Global Gateway will ensure that the user is able to click through to the Platform from the Gateway."
          },
          {
            title:"Landing Page",
            src:"pearson_3.jpg",
            url:"LandingPage-API_Swagger",
            content:"This service is utilized by the Default Landing Page SPA to configure details such as widgets, resources, entitlement labels. In addition, this service also allows the creation and modification of landing page widgets."
          },
          {
            title:"Gateway Entitlements",
            src:"pearson_2.jpg",
            url:"GatewayPlatformEntitlements-API_Swagger",
            content:"This service can grant or remove entitlements from a single user at a time and can also be used to retrieve information about the entitlements of a user or a platform."
          },
          {
            title:"Gateway Configuration",
            src:"pearson_5.jpg",
            url:"GatewayConfiguration-API_Swagger",
            content:"This allows each region to set specific configurations for each gateway.  Example which SPAs are available to the user, the landing page SPA, the footer links, and basic metadata that identified the Gateway for the specific Region. The Gateway Wrapper then loads this configuration for the Gateway and uses it to build the Gateway experience for that Region."
          }
				]
			},
      {
        id:"notification-services",
				title:"Notification Services",
				type:"apis",
				tile_height:"200px",
        sections:[
          {
            title:"Notification Decision",
            src:"pearson_8.jpg",
            url:"NotificationDecision-API_Swagger",
            content:"This service makes it possible to send emails to users based on events including: addition or removal of users, teachers, students, or other users. Furthermore, this service uses templates to create these emails."
          },
          {
            title:"Notification",
            src:"pearson_9.jpg",
            url:"Notification-API_Swagger",
            content:"Notification delivery types are predefined values: email, SMS, and InAppNotification. This service listens for user onboarded events and sends welcome mail to onboarded users and is also able to send notification to any user without an event trigger."
          },
          {
            title:"Template",
            src:"pearson_10.jpg",
            url:"Template-API_Swagger",
            content:"Templates can be drafted, removed, or finalized for a given productModelId to be able to be populated with data. In addition to template management, this service is able to populate data for a given finalized template to be used by other services. Templates have to be of html format to be successfully created."
          }
        ]
      },
			{
				id:"use_cases",
				type:"custom",
				title:"Use Cases",
        content:<ul>
          <li>A product model for example “ActiveHub” is available in UK Gateway</li>
          <li>Product identifiers like A103000274689, A103000274690 are configured to be available for product model “ActiveHub”</li>
          <li>Send email reminders to students for their upcoming assignments before the due dates</li>
          <li>Ability for a group of users to have a back and forth discussions on a topic related to a course for example a book club kind of an environment</li>
        </ul>
			},
      {
        id:"videos",
        type:"video",
        title:"Videos",
        videos:[{
          title:"ProductModel and Region Overview",
          file:"product_model_region_overview.mp4"
        },
        {
          title:"ActiveHub Specific Landing Page",
          file:"activehub-recording.mp4"
        },
        {
          title:"Gateway Mexico Landing Page",
          file:"gateway_mexico.mp4"
        }]
      }
		]}/>;
}

export default CustomerOverview;
