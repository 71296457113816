import React from 'react';
import OverviewPage from '../../Overview/OverviewPage';

function ContentOverview(props){
	return <OverviewPage
		url = {(doc) => {return "/content/api/Content/" + doc}}
		title = "Content Services"
		subtitle = "The Content Platform Service has numerous APIs designed to allow applications to find, create, manipulate, consume, and analyze content."
		service_owner = {{
			owner:"Allen Ross/Srinivas Karedla",
			user:"srinivas.karedla@pearson.com",
			href:"https://teams.microsoft.com/l/channel/19%3ad7fbd26653024d99a6ba0a2b46771d38%40thread.tacv2/Content?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
    nav_width = "580px"
		nav_links = {[
			{
				href:'#business',
				label:'Business Context',
			},
			{
				href:'#api_docs',
				label:'Content Services',
			},
			{
				href:'#use_cases',
				label:'Use Cases',
			},
			{
				href:'#videos',
				label:'Videos'
			}
		]}

		sections = {[
      {
        id:"business",
        type:"business_context",
        height:"275px",
        sections:[{
            title:"Content Ingestion",
            color:"#dba63f",
            text:<p>Any existing content that was created outside of the Content Platform, such as ePubs or PDFs is ingested into the Content Platform for use. In addition, third-party content that is controlled on a separate vendor's platform is cataloged within the Content Platform for use. This enables content that isn't controlled by the Content Platform to still be utilized within our Tools.</p>
          },
          {
            title:"Content Authoring/Production",
            color:"#dd77e4",
            text:<p>New content is authored or produced using Content Platform Tools. Images/Videos that are produced in-house or licensed from third-parties are also stored in the Content Platform at this stage.</p>
          },
          {
            title:"Content Enhancement",
            color:"#3982c6",
            text:<p>Once content has been authored or ingested, it can be enhanced. Typical types of enhancements that need to be made to content include aligning Learning Objectives to the content, attaching Rights/Clearance information, and assembling the content into a Course structure. The Content Platform provides Tools to enable these types of enhancements. All content can be enhanced within the Content Platform, regardless of whether it was authored/produced using Content Platform Tools or not.</p>
          },
          {
            title:"Content Distribution",
            color:"#dba63f",
            text:<p>The final stage in the Content Pipeline is distribution of the content to Learning Platforms. This involves optimizing and/or transforming content to match the expectations of the Target Learning Platform, physically delivering the content to a CDN for use by the Target Learning Platform, and providing the metadata around the content to the Learning Platform so that the content can be utilized appropriately.</p>
          }
        ],
        content:<>
          <p>The Content Service Platform is designed as a Pipeline that allows users to author new content, as well as ingest existing content. That content is stored in a standardized way that enables it to be further enhanced with metadata and have additional manipulations done to it, such as Assembly into Courses or Rights Clearance. Once all the manipulations to the content are done, the content is transformed into the format required by the downstream Learning Platform and distributed to them.</p>
          <p>In general, there are four stages to the Content Pipeline:</p>
        </>
      },
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Consumers",
        content:<ul>
          <li>OEP</li>
          <li>HNO</li>
          <li>Everest (Active Hub)</li>
          <li>CourseInstance</li>
          <li>TOC Transformation Service</li>
          <li>Resource Service</li>
        </ul>
      },
			{
        id:"api_docs",
				type:"apis",
				tile_height:"500px",
				title:"Content Services",
				sections:[{
					title:"Manifest",
					src:"pearson_2.jpg",
					url:"ContentAPI_Swagger",
					content:<>
            <p>The Manifest API Microservice provides an interface for callers to request manifest information for a product/title. This API gives information about the structure of the product and where the content items (narrative, assessments, media, etc. ) fit within it.</p>
            <p>There are three main types of capabilities provided by the Manifest API:</p>
            <ol>
              <li>manifest-level: Information can be obtained about a product from the very top-level of the product.</li>
              <li>container-level: Information can be obtained about a product starting from a specific container.</li>
              <li>iterators: The API provides ways to walk through the entire product hierarchy in a controlled fashion.</li>
            </ol>
          </>
				},
        {
					title:"Assessment Consumption",
					src:"pearson_3.jpg",
					url:"acapi-api-docs",
					content:"The Assessment Consumption API is a Consumption API that allows for assessment and assessment items to be consumed by clients. It enables clients to retrieve both metadata about the assessments/items as well as the actual content of the items."
				},
        {
					title:"Learning Product",
					src:"pearson_5.jpg",
					url:"product-api-app-docs",
					content:<>
            <p>The Learning Product API is a consumption API that enables clients to retrieve the Learning objectives that are aligned to a product or a piece of content within the product.</p>
            <p>Each Learning Framework contains a hierarchy of Learning Objectives. These Learning Objectives can then be aligned to specific pieces of content or structure. The LO API gives clients the ability to view and create learning frameworks, the learning objectives contained within those frameworks, and align them to content.</p>
          </>
				}],
				content:<>
          <p>The Content Pipeline is powered by a set of Content service APIs that provide a consistent way to represent content. All the Tools within the Content Platform utilize these Content APIs, and all the stages of the Content Pipeline interact with these APIs. They are implemented as a set of microservices that communicate through an event-driven architecture.</p>
          <p>The Content Platform has numerous APIs designed to allow applications to find, create, manipulate, consume, and analyze content.</p>
          <p>There are three categories of APIs in the Content Platform:</p>
          <ul>
            <li>Transaction APIs - these APIs are designed to allow applications to create and manipulate content and its metadata</li>
            <li>Consumption APIs - these APIs are designed to allow applications to consume content and its metadata</li>
            <li>UI-Specific APIs - these APIs are specific to a user interface, and not intended for use by any other clients</li>
          </ul>
          <p>The following Consumption APIs are used for data consumption:</p>
				</>
			},
      {
        id:"business",
        type:"business_context",
        height:"275px",
        title:"Use Cases",
        sections:[{
            title:"Content Creation",
            color:"#dba63f",
            text:<p>Content authors can create and update static, interactive, multimedia, and assessment content</p>
          },
          {
            title:"Content Alignment",
            color:"#dd77e4",
            text:<p>Learning designers can define learning frameworks based on curricula, state standards, progress descriptors, skill levels, etc. Content can then be aligned to objectives within the framework.</p>
          },
          {
            title:"Project Assembly",
            color:"#3982c6",
            text:<p>Authors can assemble projects, adding structure and context to the collection of content that comprises a title.</p>
          },
          {
            title:"Content Distribution",
            color:"#dba63f",
            text:<p>The final stage in the Content Pipeline is distribution of the content to Learning Platforms. This involves optimizing and/or transforming content to match the expectations of the Target Learning Platform, physically delivering the content to a CDN for use by the Target Learning Platform, and providing the metadata around the content to the Learning Platform so that the content can be utilized appropriately.</p>
          },
          {
            title:"Project Publishing",
            color:"#dba63f",
            text:<p>Product owners can publish their content to end platforms across the globe, covering a variety of delivery formats including web, mobile, and virtual assistants.</p>
          },
          {
            title:"Product Delivery",
            color:"#dd77e4",
            text:<p>Users can rely on quick and secure delivery of Pearson learning content to their preferred LMS or learning application.</p>
          },
        ]
      },
      {
        id:"videos",
        type:"video",
        title:"Videos",
        videos:[{
          title:"Cypress content creation and publication",
          file:"Cypress_content_creation.mp4"
        }]
      }
		]}
	/>
}

export default ContentOverview;
