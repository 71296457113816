import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import IdentityOnboarding from './IdentityOnboarding';

export function SideBar(props) {
  return <>
      <SubMenu name="Consent Policy" open={props.handle_open('IES_Consent_Policy_Swagger')}>
        <Item folder='User Management' doc='IES_Consent_Policy_Swagger' root={props.root} scroll_to='operations-default-post_consentpolicies' name='Create Consent Policy'/>
        <Item folder='User Management' doc='IES_Consent_Policy_Swagger' root={props.root} scroll_to='operations-default-get_consentpolicies__CpId_' name='Get Consent Policy'/>
        <Item folder='User Management' doc='IES_Consent_Policy_Swagger' root={props.root} scroll_to='operations-default-put_consentpolicies__CpId_' name='Update Consent Policy'/>
        <Item folder='User Management' doc='IES_Consent_Policy_Swagger' root={props.root} scroll_to='operations-default-delete_consentpolicies__CpId_' name='Delete Consent Policy'/>
        <Item folder='User Management' doc='IES_Consent_Policy_Swagger' root={props.root} scroll_to='operations-default-get_consentpolicies_client__clientID_' name='Get Consent Policy by ClientID'/>
      </SubMenu>
      <SubMenu name="Credential" open={props.handle_open('IES_Credential_Swagger')}>
      <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-get_credentials__UniqueIdentifier_' name='Get Credential Unique Identifier'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-get_credentials' name='Get Credential'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-post_credentials' name='Create Credential'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-put_credentials__credentialId_' name='Update Credential'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-delete_credentials__credentialId_' name='Delete Credential'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-post_credentials_identity' name='Validate Credential'/>
        <Item folder='User Management' doc='IES_Credential_Swagger' root={props.root} scroll_to='operations-default-get_credentials_checkUsernameAvailability' name='Check Username Availability'/>
      </SubMenu>
      <SubMenu name="Identity Profile Email" open={props.handle_open('IES_IdentityProfile_Email_Swagger')}>
        <Item folder='User Management' doc='IES_IdentityProfile_Email_Swagger' root={props.root} scroll_to='operations-default-post_identityprofiles__IdentityId__emails' name='Create Identity Profile'/>
        <Item folder='User Management' doc='IES_IdentityProfile_Email_Swagger' root={props.root} scroll_to='operations-default-get_identityprofiles__IdentityId__emails__EmailId_' name='Get Identity Profile'/>
        <Item folder='User Management' doc='IES_IdentityProfile_Email_Swagger' root={props.root} scroll_to='operations-default-put_identityprofiles__IdentityId__emails__EmailId_' name='Update Identity Profile'/>
        <Item folder='User Management' doc='IES_IdentityProfile_Email_Swagger' root={props.root} scroll_to='operations-default-delete_identityprofiles__IdentityId__emails__EmailId_' name='Delete Identity Profile'/>
      </SubMenu>
      <SubMenu name="Identity Profile" open={props.handle_open('IES_Identity_Profile_Swagger')}>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-get_identityprofiles_email' name='Get Identity Profile by Email'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-put_identityprofiles_updatesms__id_' name='Update Sms'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-get_identityprofiles_phone' name='Get Identity Profile by Phone Number'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-get_identityprofiles__id_' name='Get Identity Profile by ID'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-put_identityprofiles__id_' name='Update Identity Resource'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-post_identityprofiles__id_' name='Create Identity-Profile Resource'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-delete_identityprofiles__id_' name='Delete Identity Profile'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-get_identityprofiles_name' name='Get Identity Profile by name'/>
        <Item folder='User Management' doc='IES_Identity_Profile_Swagger' root={props.root} scroll_to='operations-default-post_identityprofiles_batch' name='Get Identity Profiles by PI Ids'/>
      </SubMenu>
      <SubMenu name="Identity" open={props.handle_open('IES_Identity_Swagger')}>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-get_identities__identityId_' name='Get Identity by ID'/>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-put_identities__identityId_' name='Update Identity'/>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-delete_identities__identityId_' name='Delete Identity'/>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-post_identities' name='Create Identity'/>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-get_identities_getcountries' name='Get Countries'/>
        <Item folder='User Management' doc='IES_Identity_Swagger' root={props.root} scroll_to='operations-default-get_identities_lookup_context__context__contextid__contextid_' name='Get by Context and ContextId'/>
      </SubMenu>
      <SubMenu name="User Composite" open={props.handle_open('IES_User_Composite_Swagger')}>
        <Item folder='User Management' doc='IES_User_Composite_Swagger' root={props.root} scroll_to='operations-default-post_usercomposite' name='Create User Composite'/>
        <Item folder='User Management' doc='IES_User_Composite_Swagger' root={props.root} scroll_to='operations-default-get_usercomposite__IdentityId_' name='Get User Composite'/>
        <Item folder='User Management' doc='IES_User_Composite_Swagger' root={props.root} scroll_to='operations-default-get_usercomposite_context_SMS_contextid__SMSID_' name='Get User Composite by Context'/>
      </SubMenu>
      <SubMenu name="Entitlements" open={props.handle_open('entitlements')}>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-bulkAddUsingPOST' name='Create Bulk Entitlements'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementsUsingGET' name='Get Entitlements'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-addUsingPOST' name='Create Entitlements'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-checkSingleProductEntitlementUsingPOST' name='Check Entitlements for a Product and User ID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-updateEntitlementsByLicenseIdUsingPUT' name='Update Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-activateEntitlementsByLicenseIdUsingPOST' name='Activate Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-cancelEntitlementsByLicenseIdUsingPOST' name='Cancel Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-deactivateEntitlementsByLicenseIdUsingPOST' name='Deactivate Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-expireEntitlementsByLicenseIdUsingPOST' name='Expire Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-reinstateEntitlementsByLicenseIdUsingPOST' name='Reinstate Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-renewEntitlementsByLicenseIdUsingPOST' name='Renew Entitlements by LicenseID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementByProductIdAndUserListUsingPOST' name='Get Entitlements for a Product ID and List of User IDs'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-activateEntitlementsBySubscriptionIdUsingPOST' name='Activate Entitlements by SubscriptionID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-cancelEntitlementsBySubscriptionIdUsingPOST' name='Cancel eEntitlements by SubscriptionID '/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-deactivateEntitlementsBySubscriptionIdUsingPOST' name='Deactivate Entitlements by SubscriptionID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-expireEntitlementsBySubscriptionIdUsingPOST' name='Expire Entitlements by SubscriptionID '/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-reinstateEntitlementsBySubscriptionIdUsingPOST' name='Reinstate Entitlements by SubscriptionID '/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-renewEntitlementsBySubscriptionIdUsingPOST' name='Renew Entitlements by SubscriptionID '/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementByIdUsingGET' name='Get Entitlement by ID'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-updateUsingPUT' name='Update Entitlement'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-deleteUsingDELETE' name='Delete Entitlement'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-cancelEntitlementUsingPOST' name='Cancel Entitlement'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-reinstateEntitlementUsingPOST' name='Reinstate Entitlement'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-checkEntitlementForProductUsingPOST' name='Check Entitlements for a ProductID and a list of UserIDs'/>
        <Item folder='User Management' doc='entitlements' root={props.root} scroll_to='operations-entitlement-controller-checkEntitlementForUserUsingPOST' name='Check Entitlements for a UserID and a list of ProductIDs'/>
      </SubMenu>
      <SubMenu name="License Manager" open={props.handle_open('license_manager')}>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-searchUsingGET' name='Search Operation'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-createUsingPOST' name='Create License'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-processOrderUsingPOST' name='Process Order'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-updateOrderUsingPUT' name='Update Order'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-renewOrderUsingPOST' name='Renew License by ID'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-updateLicenseStatusBatchUsingPUT' name='Update all License Statuses Based on Date'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-getLicenseByIdUsingGET' name='Get License by ID'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-updateUsingPUT' name='Update License'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-deleteUsingDELETE' name='Delete License'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-cancelOrderUsingPOST' name='Cancel License by ID'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-retrieveEntitlementsUsingPOST' name='Retrieve Entitlements'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-redeemUsingPOST' name='Redeem'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-renewOrderUsingPOST_1' name='Renew License by ID'/>
        <Item folder='User Management' doc='license_manager' root={props.root} scroll_to='operations-license-controller-unredeemUsingPOST' name='Unredeem'/>
      </SubMenu>
  </>;
}

export function docs(){
  return [
    'IES_Consent_Policy_Swagger',
    'IES_Credential_Swagger',
    'IES_IdentityProfile_Email_Swagger',
    'IES_Identity_Profile_Swagger',
    'IES_Identity_Swagger',
    'IES_User_Composite_Swagger',
    'entitlements',
    'license_manager'
  ];
}

class IdentityApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      hide_header
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/identity' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Identity and Enablement'
      overview_route='/identity'
      onboarding_title='Onboarding'
      onboarding_route='/identity/onboarding'
      onboarding_component={<IdentityOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default IdentityApi;
