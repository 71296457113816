import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import DataOnboarding from './DataOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Address Validation" open={props.handle_open('AddressDoctor_Swagger')}>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-addressValidationUsingPOST' name='Validate All Address Lines'/>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-autofilValidationUsingPOST' name='Autofill City and State'/>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-typeValidationUsingPOST' name='Type Ahead Address Search'/>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-partialValidationUsingPOST' name='Partial Address Lookup'/>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-getAuthTokenUsingGET' name='Auth Token for Dev region'/>
      <Item folder='Enterprise Data' doc='AddressDoctor_Swagger' root={props.root} scroll_to='operations-address-doctor-controller-healthCheckUsingGET' name='Health Check'/>
    </SubMenu>
    <SubMenu name="Products API" open={props.handle_open('product_api_swagger')}>
      <Item folder='Enterprise Data' doc='product_api_swagger' root={props.root} scroll_to='operations-product-controller-getUsingGET' name='Get Product'/>
    </SubMenu>
    <SubMenu name="Customer API" open={props.handle_open('Customer-API_Swagger')}>
      <Item folder='Enterprise Data' doc='Customer-API_Swagger' root={props.root} scroll_to='operations-V1-get_uid' name='Get Customer by Uid'/>
    </SubMenu>
    <SubMenu name="Digital Account Verification API" open={props.handle_open('DigitalAccount_API_Swagger')}>
      <Item folder='Enterprise Data' doc='DigitalAccount_API_Swagger' root={props.root} scroll_to='operations-Account_Number_and_Platform_ID-post_v1_digitalaccountverification' name='Get Access Using Account Number and PlatformID'/>
      <Item folder='Enterprise Data' doc='DigitalAccount_API_Swagger' root={props.root} scroll_to='operations-TEP_Account_and_TEP_Party_Number-post_v2_digitalaccountverification' name='Get Access Using TEP Account and TEP Party Number'/>
    </SubMenu>
    <SubMenu name="Customer Lookup API" open={props.handle_open('CustomerLookup_API_Swagger')}>
      <Item folder='Enterprise Data' doc='CustomerLookup_API_Swagger' root={props.root} scroll_to='operations-TEP_Account_Number-post_tepaccountnumber' name='Get Customer by TEP Account'/>
      <Item folder='Enterprise Data' doc='CustomerLookup_API_Swagger' root={props.root} scroll_to='operations-Org_and_Postal_Code-post_orgnameandpostalcode' name='Get Customer by Org and Postal'/>
    </SubMenu>
    <SubMenu name="Eligibility Check API" open={props.handle_open('EligibilityCheck_API_Swagger')}>
      <Item folder='Enterprise Data' doc='EligibilityCheck_API_Swagger' root={props.root} scroll_to='operations-Eligibility_Check-post_digitalaccountverification' name='Check Eligibility'/>
    </SubMenu>
    <SubMenu name="User Profile API" open={props.handle_open('eds_user_profile_api_swagger')}>
      <Item folder='Enterprise Data' doc='eds_user_profile_api_swagger' root={props.root} scroll_to='operations-User_profile_service-generateMarketingReportUsingGET' name='Create Marketing Report'/>
      <Item folder='Enterprise Data' doc='eds_user_profile_api_swagger' root={props.root} scroll_to='operations-User_profile_service-getUserDataUsingGET' name='Get User Data'/>
      <Item folder='Enterprise Data' doc='eds_user_profile_api_swagger' root={props.root} scroll_to='operations-User_profile_service-authServicehealthUsingGET' name='Auth Service Health'/>
      <Item folder='Enterprise Data' doc='eds_user_profile_api_swagger' root={props.root} scroll_to='operations-User_profile_service-mergeUserUsingPOST' name='Merge User'/>
      <Item folder='Enterprise Data' doc='eds_user_profile_api_swagger' root={props.root} scroll_to='operations-User_profile_service-createAccountRegistrationUsingPOST' name='Create Account Registery'/>
    </SubMenu>
  </>;
}

export function docs() {
  return [
    'AddressDoctor_Swagger',
    'product_api_swagger',
    'Customer-API_Swagger',
    'DigitalAccount_API_Swagger',
    'CustomerLookup_API_Swagger',
    'EligibilityCheck_API_Swagger',
    'eds_user_profile_api_swagger'
  ];
}

class DataApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder, doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs"><SideBar root='/data' handle_open={handle_open}/></SubMenu>}
      overview_title='Enterprise Data'
      overview_route='/data'
      onboarding_title='Onboarding'
      onboarding_route='/data/onboarding'
      onboarding_component={<DataOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default DataApi;
