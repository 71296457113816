import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';

class NavHamMenu extends React.Component {
  constructor(props){
    super(props)
    this.state = {open:false};
  }

  handleClick() {
      this.setState({
          open: !this.state.open
      });
  }

  render(){
    let menu = "";
    if(this.state.open){
      menu = <span>{this.props.children}</span>;
    }
    return(<span className="nav-ham-wrapper">
      <span className="nav-ham-icon">
        <HamburgerMenu
          isOpen={this.state.open}
          menuClicked={this.handleClick.bind(this)}
          width={28}
          height={25}
          strokeWidth={3}
          rotate={0}
          color='#007FA3'
          borderRadius={0}
          animationDuration={0.5}
        />
      </span>
      {menu}
    </span>);
  }
}

export default NavHamMenu;
