import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import UserManagementOnboarding from './UserManagementOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Organization" open={props.handle_open('Organization-API Swagger')}>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getEventDeatilsBypmrIdUsingPOST' name='Get Event Details'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getOrganizationByOrganizationIdUsingGET' name='Get Organization By Organization Id'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-editOrganizationByOrganizationIdUsingPUT' name='Edit Organization By Organization Id'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-deleteOrganizationByOrganizationIdUsingDELETE' name='Delete Organization By Organization Id'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-associateExternalIdWithOrgUsingPUT' name='Associate External Id With Org'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-enableOrganizationByProductModelRegionIdUsingPOST' name='Enable Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-disableOrganizationByProductModelRegionIdUsingDELETE' name='Disable Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-addUserInOrganizationByProductModelRegionIdUsingPOST' name='Add User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-disableUserInOrganizationByProductModelRegionIdUsingDELETE' name='Disable User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-checkPermissionForUserInOrganizationByProductModelRegionIdUsingGET' name='Check Permission For User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-addPermissionGroupForUserInOrganizationByProductModelRegionIdUsingPOST' name='Add Permission Group For User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-removePermissionGroupForUserInOrganizationByProductModelRegionIdUsingDELETE' name='Remove Permission Group For User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getPermissionsForUserInOrganizationByProductModelRegionIdUsingGET' name='Get Permission For User In Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getUsersOfOrganizationByProductModelRegionIdUsingGET' name='Get Users Of Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getEventDeatilsUsingPOST' name='Get Event Details'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getAllOrganizationsUsingGET' name='Get All Organizations'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-createOrganizationUsingPOST' name='Create Organization'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getOrganizationsByNameUsingGET' name='Get Organizations By Name'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getOrganizationsByProductModelRegionUsingGET' name='Get Organizations by Product Model Region'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getOrganizationsByUserUsingGET' name='Get Organizations By User'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getUserOnOrganizationAndProductModelRegionUsingGET' name='Get User On Organization And Product Model Region'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getOrganizationOnUserPermissionUsingGET' name='Get Organization On User Permission'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-controller-getPMRegionsByUserUsingGET' name='Get Product Model Regions By User'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-createOrganizationGroupUsingPOST' name='Create Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-getNameOrDescriptionOfOrganizationGroupUsingGET' name='Get Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-updateNameOrOrganizationOfOrganizationGroupUsingPUT' name='Update Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-deleteOrganizationGroupUsingDELETE' name='Delete Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-addOrganizationToOrganizationGroupUsingPOST' name='Add Organization To Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-deleteOrganizationFromGroupUsingDELETE' name='Delete Organization From Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-getAllOrganizationsInGroupUsingGET' name='Get All Organizations In Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-addOrganizationGroupToRegionUsingPOST' name='Add Organization Group To Region'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-deleteOrganizationGroupFromRegionUsingDELETE' name='Delete Organization Group From Region'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-getAllRegionsWhichHasOrganizationGroupUsingGET' name='Get All Regions Which Has Organization Group'/>
      <Item folder='User Management' doc='Organization-API Swagger' root={props.root} scroll_to='operations-organization-groups-controller-getAllOrganizationGroupsUsingGET' name='Get All Organization Groups'/>
    </SubMenu>
    <SubMenu name="Permission Check" open={props.handle_open('PermissionCheck-API Swagger')}>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-1-checkUserHasPermissionForProductModelRegionUsingGET' name="V1 - Check User's Product Model Region Permissions"/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-1-checkPermissionsUsingPOST' name='V1 - Check Permissions'/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-2-permissionCheckForUserUsingPOST' name='V2 - Check Permissions'/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-2-checkUserPermissionForProductModelUsingGET' name="V2 - Check User's Product Model Region Permissions"/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-2-checkUserPermissionForRegionUsingGET' name="V2 - Check User's Region Permissions"/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-check-controller-v-2-ListOfPermissionsForUserUsingPOST' name='V2 - Get All User Permissions'/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-controller-getUserPermissionsWithUserIdAndRegionIdWithoutPMregionUsingGET' name='Get User Permissions For Region'/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-controller-getUserPermissionsWithoutProductModelRegionUsingGET' name='Get User Permissions'/>
      <Item folder='User Management' doc='PermissionCheck-API Swagger' root={props.root} scroll_to='operations-permission-controller-checkUserHasPermissionForProductModelRegionUsingGET_1' name='Check User Permissions For Product Model Region'/>
    </SubMenu>
    <SubMenu name="Permission System" open={props.handle_open('PermissionSystem-API Swagger')}>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-getPermissionByIdUsingGET' name='Get Permission By Id'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-createPermissionUsingPOST' name='Create Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-updatePermissionUsingPUT' name='Update Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-deletePermissionUsingDELETE' name='Delete Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-getAllPermissionsUsingGET' name='Get All Permissions'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-getPermissionsByDescriptionUsingGET' name='Get Permissions By Description'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-controller-getPermissionsByNameUsingGET' name='Get Permissions By Name'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-getPermissionGroupByIdUsingGET' name='Get Permission Group By Id'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-updatePermissionGroupUsingPUT' name='Update Permission Group'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-deletePermissionGroupByIdUsingDELETE' name='Delete Permission Group By Id'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-getPermissionGroupsUsingGET' name='Get Permission Groups'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-createPermissionGroupUsingPOST' name='Create Permission Group'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-getPermissionGroupsByProductModelIdUsingGET' name='Get Permission Groups By Product Model Id'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-getPermissionGroupsWithoutProductModelIdUsingGET' name='Get Permission Groups'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-addPermissionToPermissionGroupUsingPOST' name='Add Permission To Permission Group'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-permission-groups-controller-deletePermissionFromPermissionGroupUsingDELETE' name='Delete Permission From Permission Group'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-system-permission-controller-getSystemPermissionUsingGET' name='Get System Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-system-permission-controller-createSystemPermissionUsingPOST' name='Create System Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-system-permission-controller-deleteSystemPermissionUsingDELETE' name='Delete System Permission'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-getUserPermissionsByUserUsingGET' name='Get User Permissions By User'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-deleteUserPermissionsByUserUsingDELETE' name='Delete User Permissions By User'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-getUserPermissionsByUserAndRegionUsingGET' name='Get User Permissions By User And Region'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-removeUserPermissionsByUserAndRegionUsingDELETE' name='Remove User Permissions By User and Region'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-addPermissionGroupToUserInGivenRegionUsingPOST' name='Add Permission Group To User'/>
      <Item folder='User Management' doc='PermissionSystem-API Swagger' root={props.root} scroll_to='operations-user-permission-controller-removePermissionGroupToUserInGivenRegionUsingDELETE' name='Remove Permission Group From User'/>
    </SubMenu>
    <SubMenu name="Product Model" open={props.handle_open('ProductModel-API Swagger')}>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelsByProductModelIdAndLocaleUsingGET' name='Get Product Model'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-updateProductModelsByProductModelIdUsingPUT' name='Update Product Model'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-deleteProductModelUsingDELETE' name='Delete Product Model'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelConfigurationUsingGET' name='Get Product Model Configuration'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-updateProductModelConfigurationUsingPUT' name='Update Product Model Configuration'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelsByProductModelIdAndLocaleUsingGET_1' name='Get Product Model By Locale'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-updateProductModelsByProductModelIdUsingPUT_1' name='Update Product Model By Locale'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelsUsingGET' name='Get All Product Models'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-createNewProductModelUsingPOST' name='Create Product Model'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelsUsingGET_1' name='Get All Product Models In Locale'/>
      <Item folder='User Management' doc='ProductModel-API Swagger' root={props.root} scroll_to='operations-product-model-controller-v-2-getProductModelConfigurationUsingGET_1' name='Get Product Model Configuration By Type'/>
    </SubMenu>
    <SubMenu name="Product Model Region" open={props.handle_open('ProductModelRegion-API Swagger')}>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelRegionIdUsingGET' name='Get Product Model Region'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-deleteProductModelRegionIdUsingDELETE' name='Delete Product Model Region'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelRegionsUsingGET' name='Get All Product Model Regions'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-createNewProductModelRegionUsingPOST' name='Create New Product Model Region'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelRegionsbyModelIdUsingGET' name='Get Product Model Regions'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getProductModelRegionsByRegionIdUsingGET' name='Get Product Model Regions By Region'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-controller-getByListPMIdUsingPOST' name='Get Product Models By Product Model Region'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-retention-controller-getRetentionPolicyUsingGET' name='Get Retention Policy'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-retention-controller-saveRetentionPolicyUsingPOST' name='Save Retention Policy'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-product-model-retention-controller-updateRetentionPolicyUsingPUT' name='Update Retention Policy'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-region-controller-getByRegionIDUsingGET' name='Get Region Information'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-region-controller-getByRegionIdAndLocaleUsingGET' name='Get Region Information by Locale'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-region-controller-getUSRegionsUsingGET' name='Get All Regions'/>
      <Item folder='User Management' doc='ProductModelRegion-API Swagger' root={props.root} scroll_to='operations-region-controller-getByGivenLocaleUsingGET' name='Get All Regions In Locale'/>
    </SubMenu>
    <SubMenu name="URL Permission Context" open={props.handle_open('URLPermissionContext-API Swagger')}>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-retrieveProxyConfigurationUsingGET' name='Get Proxy Configuration'/>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-createProxyConfigurationUsingPOST' name='Create Proxy Configuration'/>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-retrieveProxyConfigurationByIdUsingGET' name='Get Proxy Configuration By Id'/>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-updateProxyConfigurationByIdUsingPUT' name='Update Proxy Configuration By Id'/>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-deleteProxyConfigurationByIdUsingDELETE' name='Delete Proxy Configuration By Id'/>
      <Item folder='User Management' doc='URLPermissionContext-API Swagger' root={props.root} scroll_to='operations-url-permission-context-controller-retrieveAllProxyConfigurationsUsingGET' name='Get All Proxy Configurations'/>
    </SubMenu>
    <SubMenu name="User Search" open={props.handle_open('UserSearch-API Swagger')}>
      <Item folder='User Management' doc='UserSearch-API Swagger' root={props.root} scroll_to='operations-User_Search_Service_Endpoints-createTemplateUsingPOST' name='Query Users'/>
    </SubMenu>
  </>;
}

export function docs(){
  return [
    'Organization-API Swagger',
    'PermissionCheck-API Swagger',
    'PermissionSystem-API Swagger',
    'ProductModel-API Swagger',
    'ProductModelRegion-API Swagger',
    'URLPermissionContext-API Swagger',
    'UserSearch-API Swagger'
  ];
}

class UserManagementApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      hide_header
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/user_management' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='User Management'
      overview_route='/user_management'
      onboarding_title='Onboarding'
      onboarding_route='/user_management/onboarding'
      onboarding_component={<UserManagementOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default UserManagementApi;
