import React from 'react';
import { useCookies } from 'react-cookie';
import {Typography,Container} from '@material-ui/core';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { get_api_host,get_id_token } from '../../../Helper/HostInfo';
import NavLink from '../../../Header/Navigation/NavLink';

export default function Search(props) {
  const [cookies] = get_id_token();
  
  const loading_icon = <HourglassEmptyIcon style={{color:'grey'}}/>;

  const [data, setData] = React.useState({
    hits:[],
    state:"loading",
  });

  const [search, setSearch] = React.useState(props.location.search);

  const keys_and_names = {
    'Collaborations and Communications/Group-API_Swagger.yml':'Group',
    'Collaborations and Communications/VideoConference-API_Swagger.yml':'Video Conference',
    'Course Management/Course-API_Swagger.yml':'Course',
    'Course Management/CourseInstance-API_Swagger.yml':'Course Instance',
    'Course Management/CourseInstanceConsumption-API_Swagger.yml':'Course Instance Consumption',
    'Course Management/CourseInstanceTOC-API_Swagger.yml':'Course Instance TOC',
    'Course Management/CourseInstanceUserAssignment-API_Swagger.yml':'Course Instance User Assignment',
    'Course Management/Roster-API_Swagger.yml':'Roster',
    'Course Management/UserAssignmentConsumption-API_Swagger.yml':'User Assignment Consumption',
    'Customer Experience/GatewayPlatform-API.yml':'Gateway Platform',
    'Customer Experience/GatewayPlatformEntitlements-API_Swagger.yml':'Gateway Platform Entitlement',
    'Customer Experience/GatewayConfiguration-API_Swagger.yml':'Gateway Configuration',
    'Customer Experience/GatewayResources-API_Swagger.yml':'Gateway Resources',
    'Customer Experience/LandingPage-API_Swagger.yml':'Landing Page',
    'Customer Experience/Notification-API_Swagger.yml':'Notification',
    'Customer Experience/NotificationDecision-API_Swagger.yml':'Notification Decision',
    'Customer Experience/Template-API_Swagger.yml':'Template',
    'Enterprise Data/Products_API_Swagger.yml':'Products',
    'Enterprise Data/Customer-API_Swagger.yml':'Customer',
    'Enterprise Data/DigitalAccount_API_Swagger.yml':'Digital Account',
    'Enterprise Data/CustomerLookup_API_Swagger.yml':'Customer Lookup',
    'Enterprise Data/EligibilityCheck_API_Swagger.yml':'Eligibility Check',
    'eCommerce/eCommerce-API.yml':'Ecommerce',
    'eCommerce/AddressDoctor_Swagger.yml':'Address Doctor',
    'Product Management/Entitlement-API_Swagger.yml':'Entitlement',
    'Product Management/EntitlementAccessCodes-API_Swagger.yml':'Entitlement Access Codes',
    'Product Management/Resource-API_Swagger.yml':'Resource',
    'Product Management/SKU-API_Swagger.yml':'SKU',
    'Product Management/Subscription-API_Swagger.yml':'Subscription',
    'User Management/IES_Consent_Policy_Swagger.yml':'Consent Policy',
    'User Management/IES_Credential_Swagger.yml':'Credential',
    'User Management/IES_IdentityProfile_Email_Swagger.yml':'Identity Profile Email',
    'User Management/IES_Identity_Profile_Swagger.yml':'Identity Profile',
    'User Management/IES_Identity_Swagger.yml':'Identity',
    'User Management/IES_User_Composite_Swagger.yml':'User Composite'
  };

  const [run, setRun] = React.useState(false);

  React.useEffect(() => {
    setSearch(props.location.search);
  },[props.location.search]);

  React.useEffect(() => {
    setRun(true);
  },[search]);

  React.useEffect(() => {
    const query = () => {
      const regex = /^\?q=([a-zA-Z0-9%_]*)/i;
      const matches = search.match(regex);
      return matches[1];
    }

    const axios = require('axios');
    const run_query = async () => {
      if(run){
        setRun(false);
        setData({
          hits:[],
          state:"loading",
        });
        const value = query();
        await axios({
          method: 'post',
          url: get_api_host() + '/search',
          data: {
            search: value
          },
          headers: {
            'Content-Type': 'application/json',
            'myCloudProxySession': get_id_token()
          }
        }).then((response) => {
          setData({
            hits:response.data.hits.hit,
            state:"complete"
          });
        }).catch((err) => {
          setData({
            hits:[],
            state:"error",
            e:err
          });
        })
      }
    }
    run_query();
  }, [run,keys_and_names,search,get_id_token()]);

  const render = () => {
    const hit_name = (hit) => hit.fields.folder + "/" + hit.id;
    if(data.state === "loading"){
      return <div style={{margin:"auto",width:"50px",paddingTop:"100px"}}>
        {loading_icon}
      </div>;
    } else if(data.state === "complete") {
      if(data.hits.length > 0){
        return <div style={{paddingTop:"50px", color:"#333"}}>
          <Container maxWidth={false}>
            <Typography variant="body2">Search Results...</Typography><br/>
            {
              data.hits
              .filter((hit) => hit_name(hit) in keys_and_names)
              .map((hit) => <div style={{paddingBottom:"20px",maxWidth:"600px"}}>
                <NavLink
                  to={"/develop/api/" + hit_name(hit).slice(0,-4)}
                >
                  <Typography variant="h6">{keys_and_names[hit_name(hit)]}</Typography>
                </NavLink>
                <Typography variant="body2">{hit.fields.description}</Typography>
              </div>)
            }
          </Container>
        </div>;
      } else {
        return <div style={{margin:"auto",width:"350px",color:"#cc3300",paddingTop:"100px"}}>
          <Typography variant="h4">No Results Found</Typography>
        </div>
      }
    } else if(data.state === "error") {
      return <div style={{margin:"auto",maxWidth:"350px",color:"#cc3300",paddingTop:"100px"}}>
        <Typography variant="h4">No Results Found</Typography>
      </div>
    }
  };

  return(render());

}
