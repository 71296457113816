import React from 'react';
import {Typography} from '@material-ui/core';

function CustomerOnboarding(props) {
  return(<div className="section">
  <div className="markdown-wrapper">
  <Typography style={{color:"#007fa3",paddingTop:"20px",paddingBottom:"20px"}} variant="h3" align="left">Onboarding</Typography>
  <ol>
  <li>Get IES Token for IES User.</li>
  <li>Build service request with the required parameters:</li>
  <li>&#60; methodType&#62; : method of the request.</li>
  <li>&#60; baseUrl&#62; : environment url of service</li>
  <li>&#60; serviceEndpoint&#62; : endpoint to be reached in service</li>
  <li>&#60; UserId&#62; : IES User ID</li>
  <li>&#60; Location&#62; : Location</li>
  <li>&#60; IESToken&#62; : Authorization token from IES</li>
  <li>&#60; JSONBody&#62; : JSON document containing information required for service request</li>
  </ol>
  <Typography style={{color:"#003057",paddingTop:"20px",paddingBottom:"20px"}} variant="h5" align="left">Testing Authentication</Typography>
  <div style={{position:"relative",left:"20px"}}>
  <p>Example:</p>
  <pre><code class="bash">curl --location --request &lt;methodType&gt; '&lt;baseUrlForService&gt;&lt;serviceEndpoint&gt;' <br/>
  --header 'x-prsn-user-id: &lt;UserID&gt;' <br/>
  --header 'X-PrefLocation: &lt;Location&gt;'(if needed) <br/>
  --header 'Authorization: &lt;IESToken&gt;' (REQUIRED) <br/>
  --header 'Content-Type: application/json' <br/>
  --data-raw '&lt;JSONBody&gt;(if needed)'
  </code></pre>

  <blockquote>
  <p>If the Pearson User has sufficient permissions, the request will go through. Otherwise, the service will return a "403 Forbidden" status code and the request will not be fulfilled.</p>
  </blockquote>
  </div>
  </div>
  </div>
  );
}

export default CustomerOnboarding;
