import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import DevPortal from './components/DevPortal';
import * as serviceWorker from './serviceWorker';
import {MsalProvider} from "@azure/msal-react";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
  <React.StrictMode>
    <DevPortal/>
  </React.StrictMode>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
