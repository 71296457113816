import React, { useState } from "react";
import { Typography, Button, Modal, TextField } from "@material-ui/core";
import { get_api_host,get_id_token } from "../../Helper/HostInfo";
import { useCookies } from "react-cookie";
// import { get_host_env } from "../../Helper/HostInfo";

function RequestEnhancement(props) {
  const [cookies] = get_id_token();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    summary: false,
    description: false,
    submit: false,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const error_msg = () => {
    if (errors.summary || errors.description) {
      return "Summary and Description are required";
    } else if (errors.submit) {
      return "Unable to submit request. Try again later.";
    } else {
      return "";
    }
  };

  const submit_label = () => {
    if (loading) {
      return "Creating Ticket...";
    } else {
      return "Submit";
    }
  };

  const validate = async () => {
    const summary = document.getElementById("rf_summary").value;
    const description = document.getElementById("rf_description").value;

    if (!summary || !description) {
      setErrors({
        summary: summary ? false : true,
        description: description ? false : true,
        submit: false,
      });
      return;
    }

    setErrors({
      summary: false,
      description: false,
      submit: false,
    });
    setLoading(true);
    await submit_request(summary, description);
    return;
  };

  const submit_request = async (summary, description) => {
    const axios = require("axios");
    await axios({
      method: "post",
      url: get_api_host() + "/change/request",
      data: {
        summary: summary,
        description: description,
      },
      headers: {
        "Content-Type": "application/json",
        myCloudProxySession: get_id_token(),
      },
    })
      .then((response) => {
        setLoading(false);
        close();
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setErrors({
          summary: false,
          description: false,
          submit: true,
        });
      });
  };

  const close = () => {
    if (!loading) {
      setOpen(false);
      setLoading(false);
      setErrors({
        summary: false,
        description: false,
        submit: false,
      });
      setSuccess(false);
    }
  };

  return (
    <div style={{ paddingBottom: "50px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Request Feature
      </Button>
      <Modal
        open={success}
        onClose={() => {
          setSuccess(false);
        }}
        onBackdropClick={() => {
          setSuccess(false);
        }}
      >
        <div
          style={{
            position: "relative",
            top: "30%",
            margin: "auto",
            width: "350px",
            height: "150px",
            backgroundColor: "white",
            padding: "40px 0px 0px 40px",
          }}
        >
          <Typography variant="h6">Ticket Created Successfully</Typography>
          <div
            style={{
              width: "100px",
              margin: "auto",
              position: "relative",
              top: "20px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ border: "solid 1px black" }}
              onClick={() => {
                setSuccess(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={open} onClose={close} onBackdropClick={close}>
        <div
          style={{
            position: "relative",
            top: "30%",
            margin: "auto",
            width: "500px",
            height: "400px",
            backgroundColor: "white",
            padding: "40px 40px 0px 40px",
          }}
        >
          <Typography variant="h6">Request a Feature</Typography>
          <Typography variant="body2" style={{ color: "red" }}>
            {error_msg()}
          </Typography>
          <div style={{ paddingTop: "25px" }}>
            <TextField
              required
              error={errors.summary}
              id="rf_summary"
              label="Summary"
              variant="outlined"
              fullWidth={true}
              placeholder="Brief summary of the new feature"
              disabled={loading}
            />
          </div>
          <div style={{ paddingTop: "25px" }}>
            <TextField
              multiline
              rows={5}
              rowsMax={7}
              required
              error={errors.description}
              id="rf_description"
              label="Description"
              variant="outlined"
              fullWidth={true}
              placeholder="Detailed description of the new feature"
              disabled={loading}
            />
          </div>
          <div style={{ paddingTop: "25px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={validate}
              disabled={loading}
            >
              {submit_label()}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ border: "solid 1px black", float: "right" }}
              onClick={close}
              disabled={loading}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RequestEnhancement;
