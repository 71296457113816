import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./DevPortal.scss";
import Header from "./Header/Header.js";
import Content from "./Content/Content.js";
import Footer from "./Footer/Footer";
import MyCloud from "./Helper/MyCloud.js";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig.js";
import { callMsGraph } from "../graph.js";
import { useMsal } from "@azure/msal-react";

// Styling for material-ui components (updates are global settings)
const theme = createTheme({
  palette: {
    primary: {
      main: "#007FA3",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontSize: 16,
  },
  overrides: {
    MuiContainer: {
      root: {
        maxWidth: "1600px",
      },
      maxWidthLg: {
        maxWidth: "1600px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#ffffff",
          boxShadow: "none",
        },
      },
      contained: {
        backgroundColor: "ffffff",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "ffffff",
          boxShadow: "none",
        },
      },
      containedSecondary: {
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#ffffff",
          boxShadow: "none",
        },
        "& > .MuiButton-label": {
          color: "#212121",
          boxShadow: "none",
          fontSize: "13px",
        },
        "& > .MuiButton-label:hover": {
          "& .circle": {
            color: "grey",
            borderColor: "grey",
          },
          backgroundColor: "#ffffff",
          boxShadow: "none",
          color: "grey",
          fontSize: "14px",
        },
      },
    },
  },
});

function DevPortal(props) {
  const { instance } = useMsal();

  const [fixedHeader, setFixedHeader] = useState(true);
  let isAuthenticated = useIsAuthenticated();
  useEffect(() => {
      if (!isAuthenticated) {
      // console.log("is auth", isAuthenticated);
      // RequestProfileData();

      Login();
    } else {
      RequestProfileData();
    }
  }, []);

  const Login = () => {
    console.log("inside login");
    const msalKeys = 
      localStorage.getItem("msal.account.keys") || [];
      // const msalnew=JSON.parse(msalKeys)||[];
      console.log("enter", msalKeys);

    if (msalKeys.length === 0) {
      console.log("enter", isAuthenticated);
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }else {
            RequestProfileData();

    }
  };
  const { accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // console.log('access token', response.accessToken);
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }
  return (
    <>
      {" "}
      {isAuthenticated ? (
        <>
          <AuthenticatedTemplate>
            <Router>
              <ThemeProvider theme={theme}>
                <Header isFixed={fixedHeader} />
                <div style={{ height: "80px" }} />
                <Content setFixedHeader={setFixedHeader} />
                <div style={{ width: "100%", height: "200px" }} />
                <Footer />
              </ThemeProvider>
            </Router>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <></>
          </UnauthenticatedTemplate>
        </>
      ) : (
        <></>
      )}
    </>
  );
  // <MyCloud>

  // </MyCloud>;
}

export default DevPortal;
