import React from "react";
import { useCookies } from 'react-cookie';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {Container,Grid,Typography} from '@material-ui/core';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import {Switch, Route, Link} from 'react-router-dom';
import {Desktop, NotDesktop} from '../../Responsive.js';
import { get_api_host, get_id_token } from '../../Helper/HostInfo';

export function SidebarItem(props) {
  let scroll_point = () => {
    let element = document.getElementById(props.scroll_to);
    if(element){
      let bodyRect = document.body.getBoundingClientRect();
      let elemRect = element.getBoundingClientRect();
      let offset = elemRect.top - bodyRect.top;
      window.scrollTo(0,offset);
    } else {
      window.scrollTo(0,0);
    }
  };
  return <MenuItem>{props.name}<Link to={props.root + "/api/" + props.folder + "/" + props.doc + "?id=" + props.scroll_to} onClick={scroll_point}/></MenuItem>
}

export function SidebarSubMenu(props) {
  return <>
    <Desktop>
      <SubMenu title={props.name} defaultOpen={props.open}>{props.children}</SubMenu>
    </Desktop>
    <NotDesktop>
      <SubMenu title={props.name}>{props.children}</SubMenu>
    </NotDesktop>
  </>
}

function SidebarNav(props) {
  let header = () => {
    if (!props.hide_header) {
      return <SidebarHeader>
        <Menu>
          <MenuItem>
            {props.overview}
            <Link to={props.overview_route}/>
          </MenuItem>
          <MenuItem>
            &nbsp;&nbsp;&nbsp;&nbsp;{props.onboarding}
            <Link to={props.onboarding_route}/>
          </MenuItem>
        </Menu>
      </SidebarHeader>;
    } else {
      return <></>;
    }
  };

  return(<ProSidebar width={props.width}>
    {header()}
    <SidebarContent>
    <Menu>
      {props.sidebar}
    </Menu>
    </SidebarContent>
  </ProSidebar>);
}

function EmptySwagger() {
  return <Container style={{minHeight:"1000px"}}>
    <Typography style={{color:"#007fa3",paddingTop:"20px",paddingBottom:"20px"}} variant="h3" align="left">Looking for documentation?</Typography>
    <Typography variant="body2">
      Look through the <a href="/discover">Discover</a> page to read about the different products available on the developer portal and the services they offer.
      <br/><br/>
      If you know what you're looking for, select a service on the left to load its documentation.
    </Typography>
  </Container>;
}

function SwaggerContent(props) {
  const [cookies] = get_id_token();
  const params = (new URL(document.location)).searchParams;
  const name = params.get("id");
  const doc = props.doc;
  const folder = props.folder;
  const complete = props.scroll_point(name);
  const url = get_api_host() + "/doc/" + folder + "/" + doc + ".yml";

  const update_request = (req) => {
    let headers = req.headers;
    headers.myCloudProxySession = get_id_token();
    req.headers = headers;
    return req;
  };

  if(doc && props.swagger_url.includes(doc)){
    return <SwaggerUI url={url} requestInterceptor={update_request} docExpansion="full" defaultModelsExpandDepth={-1} onComplete={complete}/>;
  } else {
    return <EmptySwagger/>
  }
}

class SwaggerPage extends React.Component {
  constructor(props) {
    super(props)
    this.scroll_point = 0;
    this.state = {doc:props.doc,folder:props.folder};
  }
  handleScroll(event) {
    let scroll = window.scrollY;
    let position_type = document.getElementsByClassName("pro-sidebar")[0].style.position;
    let width = document.getElementsByClassName("pro-sidebar")[0].style.width;
    if(scroll >= 80 && position_type !== "fixed" && width !== "100%") {
      document.getElementsByClassName("pro-sidebar")[0].style.position = "fixed";
      document.getElementsByClassName("pro-sidebar")[0].style.top = 80;
    } else if(scroll < 80 && position_type !== "static") {
      //document.getElementsByClassName("pro-sidebar")[0].style.position = "static";
      document.getElementsByClassName("pro-sidebar")[0].style.position = "fixed";
      document.getElementsByClassName("pro-sidebar")[0].style.top = 80;
    }
  }
  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  static getDerivedStateFromProps(nextProps,prevState){
    let doc = nextProps.doc;
    let folder = nextProps.folder;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let set_scroll_to_zero = () => {
      let position = document.body.getBoundingClientRect().top;
      window.scrollTo(0,position)
    };
    let scroll_point = (id) => () => {
      let element = document.getElementById(id);
      if(element){
        let bodyRect = document.body.getBoundingClientRect();
        let elemRect = element.getBoundingClientRect();
        let offset = elemRect.top - bodyRect.top;
        window.scrollTo(0,offset);
      } else {
        set_scroll_to_zero();
      }
    };
    return(<div id="swagger_wrapper" className="section">
      <Desktop>
        <Container maxWidth={false} style={{position:"relative",top:"-30px"}}>
          <Grid container>
            <Grid item md={3}>
              <SidebarNav
                sidebar={this.props.sidebar}
                items={this.props.items}
                overview={this.props.overview_title}
                overview_route={this.props.overview_route}
                onboarding={this.props.onboarding_title}
                onboarding_route={this.props.onboarding_route}
                scroll_point={scroll_point}
                width="350px"
                hide_header={this.props.hide_header}
              />
            </Grid>
            <Grid item md={9}>
              <div style={{paddingLeft:"50px"}}>
                <Switch>
                  <Route path={this.props.onboarding_route}>
                    {this.props.onboarding_component}
                  </Route>
                  <Route path="/">
                    <SwaggerContent swagger_url={this.props.swagger_url} scroll_point={scroll_point} doc={this.state.doc} folder={this.state.folder}/>
                  </Route>
                </Switch>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Desktop>
      <NotDesktop>
          <Grid container direction="column">
            <Grid item md={12}>
              <SidebarNav
                sidebar={this.props.sidebar}
                items={this.props.items}
                overview={this.props.overview_title}
                overview_route={this.props.overview_route}
                onboarding={this.props.onboarding_title}
                onboarding_route={this.props.onboarding_route}
                scroll_point={scroll_point}
                width="100%"
              />
            </Grid>
            <Grid item md={12}>
              <SwaggerContent swagger_url={this.props.swagger_url} scroll_point={scroll_point} doc={this.state.doc} folder={this.state.folder}/>
            </Grid>
          </Grid>
      </NotDesktop>
    </div>);
  }
}

export default SwaggerPage;
