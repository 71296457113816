import OverviewPage from '../../Overview/OverviewPage';
import React from 'react';

function CommunicationOverview(props){
	return <OverviewPage
		url = {(doc) => {return "/communication/api/Collaboration and Communication/" + doc}}
		title = "Collaborations and Communications"
		subtitle = "Provides embedded or link out experiences within the product model to enable capabilities like video conference, chat, whiteboard, etc. This category also comes with two SPAs as well called one Meet and one Conference to schedule and view events, launch video conferences, share whiteboards, and send/receive messages in a chat window."
		service_owner = {{
	    owner:"Srinivas Karedla",
	    user:"srinivas.karedla@pearson.com",
			href: "https://teams.microsoft.com/l/channel/19%3adc85e3230f8f44dd91759ddc9f6de619%40thread.tacv2/Collaboration%2520and%2520Communication?groupId=80e90ead-c5f3-4e2d-bf93-4806745cad33&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"
		}}
		nav_links = {[
			{
				href:'#business',
				label:"Business Context"
			},
      { href:'#virtual_classroom',
        label:"Virtual Classroom"
      },
			{
				href:'#video_conferencing',
				label:"Video Conferencing"
			},
			{
				href:'#calendar',
				label:"Calendar"
			}
		]}

		sections = {[
			{
				id:"business",
				type:"business_context_footer",
				height:250,
				sections:[{
					title:"Virtual Classroom",
					color:"#dba63f",
					text:<p>A web application that provides teachers and students a one of a kind virtual classroom experience. With features that include polling/quizzes, classroom participation, breakoutrooms w/ omniprecesne, custom layouts, chat, advanced whiteboard and screenshare we have completely reimagined the virtual learning experience.</p>,
          href:'#virtual_classroom'
				},
				{
					title:"Video Conferencing",
					color:"#dd77e4",
					text:<p>Standard  video conferencing solution backed by Twillio with an AWW whiteboard integration.<br/><br/><br/><br/><br/><br/><br/></p>,
          href:'#video_conferencing'
				},
        {
          title:"Calendar",
					color:"#dba63f",
					text:<p>Gives users the ability to create, update, and delete MS Teams calendar events within a web application.  Promotes user retention by not bouncing them out to another application.<br/><br/><br/><br/><br/></p>,
          href:'#calendar'
        }]
			},
      {
        id:"business_context_consumers",
        type:"custom",
        title:"Consumers",
        content:<div style={{position:"relative",top:"-30px",left:"25px"}}>
          <p>Virtual Classroom:</p>
          <ul>
            <li>OBL</li>
          </ul>
          <p>MS Teams integration:</p>
          <ul>
            <li>Everest</li>
          </ul>
        </div>
      },
      {
        id:"virtual_classroom",
        type:"api_with_roadmap_video",
        title:"Virtual Classroom",
        team:"virtual_classroom",
        roadmap_title:"Virtual Classroom",
        request_enhancement:"true",
        videos:[{
          title:"Virtual Classroom - Demo",
          file:"VirtualClassroom.mp4"
        }],
        sections:[{
          title:"Chat",
          url:"virtual-classroom-swagger",
          src:"pearson_7.jpg",
          content:"AWS Chime Messaging API used to send chat messages, attachments, and emojis to participants."
        },
        {
          title:"Classrooms",
          url:"virtual-classroom-swagger",
          src:"pearson_5.jpg",
          content:"A classroom is logical container for a set of students and teacher(s). When creating a classroom a unique meetingID is generated for reusable URLs. Teachers can create/delete/update classroom attributes"
        },
        {
          title:"Course Materials",
          url:"virtual-classroom-swagger",
          src:"pearson_6.jpg",
          content:"A library of classroom documents, screenshots and other artifacts."
        },
        {
          title:"Meeting",
          url:"virtual-classroom-swagger",
          src:"pearson_2.jpg",
          content:"Start/Stop a Virtual Classroom meeting using AWS Chime."
        },
        {
          title:"Recordings",
          url:"virtual-classroom-swagger",
          src:"pearson_3.jpg",
          content:"Start/Stop/Save a Virtual Classroom recording. Recordings are saved to a participants dashboards."
        },
        {
          title:"Whiteboard",
          url:"virtual-classroom-swagger",
          src:"pearson_1.jpg",
          content:"Our collaborative solution allows teachers and students to draw, graph, write, or markup images or documents. Save these documents to the classroom document library."
        }]
      },
      {
				id:"video_conferencing",
				type:"apis_with_video",
				title:"Video Conferencing",
				videos:[{
          title:"Scheduling a Meeting",
					file:"collaboration-scheduling.mp4"
        },
        {
          title:"Twillio Video Conferencing",
					file:"collaboration-videoconference-twillio.mp4"
				}],
				sections:[{
					title:"Group Management",
					url:"Group-API_Swagger",
					src:"pearson_3.jpg",
					content:"An instructor or a school administrator can create specific groups that can span across multiple courses. By default, instructors and administrators are automatically added to a group that they create."
				},{
					title:"Video Conference",
					url:"VideoConference-API_Swagger",
					src:"pearson_2.jpg",
					content:"Standard video conferencing solution backed by Twillio with an AWW whiteboard integration."
				}]
			},
			{
				id:"calendar",
				type:"apis",
				title:"Calendar",
				sections:[{
					title:"Microsoft Teams",
					url:"teams-integration-service-API_Swagger",
					src:"pearson_4.jpg",
					content:"Gives users the ability to create, update, and delete MS Teams calendar events within a web application.  Promotes user retention by not bouncing them out to another application."
				}]
			}
		]}
	/>
}

export default CommunicationOverview;
