import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import DevelopOnboarding from './DevelopOnboarding';
import {SideBar as CustomerMenu, docs as customer_docs} from '../Customer/CustomerApi';
import {SideBar as DataMenu, docs as data_docs} from '../Data/DataApi';
import {SideBar as UserManagementMenu, docs as user_management_docs} from '../UserManagement/UserManagementApi';
import {SideBar as EcommerceMenu, docs as ecommerce_docs} from '../Ecommerce/EcommerceApi';
import {SideBar as ProductMenu, docs as product_docs} from '../Product/ProductApi';
import {SideBar as CourseMenu, docs as course_docs} from '../Course/CourseApi';
import {SideBar as CommunicationMenu, docs as communication_docs} from '../Communication/CommunicationApi';
import {SideBar as ContentMenu, docs as content_docs} from '../Content/ContentApi';
import {SideBar as IdentityMenu, docs as identity_docs} from '../Identity/IdentityApi';

function docs() {
  return [].concat(
    customer_docs(),
    data_docs(),
    user_management_docs(),
    ecommerce_docs(),
    product_docs(),
    course_docs(),
    communication_docs(),
    content_docs(),
    identity_docs()
  );
}

class DevelopApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder, doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      return this.state.doc === n
    };
    return <SwaggerPage
      hide_header
      sidebar = {<SubMenu open name="API Docs">
        <SubMenu name="Collaborations & Communications">
          <CommunicationMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Content">
          <ContentMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Course Management">
          <CourseMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Customer">
          <CustomerMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Ecommerce and Marketing">
          <EcommerceMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Enterprise Data">
          <DataMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Identity and Enablement">
          <IdentityMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="Product Management">
          <ProductMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
        <SubMenu name="UserManagement">
          <UserManagementMenu root="/develop" handle_open={handle_open}/>
        </SubMenu>
      </SubMenu>}
      overview_title='Available Developer Portal Services'
      overview_route='/develop'
      onboarding_title='Onboarding'
      onboarding_route='/develop/onboarding'
      onboarding_component={<DevelopOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default DevelopApi;
