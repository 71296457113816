import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import CommunicationOnboarding from './CommunicationOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Virtual Classroom" open={props.handle_open('virtual-classroom-swagger')}>
      <SubMenu name="Chat" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_channel_create' name='Create a Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_channel_member_add' name='Add a Member to a Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_channel_member_ban' name='Ban a Member from a Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-post_chime_chat_channel_member_list' name='List Members in a Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_channel_member_remove' name='Remove Member from a Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_chat_channel_message_endpoint' name='Get Messaging Session Endpoint'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-post_chime_chat_channel_message_list' name='List Channel Messages'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_channel_message_redact' name='Redact Message'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_channel_message_remove' name='Delete Message'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_channel_message_send' name='Send Message'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-post_chime_chat_channel_message_update' name='Update Message'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_channel_remove' name='Delete Channel'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_user_create' name='Create Chat User'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_user_demote' name='Demote Chat User'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_chat_user_promote' name='Promote Chat User'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_chat_user_remove' name='Delete Chat User'/>
      </SubMenu>
      <SubMenu name="Classrooms" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_classrooms' name='Get Classroom'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_classrooms' name='Create Classroom'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_classrooms' name='Delete Classroom'/>
      </SubMenu>
      <SubMenu name="Course Materials" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_document' name='Get Course Materials'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_document' name='Create Course Materials'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_document' name='Delete Course Materials'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_documents' name='List Course Materials'/>
      </SubMenu>
      <SubMenu name="Meeting" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_attendee' name='Get Attendee'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_attendee' name='Create Attendee'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_attendee' name='Delete Attendee'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_attendees' name='List Attendees'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_meeting' name='Get Meeting Details'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_meeting' name='Create Meeting'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_meeting' name='Delete Meeting'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_meetings' name='List Meetings'/>
      </SubMenu>
      <SubMenu name="Recordings" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_recording' name='Get Meeting Recording'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_recording' name='Create Recording'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-delete_chime_recording' name='Delete Recording'/>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-get_chime_recordings' name='List Recordings'/>
      </SubMenu>
      <SubMenu name="Whiteboard" open={props.handle_open('virtual-classroom-swagger')}>
        <Item folder='Collaboration and Communication' doc='virtual-classroom-swagger' root={props.root} scroll_to='operations-default-put_chime_whiteboard' name='Create Whiteboard Image'/>
      </SubMenu>
    </SubMenu>
    <SubMenu name="Video Conferencing" open={props.handle_open(['Group-API_Swagger','VideoConference-API_Swagger'])}>
      <SubMenu name="Group Management" open={props.handle_open('Group-API_Swagger')}>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-removeMemberUsingDELETE' name='Remove Member'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-getGroupsUsingGET' name='Get Groups'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-createGroupUsingPOST' name='Create Group'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-findGroupUsingGET' name='Find Group'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-updateGroupUsingPUT' name='Update Group'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-removeGroupUsingDELETE' name='Remove Group'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-updateGroupByPatchUsingPATCH' name='Update Group by Patch'/>
        <Item folder='Collaboration and Communication' doc='Group-API_Swagger' root={props.root} scroll_to='operations-group-controller-getAllParticipantsUsingGET' name='Get All Participants'/>
      </SubMenu>
      <SubMenu name="Video Conference" open={props.handle_open('VideoConference-API_Swagger')}>
        <Item folder='Collaboration and Communication' doc='VideoConference-API_Swagger' root={props.root} scroll_to='operations-video-conference-controller-createVideoConferenceUsingPOST' name='Create New Video Conference'/>
        <Item folder='Collaboration and Communication' doc='VideoConference-API_Swagger' root={props.root} scroll_to='operations-video-conference-controller-getAllVideoConferencesForUserIdUsingGET' name='Get Video Conference by User'/>
        <Item folder='Collaboration and Communication' doc='VideoConference-API_Swagger' root={props.root} scroll_to='operations-video-conference-controller-getVideoConferenceUsingGET' name='Get Video Conference by ConferenceID'/>
        <Item folder='Collaboration and Communication' doc='VideoConference-API_Swagger' root={props.root} scroll_to='operations-video-conference-controller-putVideoConferenceUsingPUT' name='Update Video Conference'/>
        <Item folder='Collaboration and Communication' doc='VideoConference-API_Swagger' root={props.root} scroll_to='operations-video-conference-controller-deleteVideoConferenceUsingDELETE' name='Delete Video Conference'/>
      </SubMenu>
    </SubMenu>
    <SubMenu name="Chat">
    </SubMenu>
    <SubMenu name="Whiteboard">
    </SubMenu>
    <SubMenu name="Scheduling">
    </SubMenu>
    <SubMenu name="Calendar" open={props.handle_open('teams-integration-service-API_Swagger')}>
      <SubMenu name="Microsoft Teams" open={props.handle_open('teams-integration-service-API_Swagger')}>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-inbound-redirect-controller-createMeetingUsingGET' name='Create Meeting (Inbound Redirect)'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-social-login-token-controller-createMeetingUsingPOST' name='Create Meeting (Social Login)'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-getEventsUsingGET' name='Get All Events for User'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-updateMeetingUsingPUT' name='Update an Event'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-deleteMeetingUsingDELETE' name='Delete an Event'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-createMeetingUsingPOST_1' name='Create Meeting'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-getSchoolIdsUsingGET' name='Get Microsoft Schools'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-getGroupsInOrganizationUsingGET' name='Get Group Details'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-getListOfGroupMembersUsingGET' name='Get Members in Group'/>
        <Item folder='Collaboration and Communication' doc='teams-integration-service-API_Swagger' root={props.root} scroll_to='operations-teams-integration-controller-getListOfGroupIdsByOrganizationUsingGET' name='Get All Groups in Org'/>
      </SubMenu>
    </SubMenu>
  </>;
}

export function docs() {
  return [
    'Group-API_Swagger',
    'VideoConference-API_Swagger',
    'teams-integration-service-API_Swagger',
    'virtual-classroom-swagger'
  ];
}

class CommunicationApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      hide_header
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/communication' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Collaborations and Communications'
      overview_route='/communication'
      onboarding_title='Onboarding'
      onboarding_route='/communication/onboarding'
      onboarding_component={<CommunicationOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default CommunicationApi;
