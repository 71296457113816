import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import CustomerOnboarding from './CustomerOnboarding';

export function SideBar(props){
  return <>
    <SubMenu name="Gateway Platform Services" open={props.handle_open('GatewayPlatform-API')}>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-addImagesUsingPOST' name='Add Images'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-getImagesUsingGET' name='Get Images'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-getAllPlatformImagesUsingGET' name='Get all Platform Images'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-getPlatformImagesUsingGET' name='Get Platform Images'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-upsertPlatformImagesUsingPUT' name='Insert Platform Images'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Image-deletePlatformImagesUsingDELETE' name='Delete Platform Images Using DELETE'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-getAllPlatformsUsingGET' name='Get all Platforms'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-createPlatformUsingPOST' name='Create Platform'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-createPlatformUsingPOST_1' name='Create Platform with Locale'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-getPlatformWithPlatformIdUsingGET' name='Get Platform with PlatformId'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-putPlatformWithPlatformIdUsingPUT' name='Put Platform with PlatformId'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-getAllPlatformsUsingGET_1' name='Get all Platforms with Locale'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-getPlatformWithPlatformIdUsingGET_1' name='Get Platform with Locale and PlatformId'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-putPlatformWithPlatformIdUsingPUT_1' name='Get Platform with Locale and PlatformId'/>
      <Item folder='Customer Experience' doc='GatewayPlatform-API' root={props.root} scroll_to='operations-Platform-getPlatformByProductModelIdUsingGET' name='Gut Platform by ProducteModelId'/>
    </SubMenu>
    <SubMenu name="Gateway Platform Entitlements" open={props.handle_open('GatewayPlatformEntitlements-API_Swagger')}>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-getPlatformEntitlementsByUserUsingGET' name='Get Platforms for User'/>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-updatePlatformEntitlementsOfUserUsingPUT' name='Update User Platforms'/>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-getPlatformEntitlementsByPlatformIdUsingGET' name='Get Users for a Platform'/>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-updateUsersOfPlatformUsingPUT' name='Update Users for a Platform'/>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-grantPlatformAccessToUserUsingPOST' name='Grant User Access to a Platform'/>
      <Item folder='Customer Experience' doc='GatewayPlatformEntitlements-API_Swagger' root={props.root} scroll_to='operations-platform-entitlements-controller-revokePlatformAccessToUserUsingDELETE' name='Remove User from a Platform'/>
    </SubMenu>
    <SubMenu name="Gateway Configuration" open={props.handle_open('GatewayConfiguration-API_Swagger')}>
      <SubMenu name="Gateway Product Model Controller">
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-product-model-controller-getProductModelUsingGET' name='Get Product Model of Gateway'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-product-model-controller-setProductModelIdUsingPUT' name='Set Product Model of Gateway'/>
      </SubMenu>
      <SubMenu name="Gateway SPA Controller">
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-spa-controller-getSpaDetailsUsingGET' name='Get SPA'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-spa-controller-updateSpaDetailsUsingPUT' name='Update SPA'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-spa-controller-getAvailableSpaListUsingGET' name='Get All SPAs'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-gateway-spa-controller-addSpaListUsingPOST' name='Add New SPA(s)'/>
      </SubMenu>
      <SubMenu name="Region Controller">
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getRegionByHostNameUsingGET' name='Get Region by Hostename'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getRegionByHostNameAndLocaleUsingGET' name='Get Region by Hostname and Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getAllAvailableRegionsUsingGET' name='Get All Available Regions'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getRegionByRegionIdUsingGET' name='Get Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-addRegionUsingPUT' name='Add Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionUsingPOST' name='Update Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getConfigurationByLocaleUsingGET' name='Get Configuration by Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionConfigurationWithLocaleUsingPUT' name='Update Region Configuration'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getFooterLinkDetailsMatchWithLocaleUsingGET' name='Get Footer Link from Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateFooterLinkDetailsMatchWithLocaleUsingPUT' name='Update Footer Link in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-deleteFooterLinkFromRegionUsingDELETE' name='Remove Footer Link from Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getAllFooterLinksInRegionUsingGET' name='Get All Footer Links in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-createFooterLinkUsingPOST' name='Create Footer Link in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-createFooterLinksInBulkUsingPOST' name='Create Multiple Footer Links in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateFooterLinksOrderUsingPUT' name='Update Footer Links in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getHostnamesUsingGET' name='Get Hostnames from Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateHostnamesUsingPUT' name='Update Hostnames in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getFooterLinkDetailsMatchWithLocaleUsingGET_1' name='Get Footer Link in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateFooterLinkDetailsMatchWithLocaleUsingPUT_1' name='Update Footer Link in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getAllFooterLinksInRegionWithLocaleSpecificUsingGET' name='Get All Footer Links in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-createFooterLinkWithLocaleUsingPOST' name='Create Footer Links for Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getRegionSpaDetailsUsingGET' name='Get SPA from Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionSpaDetailsUsingPUT' name='Update SPA in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getSpasInRegionUsingGET' name='Get All SPAs in Locale'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getRegionSpaDetailsUsingGET_1' name='Get all SPAs in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-registerSpaUsingPOST' name='Add SPA to Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionSpaDetailsUsingPUT_1' name='Update SPA in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-deleteSpaFromRegionUsingDELETE' name='Remove SPA from Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-getSpasInRegionUsingGET_1' name='Get SPAs in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionSpaOrderUsingPUT' name='Update Order of SPAs in Region'/>
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-controller-updateRegionSpaOrderUsingPUT_1' name='Set Order of SPAs'/>
      </SubMenu>
      <SubMenu name="Region List Controller">
        <Item folder='Customer Experience' doc='GatewayConfiguration-API_Swagger' root={props.root} scroll_to='operations-region-list-controller-getAllAvailableRegionsUsingGET_1' name='Get All Regions'/>
      </SubMenu>
    </SubMenu>
    <SubMenu name="Landing Page" open={props.handle_open('LandingPage-API_Swagger')}>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-getAllRegionsUsingGET' name='Get All Landing Pages in All Region'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-getLandingPageForRegionUsingGET' name='Get All Landing Pages in a Region'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-postLandingPageForRegionUsingPOST' name='Add Configuration for A Landing Pages in a Region'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-putLandingPageForRegionUsingPUT' name='Update Configuration for A Landing Pages in a Region'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-getLandingPageForRegionUsingGET_1' name='Get All Landing Pages in a Locale'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-putLandingPageForRegionUsingPUT_1' name='Update Configuration for A Landing Pages in a Locale'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-getAllWidgetsUsingGET' name='Get All Widgets for Landing Pages'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-createWidgetForLandingPageUsingPOST' name='Create a Widget for Landing Pages'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-getWidgetBasedOnIdUsingGET' name='Get a Widget for Landing Pages'/>
      <Item folder='Customer Experience' doc='LandingPage-API_Swagger' root={props.root} scroll_to='operations-Landing_Page_Service_Endpoints-putWidgetBasedOnIdUsingPUT' name='Update a Widget for Landing Pages'/>
    </SubMenu>
    <SubMenu name="Gateway Resource" open={props.handle_open('GatewayResources-API_Swagger')}>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getAllResourcesUsingGET' name='Get All Gateway Resources'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-createResourceUsingPOST' name='Create Gateway Resource'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourceByResourceIdAndLocaleUsingGET' name='Get Gateway Resource Details'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-updateResourceUsingPUT' name='Update Gateway Resource'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-deleteResourceUsingDELETE' name='Remove Gateway Resource'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourceByResourceIdAndLocaleUsingGET_1' name='Get Gateway Resource Info for Locale'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-updateResourceUsingPUT_1' name='Update Gateway Resource Info for Locale'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourcesByPlatformUsingGET' name='Get Gateways for a Platform'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourceByPlatformWithLocaleUsingGET' name='Get Gateways for a Platform by Locale'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-saveResourceMappingwithRegionOrPlatformUsingPOST' name='Add Gateway to Platform'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-deleteResourceFromPlatformUsingDELETE' name='Remove Gateway from a Platforms'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourcesByRegionUsingGET' name='Get All Gateways for a Region'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-getResourcesByRegionWithLocaleUsingGET' name='Get All Gateways for a Locale'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-saveResourceMappingwithRegionUsingPOST' name='Add Gateway to Region'/>
      <Item folder='Customer Experience' doc='GatewayResources-API_Swagger' root={props.root} scroll_to='operations-Gateway_Resources_Service_Endpoints-deleteResourceFromRegionIdUsingDELETE' name='Remove Gateway from Region'/>
    </SubMenu>
    <SubMenu name="Notification" open={props.handle_open('Notification-API_Swagger')}>
      <Item folder='Customer Experience' doc='Notification-API_Swagger' root={props.root} scroll_to='operations-Notification_Service_Endpoints-saveNotificationUsingPOST' name='Create a Notification'/>
      <Item folder='Customer Experience' doc='Notification-API_Swagger' root={props.root} scroll_to='operations-Notification_Service_Endpoints-getNotificationsByUserIdUsingGET' name='Get Notifications of a User'/>
      <Item folder='Customer Experience' doc='Notification-API_Swagger' root={props.root} scroll_to='operations-Notification_Service_Endpoints-getNotificationByNotificationIdUsingGET' name='Get Status of a Notification'/>
    </SubMenu>
    <SubMenu name="Notification Decision" open={props.handle_open('NotificationDecision-API_Swagger')}>
      <Item folder='Customer Experience' doc='NotificationDecision-API_Swagger' root={props.root} scroll_to='operations-Notification_Decision_Endpoints-addNotificationDecisionUsingPOST' name='Add a Notification Decision'/>
      <Item folder='Customer Experience' doc='NotificationDecision-API_Swagger' root={props.root} scroll_to='operations-Notification_Decision_Endpoints-deleteNotificationDecisionUsingDELETE' name='Delete a Notification Decision'/>
    </SubMenu>
    <SubMenu name="Template" open={props.handle_open('Template-API_Swagger')}>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-createTemplateUsingPOST' name='Create a Template'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-retrieveTemplateByIdUsingGET' name='Get a Template'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-updateTemplateUsingPUT' name='Update a Template'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-deleteTemplateByIdUsingDELETE' name='Remove a Template'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-finalizeTemplateUsingPUT' name='Finalize a Template'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-retrieveAllTemplatesUsingGET' name='Get All Templates'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-getTemplateByProductModelRegionUsingGET' name='Get a Template by Region and EmailType'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-populateDataByProductModelTypeUsingPOST' name='Update a Template by Region and EmailType'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-populateDataByTypeUsingPOST' name='Update a Template by EmailType'/>
      <Item folder='Customer Experience' doc='Template-API_Swagger' root={props.root} scroll_to='operations-Template_Service_Endpoints-populateDataByTemplateIdUsingPOST' name='Update a Template by Id'/>
    </SubMenu>
  </>;
}
export function docs() {
  return [
    'GatewayPlatform-API',
    'GatewayPlatformEntitlements-API_Swagger',
    'GatewayConfiguration-API_Swagger',
    'GatewayResources-API_Swagger',
    'LandingPage-API_Swagger',
    'Notification-API_Swagger',
    'NotificationDecision-API_Swagger',
    'Template-API_Swagger'
  ];
}


class CustomerApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs"><SideBar root='/customer' handle_open={handle_open}/></SubMenu>}
      overview_title='Customer Experience Overview'
      overview_route='/customer'
      onboarding_title='Onboarding'
      onboarding_route='/customer/onboarding'
      onboarding_component={<CustomerOnboarding />}
      swagger_url={[
        'GatewayPlatform-API',
        'GatewayPlatformEntitlements-API_Swagger',
        'GatewayConfiguration-API_Swagger',
        'GatewayResources-API_Swagger',
        'LandingPage-API_Swagger',
        'Notification-API_Swagger',
        'NotificationDecision-API_Swagger',
        'Template-API_Swagger'
      ]}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default CustomerApi;
