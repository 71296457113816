import React from 'react';
import SwaggerPage from '../../Swagger/SwaggerPage';
import {SidebarItem as Item, SidebarSubMenu as SubMenu} from '../../Swagger/SwaggerPage';
import ProductOnboarding from './ProductOnboarding';

export function SideBar(props) {
  return <>
    <SubMenu name="Entitlement Service" open={props.handle_open('Entitlement-API_Swagger')}>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getAccessCodeMechanismsUsingGET' name='Get Access Mechanisms'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementsByEntitlmentIdUsingGET' name='Get Entitlement by ID'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-updateEntitlementByEntitlmentIdUsingPUT' name='Update an Entitlement'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementWindowTypeByEntitlementIdAndResouceIdUsingGET' name='Get Entitlement by Resource'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-updateEntitlementWindowTypeByEntitlementIdAndResouceIdUsingPUT' name='Update an Entitlement by Resource'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getResourcesByEntitlementIdUsingGET' name='Get Resources by Entitlement'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementWindowTypesUsingGET' name='Get Entitlement Window Types'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-getEntitlementsBySKUIdUsingGET' name='Get Entitlements by SKU'/>
      <Item folder='Product Management' doc='Entitlement-API_Swagger' root={props.root} scroll_to='operations-entitlement-controller-createEntitlementBySKUIdUsingPOST' name='Create an Entitlement using SKU'/>
    </SubMenu>
    <SubMenu name="Entitlement Access Codes" open={props.handle_open('EntitlementAccessCodes-API_Swagger')}>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-getAccessCodeToCIUsingGET' name='Get Access Code for Course Instance'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-addAccessCodeToCIUsingPOST' name='Add an Access Code for Course Instance'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-getAccessCodeByCoursInstanceUsingGET' name='Get All Access Codes for Course Instance'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-createAccessCodeUsingPOST' name='Create New Access Code'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-getAccessCodeDetailsUsingGET' name='Get Access Code and Redemptions'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-redeemAccessCodeUsingPOST' name='Redeem Access Code'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-getAccessCodeDetailbyValueUsingGET' name='Get Access Code by Access Value'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-createAccessCodebyValueUsingPOST' name='Create Access Code with Access Value'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-redeemAccessCodebyValueUsingPOST' name='Redeem Access Code with Access Value'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-createBulkAccessCodesUsingPOST' name='Create Multiple Access Codes'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-getAccessCodeByOrgUsingGET' name='Get All Access Codes'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-createBulkAccessCodesWithOrgUsingPOST' name='Create Multiple Access Codes for an Organization'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-addAccessCodeToOrgUsingPOST' name='Add Access Code to an Organization'/>
      <Item folder='Product Management' doc='EntitlementAccessCodes-API_Swagger' root={props.root} scroll_to='operations-access-code-controller-deleteAccessCodeUsingDELETE' name='Remove Access Code from Organization'/>
    </SubMenu>
    <SubMenu name="Resources" open={props.handle_open('Resource-API_Swagger')}>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getDisciplinesUsingGET' name='Get Disciplines'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-geLOBsUsingGET' name='Get LOBs'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-addNewResourcesWithProductModelRegionUsingPOST' name='Add New Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getExternalResourceUsingGET' name='Get External Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-addExternalResourceUsingPOST' name='Add External Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-updateExternalResourceUsingPUT' name='Update External Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-deleteExternalResourceUsingDELETE' name='Delete External Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getResourcesUsingGET' name='Get Resources'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-updateResourcesUsingPUT' name='Update Resources'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-deleteResourcesUsingDELETE' name='Delete Resources'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-associateExternalResourceWithResourceUsingPUT' name='Associate External Resource With Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getResourcesWithAllPMRDataUsingGET' name='Get Resource'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getResourcesForProductModelIdUsingGET' name='GET Resource for Product Model'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-getResourcesForProductModelIdAndRegionUsingGET' name='Get Resource by Region'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-searchResourceByDisciplineUsingGET' name='Search Resource by Descipline'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-searchResourceByLobUsingGET' name='Search Resource by LOB'/>
      <Item folder='Product Management' doc='Resource-API_Swagger' root={props.root} scroll_to='operations-resource-controller-searchResourceByTitleUsingGET' name='Search Resource by Title'/>
    </SubMenu>
    <SubMenu name='SKU' open={props.handle_open('SKU-API_Swagger')}>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByCourseIdUsingGET' name='Get All SKUs for Course'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getSkuByIdUsingGET' name='Get SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-createSkuByIdUsingPOST' name='Create SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-updateSkuByIDUsingPUT' name='Update SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-deleteSkuByIdUsingDELETE' name='Deactivate SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-addResourceToSkuUsingPOST' name='Add SKU to Resource'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-deleteResourceFromSkuUsingDELETE' name='Remove SKU from Resource'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllResourcesFromSkuUsingGET' name='Get All Resources for SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-addResourcesfromCourseIdUsingPOST' name='Add All Resources for Course to SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByIdUsingGET' name='Search for SKU'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByProductModelIdUsingGET' name='Get All SKUs for a Resource'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByRegionIdUsingGET' name='Get all SKUs in Region'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByResourceNameUsingGET' name='Search for Resource'/>
      <Item folder='Product Management' doc='SKU-API_Swagger' root={props.root} scroll_to='operations-sku-controller-getAllSkusByTitleUsingGET' name='Search for SKU by Title'/>
    </SubMenu>
    <SubMenu name='Subscriptions' open={props.handle_open('Subscription-API_Swagger')}>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-createSubscriptionUsingPOST' name='Create New Subscription'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-getSubscriptionByIdUsingGET' name='Get a Subscription'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-updateSubscriptionByIdUsingPUT' name='Update a Subscription'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-deleteSubscriptionByIdUsingDELETE' name='Delete a Subscription'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-retrieveAllSubscriptionsUsingGET' name='Get All Subscriptions for User'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-retrieveSubscriptionsByResourceTypeUsingGET' name='Get All Subscriptions by Resource Type'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-retrieveSubscriptionsByUserIdAndByResourceIdUsingGET' name='Get Current Subscriptions for User by Resource'/>
      <Item folder='Product Management' doc='Subscription-API_Swagger' root={props.root} scroll_to='operations-subscription-controller-getCurrentSubscriptionByUserIdUsingGET' name='Get Current Subscriptions for User'/>
    </SubMenu>
  </>;
}

export function docs(){
  return [
    'Entitlement-API_Swagger',
    'EntitlementAccessCodes-API_Swagger',
    'Resource-API_Swagger',
    'SKU-API_Swagger',
    'Subscription-API_Swagger'
  ];
}

class ProductApi extends React.Component {
  constructor(props) {
    super(props);
    const { params: { folder,doc } } = props.match;
    this.state = {folder:folder,doc:doc};
  }

  static getDerivedStateFromProps(nextProps,prevState){
    const { params: { folder, doc } } = nextProps.match;
    if (doc !== prevState.doc){
      return {folder:folder,doc:doc};
    } else if (folder !== prevState.folder){
      return {folder:folder,doc:doc};
    } else {
      return null;
    }
  }

  render(){
    let handle_open = (n) => {
      const type = ({}).toString.call( n ).match(/\s(\w+)/)[1].toLowerCase();
      if(type === 'array'){
        return n.includes(this.state.doc)
      } else {
        return this.state.doc === n
      }
    };
    return <SwaggerPage
      sidebar = {<SubMenu open name="API Docs">
        <SideBar root='/product' handle_open={handle_open}/>
      </SubMenu>}
      overview_title='Product Management'
      overview_route='/product'
      onboarding_title='Onboarding'
      onboarding_route='/product/onboarding'
      onboarding_component={<ProductOnboarding />}
      swagger_url={docs()}
      doc={this.state.doc}
      folder={this.state.folder}
    />;
  }
}

export default ProductApi;
