import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Welcome from './Pages/Welcome/Welcome';
import DevelopApi from './Pages/Develop/DevelopApi';

import DataOverview from './Pages/Data/DataOverview';
import DataApi from './Pages/Data/DataApi';

import UserManagementOverview from './Pages/UserManagement/UserManagementOverview';
import UserManagementApi from './Pages/UserManagement/UserManagementApi';

import CustomerOverview from './Pages/Customer/CustomerOverview';
import CustomerApi from './Pages/Customer/CustomerApi';

import CommunicationOverview from './Pages/Communication/CommunicationOverview';
import CommunicationApi from './Pages/Communication/CommunicationApi';

import CourseOverview from './Pages/Course/CourseOverview';
import CourseApi from './Pages/Course/CourseApi';

import ProductOverview from './Pages/Product/ProductOverview';
import ProductApi from './Pages/Product/ProductApi';

import Consideration from './Pages/Welcome/Consideration';

import EcommerceOverview from './Pages/Ecommerce/EcommerceOverview';
import EcommerceApi from './Pages/Ecommerce/EcommerceApi';

import ContentOverview from './Pages/Content/ContentOverview';
import ContentApi from './Pages/Content/ContentApi';

import Faq from './Pages/Faq/Faq';

import IdentityOverview from './Pages/Identity/IdentityOverview';
import IdentityApi from './Pages/Identity/IdentityApi';

import ThirdPartyOverview from './Pages/ThirdParty/ThirdPartyOverview';

import Search from './Pages/Search/Search';


function Content(props){
  let fixed_header = false;
  return(
    <Switch>
      <Route path="/develop/api/:folder/:doc" component={DevelopApi}/>
      <Route path="/develop" component={DevelopApi}/>

      <Route path="/product/onboarding" component={ProductApi}/>
      <Route path="/product/api/:folder/:doc" component={ProductApi}/>
      <Route path="/product/api" component={ProductApi}/>
      <Route path="/product" component={ProductOverview}/>

      <Route path="/identity/onboarding" component={IdentityApi}/>
      <Route path="/identity/api/:folder/:doc" component={IdentityApi}/>
      <Route path="/identity/api" component={IdentityApi}/>
      <Route path="/identity" component={IdentityOverview}/>

      <Route path="/course/onboarding" component={CourseApi}/>
      <Route path="/course/api/:folder/:doc" component={CourseApi}/>
      <Route path="/course/api" component={CourseApi}/>
      <Route path="/course" component={CourseOverview}/>

      <Route path="/communication/onboarding" component={CommunicationApi}/>
      <Route path="/communication/api/:folder/:doc" component={CommunicationApi}/>
      <Route path="/communication/api" component={CommunicationApi}/>
      <Route path="/communication" component={CommunicationOverview}/>

      <Route path="/user_management/onboarding" component={UserManagementApi}/>
      <Route path="/user_management/api/:folder/:doc" component={UserManagementApi}/>
      <Route path="/user_management/api" component={UserManagementApi}/>
      <Route path="/user_management">
        <UserManagementOverview />
      </Route>

      <Route path="/customer/onboarding" component={CustomerApi}/>
      <Route path="/customer/api/:folder/:doc" component={CustomerApi}/>
      <Route path="/customer/api" component={CustomerApi}/>
      <Route path="/customer">
        <CustomerOverview />
      </Route>

      <Route path="/content/onboarding" component={ContentApi}/>
      <Route path="/content/api/:folder/:doc" component={ContentApi}/>
      <Route path="/content/api" component={ContentApi}/>
      <Route path="/content">
        <ContentOverview />
      </Route>

      <Route path="/data/onboarding" component={DataApi}/>
      <Route path="/data/api/:folder/:doc" component={DataApi}/>
      <Route path="/data/api" component={DataApi}/>
      <Route path="/data">
        <DataOverview />
      </Route>

      <Route path="/consideration">
        <Consideration />
      </Route>

      <Route path="/ecommerce/onboarding" component={EcommerceApi}/>
      <Route path="/ecommerce/api/:folder/:doc" component={EcommerceApi}/>
      <Route path="/ecommerce/api" component={EcommerceApi}/>
      <Route path="/ecommerce">
        <EcommerceOverview/>
      </Route>

      <Route path="/third_party">
        <ThirdPartyOverview/>
      </Route>

      <Route path="/faq" component={Faq}/>

      <Route path="/search" component={Search}/>

      <Route path="/">
        {fixed_header = true}
        <Welcome />
      </Route>

      {props.setFixedHeader(fixed_header)}
    </Switch>
  );
}

export default Content;
