import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

class NavLink extends React.Component {
  render() {
    return(
      <span className="nav_button" style={this.props.style}>
        <RouterLink
          to={this.props.to}
          className="nav_link"
        >
          {this.props.children}
        </RouterLink>
        <span className="nav_spacer"/>
      </span>
    );
  }
}

export default NavLink;
