import React,{useState} from 'react';
import { get_media_host } from './HostInfo';

export default function VideoPlayer(props){
  let [visible,setVisible] = useState(true);
  let hide_or_show = (c) => {
    if(c){
      return {display:"none",visible:"hidden"};
    } else {
      return {};
    }
  };

  let on_error = (e) => {
    setVisible(false)
  };

  return <div style={{paddingRight:"40px"}}>
    <div>{props.title}</div>
    <video
      style={hide_or_show(!visible)}
      width="100%"
      height="100%"
      controls={true}
      onError={on_error}
      >
      <source src={get_media_host() + "/" + props.src} type="video/mp4"/>
    </video>
    <p style={hide_or_show(visible)}>Failed to load video</p>
  </div>;
};
