import React from 'react';
import {useHistory} from 'react-router-dom';
import {Input,InputAdornment,FormControl} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchField() {
  let history = useHistory();

  const search_icon = <SearchIcon />;

  const runSearch = () => {
    const value = document.getElementById('search-field-ad').value;
    history.push("/search?q=" + value);
  };

  return (
      <FormControl style={{backgroundColor:'white',height:'30px',width:'150px',borderRadius:'5px',paddingLeft:'5px',paddingRight:'5px',marginTop:'5px',marginRight:'20px'}}>
        <Input
          style={{textSize:'12pt'}}
          id="search-field-ad"
          onKeyDown={(e) => {if(e.keyCode === 13) runSearch()}}
          startAdornment={
            <InputAdornment position="start">
              <button
                style={{backgroundColor:'#f3f5f8',color:'#007FA3',padding:'2px',height:'30px'}}
                onClick={(e) => {runSearch()}}
              >
                {search_icon}
              </button>
            </InputAdornment>
          }
        />
      </FormControl>
  );
}
