import {Mobile, NotMobile} from '../Responsive';

import Image from '../Helper/Image';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Typography} from '@material-ui/core';

class Title extends React.Component {
  render() {
    return(
      <RouterLink to="/">
        <NotMobile>
          {/* <span style={{float:"left",width:"80px",height:"10px"}}/> */}
          <Image
            src="pearson_logo_small.png"
            alt="pearson"
            className="logo"
          />
          <Typography variant="h6" className="title">
            <span className="horizontal_bar"></span>
            <span >Global Platform Services  <span className="title_highlight">Developer Portal</span></span>
           
          </Typography>
        </NotMobile>
        <Mobile>
          <span style={{float:"left",width:"30px",height:"10px"}}/>
          <Image
            src="pearson_logo_small.png"
            alt="pearson"
            className="logo-mobile"
          />
          <Typography variant="body2" className="title-mobile">
            <span className="horizontal_bar"></span>
            <span >Global Platform Services </span><br/>
            <span className="title_highlight">Developer Portal</span>
          </Typography>
        </Mobile>
      </RouterLink>
    );
  }
}

export default Title;
