import React, {useState,useEffect} from 'react';
import {Grid,Container} from '@material-ui/core';
import {OverviewGridSection} from "../../Overview/OverviewCard";


export default function Discover(props){
  const [transy,setTransy] = useState(500);

  useEffect(() => {
    setTransy(0);
  },[])

  const set_style = (timing) => {
    return {
      transition:"transform .5s ease-in " + timing + "s",
      transform:"translateY("+transy+"px)"
    };
  };

  return <Container maxWidth={false}>
    <Grid container spacing={2}>
      <OverviewGridSection
        noShadow
        title="Collaboration and Communication"
        url="/communication"
        url_api="/communication/api"
        src="pearson_1.jpg"
        style={set_style(0)}
      >
        Provides embedded or link out experiences within the product model to enable capabilities like video conference, chat, whiteboard, etc.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Content"
        url="/content"
        url_api="/content/api"
        src="pearson_2.jpg"
        style={set_style(.1)}
      >
        Allow applications to find, create, manipulate, consume, and analyze content.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Course Management"
        url="/course"
        url_api="/course/api"
        src="pearson_3.jpg"
        style={set_style(.2)}
      >
        Provides the ability to support products that are sold within the context of a course.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Customer Experience"
        url="/customer"
        url_api="/customer/api"
        src="pearson_4.jpg"
        style={set_style(.3)}
      >
        Helps power the user experiences on the global gateway and/or product model experiences, and enhance the core suite of services like product, course, and user management services.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="eCommerce and Marketing"
        url="/ecommerce"
        url_api="/eCommerce/api"
        src="pearson_5.jpg"
        style={set_style(.4)}
      >
        Providing a core set of eCommerce services for the display of products, management of a shopping cart, progression to order completion and my account functionality.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Enterprise Data"
        url="/data"
        url_api="/data/api"
        src="pearson_6.jpg"
        style={set_style(.5)}
      >
        Query and retrieve Pearson data sets from global core platforms such as TEP and MDM.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Identity and Access Management"
        url="/identity"
        src="pearson_2.jpg"
        label="Get Started"
        style={set_style(.6)}
      >
        Identity and Access Management (IdAM) is a diverse portfolio of services and processes that provide identity management, authentication, and authorization.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Product Management"
        url="/product"
        url_api="/product/api"
        src="pearson_7.jpg"
        style={set_style(.7)}
      >
        Set of services define and describe products that learners and instructors can utilize.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="Third Party"
        url="/third_party"
        url_api="/third_party"
        label="Get Started"
        src="pearson_3.jpg"
        style={set_style(.8)}
      >
        This suite of services integrates with vendors’ capabilities rather than encouraging point to point integrations.
      </OverviewGridSection>
      <OverviewGridSection
        noShadow
        title="User Permission and Authorization"
        url="/user_management"
        url_api="/user_management/api"
        src="pearson_8.jpg"
        style={set_style(.9)}
      >
        Allows students, teachers, and admins to be added to an institution. The functionalities and capabilities each role can perform are also managed by these services. 
      </OverviewGridSection>
    </Grid>
  </Container>;
}
