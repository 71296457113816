import React from 'react';
import {Typography, Grid, Container} from '@material-ui/core';
import NavButton from '../../Header/Navigation/NavButton';
import {SectionHeader} from './OverviewPageStyling';

export default function BusinessSection(props){
  const title = () => {
    if(props.title){
      return <SectionHeader>{props.title}</SectionHeader>;
    } else {
      return <SectionHeader>Business Context</SectionHeader>;
    }
  };
  return <div id={props.id} style={{...props.style}}>
    <Container maxWidth={false}>
      {title()}
      {props.content ?
        <div style={{padding:'20px',backgroundColor:"#fcfcfc",marginBottom:'20px'}}>
          {props.content}
        </div> : null}
      <Grid container spacing={2}>
        {props.sections.map((section) => {
          return <Section href={section.href} height={props.height} title={section.title} color={section.color} len={props.sections.length}>{section.text}</Section>;
        })}
      </Grid>
    </Container>
  </div>;
}

function Section(props){
  const columns = (len) => {
    if(len <= 2){
      return 6;
    } else if (len % 3 === 0){
      return 4;
    } else if (len > 3) {
      return 3;
    }
  };
  return <Grid item lg={columns(props.len)} md={6} sm={12} xs={12}>
    <div style={{
      color:"#404749",
      paddingLeft:"30px",
      paddingRight:"30px",
      paddingBottom:"20px",
      paddingTop:"30px",
      borderRadius:"3px",
      backgroundColor:"#fcfcfc",
      boxShadow:"0px 0px 5px #888888",
      marginBottom:"20px",
      minHeight:(props.height + 30) + 'px'
    }}>
      <div style={{minHeight:props.height + 'px'}}>
        <Typography variant="h6" style={{
          color:"#000000",
          borderBottom: "solid 2px " + props.color,
        }}>
          {props.title}
        </Typography>
        {props.children}
      </div>
      <div style={{minHeight:'30px'}}>
        {props.href
          ? <a href={props.href}><NavButton freescroll color="primary" style={{width:"120px",height:"30px",float:"right"}}>See more...</NavButton></a>
          : null
        }
      </div>
    </div>
  </Grid>;
}
