import React, {useState,useEffect} from 'react';
import Image from '../../../Helper/Image';
import Discover from '../Discover/Discover';

function Welcome(props){
  const [transy,setTransy] = useState(-100);
  const [opacity,setOpacity] = useState(1.0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTransy(0);
  },[]);

  useEffect(() => {
    const handleScroll = (event) => {
      const scrollTop = window.scrollY;
      const opac = 1.0 - scrollTop/500;
      const opac_limit = .2;
      if(opac < opac_limit){
        setOpacity(opac_limit);
      } else {
        setOpacity(opac);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  return(
    <div>
      <Image
        src="header_8.jpg"
        alt="developer portal"
        style={{
          position:"fixed",
          zIndex:"-1",
          transition:"transform .5s ease-in",
          transform:"translateY("+transy+"px)",
          opacity:opacity
        }}
      />
      <div style={{position:"relative",top:"450px"}}>
        <Discover/>
        <div style={{width:"100%",height:"200px"}}/>
      </div>
    </div>
  );
}

export default Welcome;
