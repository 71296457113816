import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '@material-ui/core';

function NavButton(props){
  let history = useHistory();

  let handle_click = () => {
    if(!props.freescroll){
      window.scrollTo(0, 0);
    }
    if(props.to){
      history.push(props.to);
    }
    if(props.onClick){
      props.onClick();
    }
  }

  return(
    <Button
      variant="contained"
      color={props.color}
      onClick={handle_click}
      style={props.style}
    >
      {props.children}
    </Button>
  );
}

export default NavButton;
