import React from 'react';
import {Typography, Container} from '@material-ui/core';
import {OverviewHeader,NavSection,TopRowBlue} from '../../Overview/OverviewPageStyling';

export default function Faq(props){
  return <div style={{backgroundColor:"#fcfcfc",paddingBottom:"50px"}}><OverviewHeader topRow={
		<TopRowBlue>
      <Typography variant="h3" gutterBottom style={{paddingTop:"50px"}}>Frequently Asked Questions</Typography>
    </TopRowBlue>
	}></OverviewHeader>
	<NavSection>
  </NavSection>
	<Container maxWidth={false} style={{backgroundColor:"#fcfcfc",position:"relative",top:"25px",color:"#555"}}>
    <Typography variant="body2">
      Coming Soon ...
    </Typography>
	</Container>
  </div>
}
