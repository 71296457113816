import React from 'react';
import Title from './Title.js';
import Navigation from './Navigation/Navigation.js';

function Header(props){
  const set_fixed = (fixed) => {
    if(fixed){
      return "fixed";
    } else {
      return "inherit";
    }
  };

  return(
    <div className="header" style={{position:set_fixed(props.isFixed),zIndex:"9999"}}>
      <Title/>
      <Navigation />
    </div>
  );
}

export default Header;
